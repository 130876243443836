import React from "react";
import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";

function RecommendedProductSkeleton() {
  return (
    <Grid item xs={12} sm={12}>
      <Card
        sx={{
          mb: 1,
          borderRadius: 8,
          boxShadow: 3,
          p: 2,
          mt: 2,
          display: "flex",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={150}
          height={150}
          animation="wave"
        />
        <CardContent>
          <Skeleton variant="text" width="80%" height={30} animation="wave" />
          <Skeleton variant="text" width="60%" height={20} animation="wave" />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            animation="wave"
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default RecommendedProductSkeleton;
