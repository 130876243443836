import { createSlice } from '@reduxjs/toolkit';

const quantitySlice = createSlice({
  name: 'quantities',
  initialState: {},
  reducers: {
    setQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      state[productId] = quantity;
    },
    clearQuantities: (state) => {
      return {};
    },
  },
});

export const { setQuantity, clearQuantities } = quantitySlice.actions;
export default quantitySlice.reducer;
