import { BrowserRouter as Router } from "react-router-dom";

//pages
import Pages from "./Pages/Pages";
import Footer from "./Components/Footer";

//mui
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import React from "react";

function App() {
  let theme1 = createTheme({
    palette: {
      primary: {
        main: "#227171", // Primary color
      },
      secondary: {
        main: "#FF9933", // Secondary color
      },
      gradian: {
        main: "linear-gradient(502deg, rgb(0, 0, 0) -189%, rgb(35 156 156) 80%, rgb(2 106 106) 100%)",
        hover:
          "linear-gradient(273deg, rgb(0, 0, 0) -189%, rgb(35 156 156) 80%, rgb(2 106 106) 100%)",
      },

      customColors: {
        white: "#FFFFFF",
        black: "#000000",
      },
      // ... other configurations
    },
  });

  theme1 = responsiveFontSizes(theme1);

  // Responsive typography settings
  theme1.typography.h3 = {
    fontSize: "1.2rem",
    "@media (min-width:100px)": {
      fontSize: "1.5rem",
    },
    [theme1.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  };
  theme1.typography.h4 = {
    fontSize: ".6rem",
    "@media (min-width:100px)": {
      fontSize: ".75rem",
    },
    [theme1.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  };
  theme1.typography.subtitle2 = {
    fontSize: ".5rem",
    "@media (min-width:100px)": {
      fontSize: ".5rem",
    },
    [theme1.breakpoints.up("md")]: {
      fontSize: ".75rem",
    },
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme1}>
        <Router>
          <Pages />
          {/* <Footer /> */}
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;

// #FF9933 - dark orangte

// #232F3E - Dark Blue
