import Header from "../../Components/Header";

function CategoriesProductsView(props) {
  return (
    <>
      <Header />

      <h1>Categories</h1>
    </>
  );
}

export default CategoriesProductsView;
