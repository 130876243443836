// src/Store/cartAndAmountSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  cart: [],
  cartObj: {},
  total: 0,
  totalItems: 0,
  status: 'idle',
  error: null,
};

export const fetchCartItems = createAsyncThunk(
  'cartAndAmount/fetchCartItems',
  async (_, { rejectWithValue }) => {
    try {
      const userID = localStorage.getItem('userID');
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue('No response was received');
      } else {
        return rejectWithValue('Error setting up the request');
      }
    }
  }
);

const cartAndAmountSlice = createSlice({
  name: 'cartAndAmount',
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setCartObj: (state, action) => {
      state.cartObj = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setTotalItems: (state) => {
      state.totalItems = state.cart.length;
    },
    increaseQuantity: (state, action) => {
      const { id, price } = action.payload;
      state.cartObj[id] = (state.cartObj[id] || 0) + 1;
      state.total += price;
    },
    decreaseQuantity: (state, action) => {
      const { id, price } = action.payload;
      if (state.cartObj[id] > 1) {
        state.cartObj[id] -= 1;
        state.total -= price;
      }
    },
    removeItemFromCart: (state, action) => {
      const { index, id, price, amount } = action.payload;
      state.cart.splice(index, 1);
      state.total -= price * amount;
      delete state.cartObj[id];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cart = action.payload;
        state.cartObj = action.payload.reduce((acc, item) => {
          acc[item._id] = 1;
          return acc;
        }, {});
        state.total = action.payload.reduce((acc, item) => acc + item.price, 0);
        state.totalItems = action.payload.length;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const {
  setCart,
  setCartObj,
  setTotal,
  setTotalItems,
  increaseQuantity,
  decreaseQuantity,
  removeItemFromCart,
} = cartAndAmountSlice.actions;

export default cartAndAmountSlice.reducer;
