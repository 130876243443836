import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button, Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import Label from "../../Components/Label";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../Components/Alert";
import { useDispatch } from "react-redux";
import { setShippingAddress } from "../../Store/shippingAddressSlice";
export default function AddressForm(props) {
  //user data
  const theme = useTheme();
  const { userID, token } = useSelector((state) => state.loging);
  const dispatch = useDispatch();
  // form data
  const [username, setUsername] = useState();
  const [address, setAddess] = useState();
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [postalcode, setPostalCode] = useState();
  const [country, setCountry] = useState();

  //data
  const [isFilled, setFilled] = useState(false);
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  //useEffect call
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users?ID=${userID}`, {
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((res) => {
        setAddess(res.data.address.address);
        setCity(res.data.address.city);
        setProvince(res.data.address.province);
        setPostalCode(res.data.address.postalcode);
        setCountry(res.data.address.country);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  // submit form
  // const handleSubmit = (event) => {
  //   console.log(postalcode, address, city, province, country);

  //   event.preventDefault();

  //   if (!address.trim()) {
  //     setError('Address required');
  //     return;
  //   }
  //   if (!city.trim()) {
  //     setError('City Required');
  //     return;
  //   }
  //   if (!province.trim()) {
  //     setError("province Required");
  //     return;
  //   }
  //   if (!postalcode.trim()) {
  //     setError('Invalid postalcode');
  //     return;
  //   }
  //   if (!country.trim()) {
  //     setError('Country Required');
  //     return;
  //   }

  //   axios
  //     .put(
  //       `${process.env.REACT_APP_APIURL}api/orders`,
  //       {
  //         address,
  //         city,
  //         province,
  //         postalcode,
  //         country,
  //         _id: props.id,
  //         tracking_status: 'Pending',
  //       },
  //       {
  //         headers: { Authorization: 'Agriuservalidation ' + token },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data) {
  //         console.log(res.data);
  //         toast('Order placed successfully!', { type: 'success' });
  //         props.handleNext();
  //       }
  //     })
  //     .catch((er) => {
  //       toast.error('Error placing order. Please try again.');
  //     });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(postalcode, address, city, province, country);

    if (!address.trim()) {
      setError("Address required");
      return;
    }
    if (!city.trim()) {
      setError("City required");
      return;
    }
    if (!postalcode.trim()) {
      setError("Invalid postalcode");
      return;
    }
    if (!country.trim()) {
      setError("Country required");
      return;
    }

    const updatedOrderDetails = {
      address: {
        address,
        city,
        province,
        postalcode,
        country,
      },
    };

    dispatch(setShippingAddress(updatedOrderDetails));
    props.handleNext();
  };

  const handleClose = () => {
    setError("");
  };

  return (
    <>
      <ToastContainer />
      {error && (
        <Alert
          open={true}
          msg={error}
          title={"Alert!"}
          handleClose={handleClose}
        />
      )}

      <Typography variant="h6" gutterBottom sx={{ color: "#232F3E", mb: 2 }}>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Label title="Address" error={error} />
          <TextField
            required
            disabled={isFilled}
            inputProps={{ sx: { color: "#232F3E" } }}
            id="address"
            name="address"
            placeholder="Address"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(event) => {
              setAddess(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label title="City" error={error} sx={{ color: "#232F3E" }} />
          <TextField
            required
            disabled={isFilled}
            inputProps={{ sx: { color: "#232F3E" } }}
            id="city"
            name="city"
            placeholder="City"
            fullWidth
            autoComplete="city"
            variant="outlined"
            value={city}
            onChange={(event) => {
              setCity(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label title="Province" error={error} />
          <TextField
            id="state"
            name="state"
            placeholder="Province"
            required
            disabled={isFilled}
            inputProps={{ sx: { color: "#232F3E" } }}
            fullWidth
            variant="outlined"
            value={province}
            onChange={(event) => {
              setProvince(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label title="Postal code" error={error} />
          <TextField
            required
            disabled={isFilled}
            inputProps={{ sx: { color: "#232F3E" } }}
            id="zip"
            name="zip"
            placeholder="Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            variant="outlined"
            value={postalcode}
            onChange={(event) => {
              setPostalCode(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label title="Country" error={error} />
          <TextField
            required
            disabled={isFilled}
            inputProps={{ sx: { color: "#232F3E" } }}
            id="country"
            name="country"
            placeholder="Country"
            fullWidth
            autoComplete="shipping country"
            variant="outlined"
            value={country}
            onChange={(event) => {
              setCountry(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              sx={{
                color: "#fff",
                background: theme.palette.gradian.main,
                mt: 3,
                ml: 1,
                "&:hover": {
                  background: theme.palette.gradian.hover,
                  color: theme.palette.customColors.white,
                },
              }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
