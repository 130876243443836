import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  TextField,
  IconButton,
  Grid,
  TableContainer,
  Skeleton,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Rating,
  Divider,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CustomSnackbar from './Components/CustomSnackbar';
import Alert from '../../Components/Alert';
import Swal from 'sweetalert2';

// import "sweetalert2/src/sweetalert2.scss";
function ProductManagement() {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState({
    categoryID: '',
    categoryName: '',
  });
  const [subCategory, setSubCategory] = useState({
    sub_categoryID: '',
    sub_categoryName: '',
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState('');
  const [vendorDetails, setVendorDetails] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const { token } = useSelector((state) => state.loging);
  const [isEmpty, setEmpty] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);
  const [error, setError] = useState('');
  const [imagePreviews, setImagePreviews] = useState(new Array(5).fill(null));
  const [oldImages, setOldImages] = useState([]);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchData();
  }, [page, search]);

  useEffect(() => {
    const fetchCategories = async () => {
      axios
        .get(`${process.env.REACT_APP_APIURL}api/Category/Forcustomer/`, {
          headers: { Authorization: 'Agriuservalidation ' + token },
        })
        .then((res) => {
          setCategories(res.data.data);
        })
        .catch((er) => {
          console.log('error' + er);
        });
    };

    fetchCategories();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/products/admin?pagination=${page}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      );
      if (response.data.data.length > 0) {
        setProducts(response.data.data);
        setCount(Math.ceil(response.data.cdata / 3));
        fetchVendorDetailsForProducts(response.data.data);
        setEmpty(false);
        setLoaded(true);
      } else {
        setEmpty(true);
        setLoaded(true);
      }
    } catch (error) {
      setEmpty(true);
      console.error('Error fetching products: ', error);
    } finally {
      setLoaded(true);
    }
  };

  const fetchVendorDetailsForProducts = async (products) => {
    for (let product of products) {
      await getVendorDetails(product.user_id);
    }
  };

  const getVendorDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}users?ID=${id}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      );
      if (response.data) {
        setVendorDetails((prevDetails) => ({
          ...prevDetails,
          [id]: response.data,
        }));
      }
    } catch (error) {
      console.error('Error fetching vendor details: ', error);
    }
  };

  const SearchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/products/admin?pagination=${page}&title=${search}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      );
      if (response.data.data.length > 0) {
        setProducts(response.data.data);
        setCount(Math.ceil(response.data.cdata / 12));
        setLoaded(true);
        setEmpty(false);
      } else {
        setEmpty(true);
      }
    } catch (error) {
      setEmpty(true);
      console.error('Error fetching products: ', error);
    }
  };

  const handleDeleteAction = (productId) => {
    setCurrentProduct(productId);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_APIURL}api/products?_id=${currentProduct}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      );
      if (response.data && response.data.deleted) {
        const updatedProducts = products.filter(
          (product) => product._id !== currentProduct
        );
        setProducts(updatedProducts);
        setSnackbarMessage('Product deleted successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
      setDialogOpen(false);
    } catch (error) {
      console.error('Error deleting product: ', error);
    }
  };

  const handleProductEdit = (productId) => {
    const productToEdit = products.find((product) => product._id === productId);
    if (productToEdit) {
      setEditedProduct(productToEdit);
      setCategory({
        categoryID: productToEdit.category.categoryID,
        categoryName: productToEdit.category.categoryName,
      });
      setSubCategory({
        sub_categoryID: productToEdit.sub_category.sub_categoryID,
        sub_categoryName: productToEdit.sub_category.sub_categoryName,
      });

      const initialPreviews = new Array(5).fill(null);
      productToEdit.images.forEach((image, index) => {
        initialPreviews[index] = image;
      });
      setImagePreviews(initialPreviews);
      setOldImages(productToEdit.images);
      setImages(new Array(5).fill(null));

      const selectedCat = categories.find(
        (cat) => cat._id === productToEdit.category.categoryID
      );
      setSubCategories(selectedCat ? selectedCat.subcategories : []);
      setEditDialogOpen(true);
    }
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (file && validImageTypes.includes(file.type)) {
      if (file.size > 1024 * 1024) {
        setSnackbarMessage('Image size should be less than 1 MB.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      if (!validImageTypes.includes(file.type)) {
        setSnackbarMessage(
          'Invalid file type. Only JPG, PNG, and GIF files are allowed.'
        );
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      const newImages = [...images];
      const newImagePreviews = [...imagePreviews];
      newImages[index] = file;
      newImagePreviews[index] = URL.createObjectURL(file);
      setImages(newImages);
      setImagePreviews(newImagePreviews);
    } else {
      snackbarMessage(
        'Invalid file type. Only JPG, PNG, and GIF files are allowed.'
      );
      snackbarSeverity('error');
      setOpenSnackbar(true);

      // Swal.fire({
      //   position: 'top-end',
      //   icon: 'error',
      //   title: 'oops.... Images not vaild ',
      //   text: 'Invalid file type. Only JPG, PNG, and GIF files are allowed.!',
      // });
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = [...images];
    const newImagePreviews = [...imagePreviews];
    newImages[index] = null;
    newImagePreviews[index] = null;
    setImages(newImages);
    setImagePreviews(newImagePreviews);

    if (index < oldImages.length) {
      const newOldImages = [...oldImages];
      newOldImages.splice(index, 1);
      setOldImages(newOldImages);
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.title = editedProduct.title ? '' : 'Product name is required.';
    tempErrors.price = editedProduct.price ? '' : 'Price is required.';
    if (editedProduct.price && isNaN(editedProduct.price))
      tempErrors.price = 'Price must be a number.';
    tempErrors.description = editedProduct.description
      ? ''
      : 'Description is required.';
    tempErrors.stock = editedProduct.stock ? '' : 'Stock is required.';
    if (editedProduct.stock && isNaN(editedProduct.stock))
      tempErrors.stock = 'Stock must be a number.';
    tempErrors.category = category.categoryID ? '' : 'Category is required.';
    tempErrors.subCategory = subCategory.sub_categoryID
      ? ''
      : 'Sub Category is required.';
    tempErrors.rating = editedProduct.rating > 0 ? '' : 'Rating is required.';

    if (!oldImages.length && !images.filter(Boolean).length) {
      tempErrors.images = 'At least one product image is required.';
    }
    setErrors({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSaveEditedProduct = async () => {
    if (!validateForm()) {
      setError('Please correct the errors before submitting.');
      return;
    }

    try {
      const data = new FormData();
      data.append('title', editedProduct.title);
      data.append('price', editedProduct.price);
      data.append('description', editedProduct.description);
      data.append('stock', editedProduct.stock);
      data.append('category', JSON.stringify(category));
      data.append('sub_category', JSON.stringify(subCategory));
      data.append('id', editedProduct.id);
      data.append('user_id', editedProduct.user_id);
      data.append('rating', editedProduct.rating);
      data.append('_id', editedProduct._id);

      images.filter(Boolean).forEach((image) => {
        data.append('images', image);
      });

      data.append('oldImages', JSON.stringify(oldImages));

      const response = await axios.put(
        `${process.env.REACT_APP_APIURL}api/products`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Agriuservalidation ' + token,
          },
        }
      );

      if (response.data) {
        setSnackbarMessage('Product updated successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setEditDialogOpen(false);
        fetchData();
      }
    } catch (error) {
      console.error('Error updating product: ', error);
    }
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleChange = (event, value) => setPage(value);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    const selectedCat = categories.find((cat) => cat._id === categoryId);
    setCategory({
      categoryID: selectedCat._id,
      categoryName: selectedCat.categoryname,
    });
    setSubCategories(selectedCat ? selectedCat.subcategories : []);
  };

  const handleSubCategoryChange = (event) => {
    const subCategoryId = event.target.value;
    const selectedSubCat = subCategories.find(
      (subCat) => subCat._id === subCategoryId
    );
    setSubCategory({
      sub_categoryID: selectedSubCat._id,
      sub_categoryName: selectedSubCat.subcategoryname,
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Alert
        open={!!error}
        handleClose={() => setError('')}
        title='Alert!'
        msg={error}
      />
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6}>
            <TextField
              label='Search ProductName'
              variant='outlined'
              onKeyUp={SearchData}
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={2} sm={2} mb={2}>
            <IconButton
              onClick={SearchData}
              sx={{
                color: '#fff',
                bgcolor: '#232F3E',
                borderRadius: 1,
                p: 1,
                '&:hover': {
                  backgroundColor: '#232F3E',
                  color: '#FF9933',
                },
              }}
              size='large'
            >
              <SearchIcon fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Images</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Subcategory</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Stock Quantity</TableCell>
                  <TableCell>Vendor details</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isEmpty && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box
                        mt={7}
                        component={Typography}
                        sx={{ textAlign: 'center' }}
                      >
                        No Product found
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {!isEmpty &&
                  isLoaded &&
                  products.map((product) => (
                    <TableRow key={product._id}>
                      <TableCell>
                        {product.images.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`product ${index}`}
                            style={{ width: 50, height: 50 }}
                          />
                        ))}
                      </TableCell>
                      <TableCell>{product.title}</TableCell>
                      <TableCell>{product.category.categoryName}</TableCell>
                      <TableCell>
                        {product.sub_category.sub_categoryName}
                      </TableCell>
                      <TableCell>{product.price}</TableCell>
                      <TableCell>{product.stock}</TableCell>
                      <TableCell>
                        {vendorDetails[product.user_id] ? (
                          <>
                            <Typography variant='subtitle1' color='primary'>
                              <VerifiedUserIcon fontSize='small' />{' '}
                              {vendorDetails[product.user_id].username}
                            </Typography>
                            <Typography variant='body2' color='textSecondary'>
                              <EmailIcon fontSize='small' />{' '}
                              {vendorDetails[product.user_id].email}
                            </Typography>
                            <Typography variant='body2' color='textSecondary'>
                              <PhoneIcon fontSize='small' />{' '}
                              {vendorDetails[product.user_id].mobile_number}
                            </Typography>
                            <Divider style={{ margin: '8px 0' }} />
                            <Typography variant='body2'>
                              <LocationOnIcon fontSize='small' />{' '}
                              {`${
                                vendorDetails[product.user_id].address.address
                              }, ${
                                vendorDetails[product.user_id].address.city
                              }, ${
                                vendorDetails[product.user_id].address.province
                              }, ${
                                vendorDetails[product.user_id].address.country
                              } - ${
                                vendorDetails[product.user_id].address
                                  .postalcode
                              }`}
                            </Typography>
                            <Divider style={{ margin: '8px 0' }} />
                            <Typography variant='body2'>
                              <strong>Approval Status:</strong>{' '}
                              {vendorDetails[product.user_id].Approveled}
                            </Typography>
                          </>
                        ) : (
                          'Loading...'
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => handleProductEdit(product._id)}
                          sx={{ m: 1 }}
                        >
                          Edit
                        </Button>
                        <Button
                          color='secondary'
                          variant='contained'
                          onClick={() => handleDeleteAction(product._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {!isLoaded && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                      <Skeleton
                        animation='wave'
                        height={40}
                        width={60}
                        style={{ marginRight: 10 }}
                      />
                      <Skeleton animation='wave' height={40} width={80} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </TableContainer>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination count={count} color='primary' onChange={handleChange} />
        </Grid>
      </Grid>
      <Box sx={{ p: 3 }}>
        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Product</DialogTitle>
          <DialogContent>
            {editedProduct && (
              <>
                <TextField
                  label='Title'
                  defaultValue={editedProduct ? editedProduct.title : ''}
                  fullWidth
                  onChange={(e) =>
                    setEditedProduct((prevData) => ({
                      ...prevData,
                      title: e.target.value,
                    }))
                  }
                />
                <TextField
                  label='Price'
                  type='number'
                  defaultValue={editedProduct ? editedProduct.price : ''}
                  fullWidth
                  onChange={(e) =>
                    setEditedProduct((prevData) => ({
                      ...prevData,
                      price: parseFloat(e.target.value),
                    }))
                  }
                />
                <TextField
                  label='Description'
                  defaultValue={editedProduct ? editedProduct.description : ''}
                  fullWidth
                  multiline
                  rows={4}
                  onChange={(e) =>
                    setEditedProduct((prevData) => ({
                      ...prevData,
                      description: e.target.value,
                    }))
                  }
                />
                <TextField
                  label='Stock'
                  type='number'
                  value={editedProduct.stock || 0}
                  onChange={(e) =>
                    setEditedProduct({
                      ...editedProduct,
                      stock: parseInt(e.target.value, 10),
                    })
                  }
                  fullWidth
                  margin='dense'
                />
                <FormControl fullWidth margin='dense'>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={category.categoryID}
                    onChange={handleCategoryChange}
                    label='Category'
                  >
                    {categories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.categoryname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                  <InputLabel>Sub-category</InputLabel>
                  <Select
                    value={subCategory.sub_categoryID}
                    onChange={handleSubCategoryChange}
                    label='Sub-category'
                  >
                    {subCategories.map((subCategory) => (
                      <MenuItem key={subCategory._id} value={subCategory._id}>
                        {subCategory.subcategoryname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography component='legend'>Rating</Typography>
                <Rating
                  name='rating'
                  value={editedProduct.rating}
                  onChange={(event, newValue) =>
                    setEditedProduct((prevData) => ({
                      ...prevData,
                      rating: newValue,
                    }))
                  }
                />
                <Box p={{ md: 0, xs: 3 }}>
                  <Grid
                    container
                    columnSpacing={0.5}
                    rowSpacing={0.5}
                    sx={{ mb: 2 }}
                    justifyContent='space-around'
                  >
                    {imagePreviews.map((preview, index) => (
                      <Grid item key={index} position='relative'>
                        <Tooltip title='Select image'>
                          <label htmlFor={`file_${index}`}>
                            <Box
                              p={0.5}
                              sx={{
                                bgcolor: '#D8D8D8',
                                borderRadius: 1,
                                cursor: 'pointer',
                                position: 'relative',
                              }}
                            >
                              {preview ? (
                                <img
                                  style={{ width: 70, height: 70, margin: 1 }}
                                  src={preview}
                                  alt='Product Preview'
                                />
                              ) : (
                                <AddToPhotosIcon
                                  sx={{
                                    color: '#406882',
                                    width: 72,
                                    height: 72,
                                  }}
                                />
                              )}
                            </Box>
                          </label>
                        </Tooltip>
                        {preview && (
                          <IconButton
                            size='small'
                            sx={{ position: 'absolute', top: -5, right: -5 }}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <CloseIcon
                              fontSize='small'
                              sx={{ color: '#406882' }}
                            />
                          </IconButton>
                        )}
                        <input
                          type='file'
                          hidden
                          id={`file_${index}`}
                          accept='image/*'
                          onChange={(event) => handleFileChange(event, index)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Typography>Only you can add 5 images</Typography>
                  {errors.images && (
                    <Typography color='error' variant='caption'>
                      {errors.images}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button color='primary' onClick={handleSaveEditedProduct}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default ProductManagement;
