import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import CustomButton from '../../Components/CustomButton';
import backgroundImage from '../../Assets/logoforlogin2.jpg';
import { useMediaQuery, useTheme } from '@mui/material';
import officallogo from '../../Assets/OfficalLog.png';
function ForgotPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    let tempErrors = {};
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Simple regex for email validation

    if (!email) {
      tempErrors.email = 'This field is required.';
    } else if (!emailRegex.test(email)) {
      tempErrors.email = 'Email is not valid.';
    } else {
      tempErrors.email = '';
    }

    setErrors({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackMessage('Please correct the errors before submitting.');
      setSnackSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APIURL}users/request-password-reset`,
        {
          email,
        }
      );

      if (res.data.message === 'Usernotfound') {
        setSnackMessage('User not found.');
        setSnackSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      setSnackMessage('Please check your email for the password reset link.');
      setSnackSeverity('success');
      setOpenSnackbar(true);

      setEmail('');

      // Optionally, navigate the user to another page
    } catch (error) {
      setSnackMessage('Failed to send password reset email3333333333.');
      setSnackSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        component='div'
        p={3}
        sx={{
          marginLeft: isMobile ? '0px' : '-700px',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: isMobile ? '100vh' : '100vh',
        }}
      >
        <CustomSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          severity={snackSeverity}
          message={snackMessage}
        />
        <Container
          component='main'
          maxWidth='sm'
          sx={{ textAlign: 'center', padding: 2 }}
        >
          <Paper
            elevation={3}
            sx={{
              borderRadius: 4,

              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: isMobile ? null : 'blur(10px)',

              padding: (3, 6, 3),
            }}
          >
            <img
              alt='logo'
              src={officallogo}
              style={{ width: 190, height: 80, mb: 2 }}
            />
            <Typography
              component='h1'
              variant='h6'
              fontWeight='bold'
              gutterBottom
              color={'secondary'}
            >
              Password Reset
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
              />
              <CustomButton title='Send Reset Link' type='submit' />
              <Grid container>
                <Grid item>
                  <Link href='/login' variant='body2'>
                    Remember your password? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
        <CustomSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          severity={snackSeverity}
          message={snackMessage}
        />
      </Box>
    </Box>
  );
}

export default ForgotPassword;
