// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { Button, Box, Typography, Grid, Paper, Container } from '@mui/material';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { logout } from '../../Store/auth';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
// function OrderConfirmation(props) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [userDetails, setUserDetails] = useState({});
//   const [shippingAddress, setShippingAddress] = useState({});
//   const { userID, token } = useSelector((state) => state.loging);
//   const orderDetails = useSelector((state) => state.checkout);
//   console.log(orderDetails);

//   const [severity, setseverity] = useState('');
//   const [message, setMessage] = useState('');
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const handleSubmit = () => {
//     axios
//       .patch(
//         `${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`,
//         {},
//         {
//           headers: { Authorization: 'Agriuservalidation ' + token },
//         }
//       )
//       .then((res) => {
//         props.handleNext();
//         setTimeout(() => {
//           toast('payment is success', { type: 'success' });
//         }, 2000);
//       })
//       .catch((error) => {
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           const statusCode = error.response.status;
//           console.log(statusCode, error.response.data);
//           if (statusCode === 400) {
//             setseverity('error');
//             setMessage('Token expired, please login again.');
//             setOpenSnackbar(true);

//             // Delay logout and redirection to ensure the user sees the toast message
//             setTimeout(() => {
//               console.log('Authentication failed. Logging out.');
//               dispatch(logout());
//               navigate('/login');
//             }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
//           } else {
//             // Handle other errors
//             setseverity('error');
//             setMessage('An error occurred. Please try again later.');
//             setOpenSnackbar(true);
//           }
//         } else if (error.request) {
//           // The request was made but no response was received
//           setseverity('error');
//           setMessage(
//             'No response from server. Please check your network connection.'
//           );
//           setOpenSnackbar(true);
//         } else {
//           setseverity('error');
//           // Something happened in setting up the request that triggered an Error
//           setMessage(`Error: ${error.message}`);
//           setOpenSnackbar(true);
//         }
//       });
//   };

//   const handleBack = () => {
//     props.handleBack();
//   };
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const userResponse = await axios.get(
//           `${process.env.REACT_APP_APIURL}users?ID=${userID}`,
//           {
//             headers: { Authorization: 'Agriuservalidation ' + token },
//           }
//         );
//         setUserDetails(userResponse.data);

//         console.log(props.id);

//         // const orderResponse = await axios.get(
//         //   `${process.env.REACT_APP_APIURL}api/orders/address/get?_id=${props.id}`,
//         //   {
//         //     headers: { Authorization: 'Agriuservalidation ' + token },
//         //   }
//         // );
//         setShippingAddress(orderDetails.address);
//       } catch (error) {
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           const statusCode = error.response.status;
//           console.log(statusCode, error.response.data);
//           if (statusCode === 400) {
//             setseverity('error');
//             setMessage('Token expired, please login again.');
//             setOpenSnackbar(true);

//             // Delay logout and redirection to ensure the user sees the toast message
//             setTimeout(() => {
//               console.log('Authentication failed. Logging out.');
//               dispatch(logout());
//               navigate('/login');
//             }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
//           } else {
//             // Handle other errors
//             setseverity('error');
//             setMessage('An error occurred. Please try again later.');
//             setOpenSnackbar(true);
//           }
//         } else if (error.request) {
//           // The request was made but no response was received
//           setseverity('error');
//           setMessage(
//             'No response from server. Please check your network connection.'
//           );
//           setOpenSnackbar(true);
//         } else {
//           setseverity('error');
//           // Something happened in setting up the request that triggered an Error
//           setMessage(`Error: ${error.message}`);
//           setOpenSnackbar(true);
//         }
//       }
//     };

//     fetchData();
//   }, [userID, token, props.id]);

//   return (
//     <>
//       <ToastContainer />
//       <CustomSnackbar
//         openSnackbar={openSnackbar}
//         setOpenSnackbar={setOpenSnackbar}
//         severity={severity}
//         message={message}
//       />
//       <Container>
//         <Typography variant='h4' align='center' gutterBottom color='#232F3E'>
//           Order Confirmation
//         </Typography>

//         <Grid
//           container
//           spacing={2}
//           sx={{ display: 'flex', justifyContent: 'space-between' }}
//         >
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={6}
//             lg={4}
//             sx={{ display: 'flex' }}
//           >
//             <Typography variant='body1'>User Details</Typography>
//             <Typography >{userDetails.username}</Typography>
//             <Typography>{userDetails.email}</Typography>
//             <Typography>{userDetails.mobile_number}</Typography>
//           </Grid>

//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={6}
//             lg={4}
//             sx={{ display: 'flex' }}
//           >
//             <Typography variant='body1'>Shipping Address Details</Typography>
//             <Typography>{shippingAddress.address}</Typography>
//             <Typography>{shippingAddress.city}</Typography>
//             <Typography>{shippingAddress.province}</Typography>
//             <Typography>{shippingAddress.postalcode}</Typography>
//             <Typography>{shippingAddress.country}</Typography>
//           </Grid>
//         </Grid>

//         <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
//           <Button
//             variant='contained'
//             onClick={handleBack}
//             sx={{
//               color: '#fff',
//               backgroundColor: '#FF9933',
//               '&:hover': { backgroundColor: '#FF9933', color: '#000' },
//             }}
//           >
//             Back
//           </Button>

//           <Button
//             variant='contained'
//             onClick={handleSubmit}
//             sx={{
//               color: '#fff',
//               backgroundColor: '#FF9933',
//               '&:hover': { bgcolor: '#FF9933', color: '#000' },
//             }}
//           >
//             Next
//           </Button>
//         </Box>
//       </Container>
//     </>
//   );
// }

// export default OrderConfirmation;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  Container,
  Card,
  CardContent,
  useTheme,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../Store/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../Adminpages/Components/CustomSnackbar";
import Swal from "sweetalert2";
import { removeVendorID } from "../../Store/VendorIDSlice";
function OrderConfirmation(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const { userID, token } = useSelector((state) => state.loging);
  const orderDetails = useSelector((state) => state.checkout);
  console.log(orderDetails);
  const ShippingAddress = useSelector((state) => state.shippingAddress);
  console.log(orderDetails.products);
  const products = orderDetails.products;
  const productsOrders = Object.entries(products).map(([key, value]) => {
    return { _id: key, Quality: value };
  });
  const productIDs = productsOrders.map((product) => product._id);

  const [productForOrdered, setproductForOrdered] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(orderDetails.products);
    console.log(productForOrdered);
    if (
      !orderDetails.products ||
      Object.keys(orderDetails.products).length === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Checkout Failed again please go to cart and select products !",
        text: "The Checkout products have been deleted. Please go to the cart and select them again !",
        confirmButtonText: "Go to shopping Cart again",
        preConfirm: () => {
          // Redirect to a specific page
          window.location.href = "/cart";
        },
      });
    }
  }, [orderDetails]);
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_APIURL}api/ProductIDSToProducts`,
        {
          productIDs: productIDs,
        },
        { headers: { Authorization: "Agriuservalidation " + token } }
      )
      .then((res) => {
        console.log(res.data);
        setproductForOrdered(res.data);
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity("error");
            setMessage("Token expired, please login again.");
            setOpenSnackbar(true);

            setTimeout(() => {
              console.log("Authentication failed. Logging out.");
              dispatch(logout());
              dispatch(removeVendorID());
              window.location.href = "https://vendormart.lk/";
            }, 3000); // Adjust duration as needed
          } else {
            setseverity("error");
            setMessage("An error occurred. Please try again later.");
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          console.log(error.request);
          if (error.message.includes("Network Error")) {
            setseverity("error");
            setMessage("Network Error. Please check your internet connection.");
          } else {
            setseverity("error");
            setMessage(
              "No response from server. Please check your network connection."
            );
          }
          setOpenSnackbar(true);
        } else {
          setseverity("error");
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  }, []);

  console.log(ShippingAddress.address);
  const theme = useTheme();
  const [severity, setseverity] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleSubmit = () => {
    console.log(orderDetails);
    console.log(ShippingAddress);
    if (
      !orderDetails.total &&
      !orderDetails.tracking_status === "Pending" &&
      !orderDetails.products &&
      !orderDetails.user_id
    ) {
      setseverity("error");
      setMessage("Order Details are required");
      setOpenSnackbar(true);
      return;
    }
    if (ShippingAddress.address === "") {
      setseverity("error");
      setMessage("Shipping Address is required");
      setOpenSnackbar(true);
      return;
    }

    if (ShippingAddress.city === "") {
      setseverity("error");
      setMessage("City is required");
      setOpenSnackbar(true);
      return;
    }

    if (ShippingAddress.province === "") {
      setseverity("error");
      setMessage("Province is required");
      setOpenSnackbar(true);
      return;
    }

    if (ShippingAddress.postalcode === "") {
      setseverity("error");
      setMessage("Postal Code is required");
      setOpenSnackbar(true);
      return;
    }

    if (ShippingAddress.country === "") {
      setseverity("error");
      setMessage("Country is required");
      setOpenSnackbar(true);
      return;
    }

    if (orderDetails) {
      axios
        .post(
          `${process.env.REACT_APP_APIURL}api/orders`,
          {
            user_id: orderDetails.user_id,
            total: orderDetails.total,
            products: orderDetails.products,
            tracking_status: "Pending",
            address: ShippingAddress.address,
          },
          {
            headers: { Authorization: "Agriuservalidation " + token },
          }
        )
        .then((res) => {
          console.log("heelo");
          console.log(res.data);
          if (res.data) {
            axios
              .patch(
                `${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`,
                {},
                {
                  headers: { Authorization: "Agriuservalidation " + token },
                }
              )
              .then((res) => {
                props.handleNext();

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Your order has been placed successfully",
                  text: "Thank you for shopping with us!",
                  showConfirmButton: false,
                  timer: 2500,
                });
              })
              .catch((error) => {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  const statusCode = error.response.status;
                  console.log(statusCode, error.response.data);
                  if (statusCode === 400) {
                    setseverity("error");
                    setMessage("Token expired, please login again.");
                    setOpenSnackbar(true);

                    // Delay logout and redirection to ensure the user sees the toast message
                    setTimeout(() => {
                      console.log("Authentication failed. Logging out.");
                      dispatch(removeVendorID());
                      dispatch(logout());
                      window.location.href = "https://vendormart.lk/";
                    }, 5000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
                  } else {
                    // Handle other errors
                    setseverity("error");
                    setMessage("An error occurred. Please try again later.");
                    setOpenSnackbar(true);
                  }
                } else if (error.request) {
                  // The request was made but no response was received
                  setseverity("error");
                  setMessage(
                    "No response from server. Please check your network connection."
                  );
                  setOpenSnackbar(true);
                } else {
                  setseverity("error");
                  // Something happened in setting up the request that triggered an Error
                  setMessage(`Error: ${error.message}`);
                  setOpenSnackbar(true);
                }
              });
          }
        })
        .catch((error) => {
          if (error.response) {
            const statusCode = error.response.status;
            console.log(statusCode, error.response.data);
            if (statusCode === 400) {
              setseverity("error");
              setMessage("Token expired, please login again ");
              setOpenSnackbar(true);

              setTimeout(() => {
                console.log("Authentication failed. Logging out.");
                dispatch(logout());
                dispatch(removeVendorID());
                window.location.href = "https://vendormart.lk/";
              }, 3000); // Adjust duration as needed
            } else {
              setseverity("error");
              setMessage("An error occurred. Please try again later.");
              setOpenSnackbar(true);
            }
          } else if (error.request) {
            console.log(error.request);
            if (error.message.includes("Network Error")) {
              setseverity("error");
              setMessage(
                "Network Error. Please check your internet connection."
              );
            } else {
              setseverity("error");
              setMessage(
                "No response from server. Please check your network connection."
              );
            }
            setOpenSnackbar(true);
          } else {
            setseverity("error");
            setMessage(`Error: ${error.message}`);
            setOpenSnackbar(true);
          }
        });
    }
  };

  const handleBack = () => {
    props.handleBack();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_APIURL}users?ID=${userID}`,
          {
            headers: { Authorization: "Agriuservalidation " + token },
          }
        );
        setUserDetails(userResponse.data);

        // const orderResponse = await axios.get(
        //   `${process.env.REACT_APP_APIURL}api/orders/address/get?_id=${props.id}`,
        //   {
        //     headers: { Authorization: 'Agriuservalidation ' + token },
        //   }
        // );
        setShippingAddress(ShippingAddress.address);
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity("error");
            setMessage("Token expired, please login again.");
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log("Authentication failed. Logging out.");
              dispatch(removeVendorID());
              dispatch(logout());
              window.location.href = "https://vendormart.lk/";
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            setseverity("error");
            setMessage("An error occurred. Please try again later.");
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity("error");
          setMessage(
            "No response from server. Please check your network connection."
          );
          setOpenSnackbar(true);
        } else {
          setseverity("error");
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      }
    };

    fetchData();
  }, [userID, token]);

  return (
    <>
      <ToastContainer />
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        severity={severity}
        message={message}
      />
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        {/* <Typography variant='h4' align='center' gutterBottom color='#232F3E'>
          Order Confirmation
        </Typography> */}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Paper elevation={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Order summary
                  </Typography>
                  {productForOrdered?.map((product) => {
                    console.log(productsOrders);
                    const productQulaity = productsOrders.filter(
                      (productOrder) => productOrder._id === product._id
                    );

                    return (
                      <>
                        <Grid container spacing={1} sx={{ p: 2 }}>
                          <Grid item xs={1}>
                            <img
                              src={product.images[0]}
                              alt={product.title}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="body1">
                              {" "}
                              {product.title.substring(0, 30)}
                            </Typography>
                            <Typography variant="body2">
                              Qulaity - {productQulaity[0].Quality}
                            </Typography>

                            {/* <Typography variant='body2'>
                              categories - {product?.category?.categoryName}{' '}
                            </Typography>
                            <Typography variant='body2'>
                              Sub-categories -
                              {product?.sub_category?.sub_categoryName}
                            </Typography> */}
                          </Grid>
                          <Grid item xs={2} sx={{ mt: 2 }}>
                            <Typography variant="body1" align="right">
                              Rs {product.price}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
                      </>
                    );
                  })}

                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                      <Typography variant="body1">Subtotal</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Rs {orderDetails.total}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Tax</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Rs 0
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" fontWeight="bold">
                        Total
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        align="right"
                      >
                        Rs {orderDetails.total}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper elevation={3} sx={{ mb: 2 }}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    your Details
                  </Typography>
                  <Typography variant="body1">
                    {userDetails.username}
                  </Typography>
                  <Typography variant="body1">{userDetails.email}</Typography>
                  <Typography variant="body1">
                    {userDetails.mobile_number}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
            <Paper elevation={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Shipping Address Details
                  </Typography>
                  <Typography variant="body1">
                    {shippingAddress.address}
                  </Typography>
                  <Typography variant="body1">
                    {" "}
                    {shippingAddress.city}, {shippingAddress.province}{" "}
                  </Typography>
                  <Typography variant="body1">
                    {shippingAddress.country}, {shippingAddress.postalcode}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleBack}
            sx={{
              color: "#fff",
              background: theme.palette.gradian.main,
              "&:hover": {
                background: theme.palette.gradian.hover,
                color: theme.palette.customColors.white,
              },
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              color: "#fff",
              background: theme.palette.gradian.main,
              "&:hover": {
                background: theme.palette.gradian.hover,
                color: theme.palette.customColors.white,
              },
            }}
          >
            Order Now
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default OrderConfirmation;
