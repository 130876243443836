import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, IconButton } from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import { red } from "@mui/material/colors";

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { useMediaQuery, useTheme } from "@mui/material";
import CustomSnackbar from "../Adminpages/Components/CustomSnackbar";

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

export default function WithOutTokenAgricard(props) {
  // const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [fav, setFav] = useState(props.fav);
  const [incart, setInCart] = useState(props.inCart);

  const [open, setOpen] = useState(false);

  const [opencart, setOpenCart] = useState(false);
  const [favadd, setfavadd] = useState(false);
  const [favremove, setfavremove] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const navigate = useNavigate();
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const addTocart = () => {
    Swal.fire({
      title: "Please sign in",
      text: "Please sign in to add items to your cart.",
      icon: "warning",
      confirmButtonText: "Sign In",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/login"); // Replace "/signin" with the actual path to your sign-in page
      }
    });
  };

  const handlefavorite = (val) => {
    Swal.fire({
      title: "Please sign in",
      text: "Please sign in to favorite items.",
      icon: "warning",
      confirmButtonText: "Sign In",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/login"); // Replace "/signin" with the actual path to your sign-in page
      }
    });
  };

  return (
    <Grid
      item
      xs={isMobile ? 6 : 12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      sx={{ mt: { xs: 1, sm: 2 } }}
    >
      <CustomSnackbar
        open={open}
        onClose={() => setOpen(false)}
        severity="success"
        message="Added to cart"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <CustomSnackbar
        open={opencart}
        onClose={() => setOpenCart(false)}
        severity="warning"
        message="Already Added to Cart"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <CustomSnackbar
        open={favadd}
        onClose={() => setfavadd(false)}
        severity="success"
        message="Add to Favorites"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <CustomSnackbar
        open={favremove}
        onClose={() => setfavremove(false)}
        severity="success"
        message="Remove from Favorites"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <CustomSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
      <Card
        sx={{
          minWidth: { isMobile } ? "100%" : "200px",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
          "&:hover": {
            boxShadow: "0 0 5px 2px rgba(0,0,0,0.25)",
            transitionDuration: ".5s",
            "& .favorite-icon": {
              visibility: "visible",
            },
            "& .cart-indicator": {
              // Add this for your cart indicator
              visibility: "visible",
            },
          },
        }}
      >
        <CardMedia
          component="img"
          style={{
            maxHeight: isMobile ? "120px" : "200px",

            objectFit: "contain",
            objectPosition: "center",
            paddingTop: "10px",
            cursor: "pointer",
          }}
          image={props.data.images[0]}
          alt="Product Image"
          onClick={() => {
            navigate(`/product/view/${props.data._id}`);
          }}
        />

        <IconButton
          className="favorite-icon"
          sx={{
            color: red[800],
            mt: isMobile ? -30 : -50,
            visibility: isMobile ? "visible" : "hidden",
            ":hover": {
              visibility: isMobile ? "visible" : "visible",
            },
          }}
          onClick={() => {
            handlefavorite(!fav);
          }}
        >
          {fav ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
        </IconButton>

        <CardContent>
          <Typography
            variant={isMobile ? "body2" : "subtitle1"}
            align="center"
            color={props.data.stock === 0 ? "red" : "green"}
            sx={{
              fontSize: "0.9rem",
              marginTop: "-20px", // Adjust as needed for positioning
            }}
          >
            {props.data.stock === 0 ? "Out of Stock" : "In Stock"}
          </Typography>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Grid item>
              <Typography
                gutterBottom
                // variant="h6"
                component="div"
                textAlign="left"
                color="#232F3E"
                sx={{
                  fontSize: "14px",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2, // Adjust the number of lines you want to allow
                  maxWidth: "100%",
                }}
              >
                {props.data.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid sx container justifyContent={"space-between"}>
            <Typography
              gutterBottom
              variant="h6"
              textAlign={"left"}
              color="primary.main "
            >
              {`Rs ${props.data.price}`}
            </Typography>
            <Rating
              value={props.data.rating}
              precision={0.5}
              readOnly
              size="small" // Adjust size as needed
            />
          </Grid>
        </CardContent>
        <CardActions>
          <>
            {/* <Button
              onClick={addTocart}
              component={Typography}
              fullWidth
              size={isMobile ? "small" : "medium"}
              startIcon={<ShoppingCartOutlinedIcon />}
              sx={{
                backgroundColor: "#FF9933",
                color: "#fff",
                padding: isMobile ? "8px 4px" : "10px 20px",
                width: "100%",
                textTransform: "none",
                "&:hover": {
                  color: "#000",
                  backgroundColor: "#FF9933",
                },
              }}
              disabled={props.data.stock === 0}
            >
              {"Add to my Cart"}
            </Button> */}
            <Button
              onClick={addTocart}
              component={Typography}
              fullWidth
              size={isMobile ? "small" : "medium"}
              startIcon={<ShoppingCartOutlinedIcon />}
              sx={{
                background: theme.palette.gradian.main,
                color: "#fff",
                padding: isMobile ? "8px 4px" : "10px 20px",
                width: "100%",
                textTransform: "none",
                "&:hover": {
                  color: theme.palette.customColors.white,
                  background: theme.palette.gradian.hover,
                },
              }}
              disabled={props.data.stock === 0}
            >
              {"Add to Cart"}
            </Button>
          </>
        </CardActions>
      </Card>
    </Grid>
  );
}
