// import { createSlice } from '@reduxjs/toolkit';

// const shippingAddressSlice = createSlice({
//   name: 'shippingAddress',
//   initialState: {
//     address: {
//       address: '',
//       city: '',
//       province: '',
//       postalcode: '',
//       country: '',
//     },
//   },
//   reducers: {
//     setShippingAddress: (state, action) => {
//       state.address = action.payload.address;
//     },
//     clearShippingAddress: (state) => {
//       state.address = {
//         address: '',
//         city: '',
//         province: '',
//         postalcode: '',
//         country: '',
//       };
//     },
//   },
// });

// export const { setShippingAddress, clearShippingAddress } =
//   shippingAddressSlice.actions;
// export default shippingAddressSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

// Helper function to load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('shippingAddress');
    if (serializedState === null) {
      return {
        address: {
          address: '',
          city: '',
          province: '',
          postalcode: '',
          country: '',
        },
      };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {
      address: {
        address: '',
        city: '',
        province: '',
        postalcode: '',
        country: '',
      },
    };
  }
};

// Helper function to save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('shippingAddress', serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

const initialState = loadState();

const shippingAddressSlice = createSlice({
  name: 'shippingAddress',
  initialState,
  reducers: {
    setShippingAddress: (state, action) => {
      state.address = action.payload.address;
      saveState(state); // Save state to localStorage after updating
    },
    clearShippingAddress: (state) => {
      state.address = {
        address: '',
        city: '',
        province: '',
        postalcode: '',
        country: '',
      };
      saveState(state); // Save state to localStorage after clearing
    },
  },
});

export const { setShippingAddress, clearShippingAddress } =
  shippingAddressSlice.actions;
export default shippingAddressSlice.reducer;
