import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  useTheme,
  CardActionArea,
  CardActions,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SimpleAgriCard = ({ product }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
      <Card
        onClick={() => {
          navigate(`/product/view/${product._id}`);
        }}
        sx={{
          maxWidth: 150,
          maxHeight: 250,
          m: 2,
          transition: '0.3s',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: theme.shadows[10],
          },
        }}
      >
        <CardActionArea>
          <CardMedia
            component='img'
            sx={{
              objectFit: 'contain',
              width: '150px',
              height: '100px',

              // marginTop: "10px",
              marginBottom: '10px',
            }}
            image={product.images[0]}
            alt={product.title}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant='subtitle2'
              component='div'
              sx={{ color: '#232F3E' }}
            >
              {product.title.length > 15
                ? `${product.title.substring(0, 15)}...`
                : product.title}
            </Typography>

            <Typography
              variant='subtitle1'
              color='text.secondary'
              sx={{ mt: 1, color: 'gray', fontWeight: 'bold' }}
            >
              Rs {product.price}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default SimpleAgriCard;
