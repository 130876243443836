import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Grid,
  Link,
} from '@mui/material';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import CustomButton from '../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import officallogo from '../../Assets/OfficalLog.png';
import { useMediaQuery, useTheme } from '@mui/material';
import backgroundImage from '../../Assets/logoforlogin2.jpg';

function ResetPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.password = password ? '' : 'This field is required.';
    tempErrors.confirmPassword = confirmPassword
      ? ''
      : 'This field is required.';

    if (password && confirmPassword && password !== confirmPassword) {
      tempErrors.confirmPassword = 'Passwords do not match.';
    }

    // Add more validation based on your requirements, e.g., password length, characters, etc.
    if (password && password.length < 6) {
      tempErrors.password = 'Password must be at least 6 characters long.';
    }

    setErrors({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackMessage('Please correct the errors before submitting.');
      setSnackSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_APIURL}users/reset-password`, {
        token,
        password,
      })
      .then((response) => {
        if (response.data.message === 'Token is invalid or has expired') {
          setSnackMessage(
            'Invalid or expired token. Please try again to sent email link.'
          );
          setSnackSeverity('error');
          setOpenSnackbar(true);
        } else {
          setSnackMessage('Your password has been successfully reset.');
          setSnackSeverity('success');
          setOpenSnackbar(true);

          setTimeout(() => {
            navigate('/login');
          }, 1000);

          // Clear the password and confirmPassword fields after successful password reset
          setPassword('');
          setConfirmPassword('');
        }
      })
      .catch((error) => {
        setSnackMessage('Failed to reset password.');
        setSnackSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        component='div'
        sx={{
          marginLeft: isMobile ? '0px' : '-700px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: isMobile ? '100vh' : '100vh',
        }}
      >
        <CustomSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          severity={snackSeverity}
          message={snackMessage}
        />
        <Container component='main' maxWidth='sm'>
          <Paper
            elevation={3}
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: (3, 2),
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: isMobile ? null : 'blur(10px)',
            }}
          >
            <img
              alt='logo'
              src={officallogo}
              style={{ width: 190, height: 80, mb: 2 }}
            />
            <Typography
              component='h1'
              variant='h6'
              fontWeight='bold'
              gutterBottom
              color={'secondary'}
            >
              Reset Password
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='New Password'
                type={showPassword ? 'text' : 'password'}
                id='password'
                autoComplete='new-password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='confirmPassword'
                label='Confirm Password'
                type={showConfirmPassword ? 'text' : 'password'}
                id='confirmPassword'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle confirm password visibility'
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge='end'
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <CustomButton title={'Reset Password'} type='submit' />
              <Grid container>
                <Grid item>
                  <Link href='/ForgotPassword' variant='body2'>
                    Again need sent the email link ? Forgot Password
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
        <CustomSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          severity={snackSeverity}
          message={snackMessage}
        />
      </Box>
    </Box>
  );
}

export default ResetPassword;
