import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Paper,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

// Image imports
import image from "../../Assets/3D Design 010.png";
import image1 from "../../Assets/1st.jpg";
import image2 from '../../Assets/3st.jpg';
import image3 from "../../Assets/4th Slide Design.png";
import image4 from "../../Assets/4st.jpg";

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "relative",
  backgroundColor: "#fff",
  color: theme.palette.text.primary,
  padding: "20px",
  textAlign: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffa000",
  color: "#fff",
  marginTop: theme.spacing(2),
  "&:hover": {
    backgroundColor: "#ffca28",
  },
}));

const SliderWrapper = styled("div")({
  overflowX: "hidden", // Prevent horizontal scrollbar
});

export default function MobileImageSilder() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
  };

  const imageStyle = {
    width: "100%",

    height: isMobile ? "250px" : "400px",
    objectFit: "fill",
    borderRadius: "10px",
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {[image, image1, image2, image3, image4].map((image, index) => (
          <div key={index}>
            <StyledPaper>
              <Box
                component="img"
                src={image}
                alt={`Slide ${index + 1}`}
                sx={imageStyle}
              />
              <Typography
                variant={isMobile ? "h5" : "h4"}
                component="h2"
                mt={2}
              >
                {
                  [
                    "check our website for exclusive offers",
                    "Free Shipping Deals",
                    "Exclusive Offers",
                    "New Arrivals",
                    "Customer Satisfaction",
                  ][index]
                }
              </Typography>
              <Typography variant="h6" mt={1}>
                {
                  [
                    "Free Shipping on Orders Above Rs. 499",
                    "On orders above Rs. 999",
                    "Grab the Best Deals Today!",
                    "Check Out Our Latest Collection",
                    "Join Our Community of Happy Shoppers",
                  ][index]
                }
              </Typography>
              <StyledButton variant="contained">Learn More</StyledButton>
            </StyledPaper>
          </div>
        ))}
      </Slider>
    </SliderWrapper>
  );
}
