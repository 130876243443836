import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  BottomNavigation,
  BottomNavigationAction,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
  Drawer,
  Avatar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  BusinessCenter as BusinessCenterIcon,
  ShoppingCart as ShoppingCartIcon,
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  Category as CategoryIcon,
  Email as EmailIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import logoImage from '../../Assets/OfficalLog.png';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../Store/auth';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

function AdminNav({ selectedPage, handlePageChange }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { userID, token } = useSelector((state) => state.loging);
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState(!!token);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users?ID=${userID}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        setUser(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error('Error fetching user', error);
      });
  }, [userID, token]);

  useEffect(() => {
    console.log('AdminNav re-rendered with selectedPage:', selectedPage);
  }, [selectedPage]);

  const navItems = [
    { id: 'dashboard', text: 'Dashboard', icon: <DashboardIcon /> },
    {
      id: 'vendorManagement',
      text: 'Vendor Management',
      icon: <BusinessCenterIcon />,
    },
    {
      id: 'customerManagement',
      text: 'Customer Management',
      icon: <PeopleIcon />,
    },
    {
      id: 'categoryManagement',
      text: 'Category Management',
      icon: <CategoryIcon />,
    },
    {
      id: 'productManagement',
      text: 'Product Management',
      icon: <ShoppingCartIcon />,
    },
    { id: 'orderManagement', text: 'Order Management', icon: <BarChartIcon /> },
    {
      id: 'communication',
      text: 'Communication Management',
      icon: <EmailIcon />,
    },
    { id: 'settings', text: 'Settings', icon: <SettingsIcon /> },
  ];

  const handleNavigation = (pageId) => {
    handlePageChange(pageId);
    if (isMobile || isTablet) {
      setSidebarOpen(false);
    }
  };

  return (
    <Box>
      <AppBar
        position='fixed'
        sx={{
          width: isMobile || isTablet ? '95%' : '83%',
          background:
            'linear-gradient(209deg, rgba(1,5,29,1) 50%, rgba(31,21,0,0.824) 100%)',
          color: '#FFFFFF',
          boxShadow: 'none',
          ml: { xs: 1, sm: 1, md: 190 },
          mt: { xs: 1, sm: 1, md: 1 },
          mr: { xs: 1, sm: 1, md: 1 },
          borderRadius: '10px 10px 10px 10px',
          border: 'none',
          boxShadow: '0 1px 3px #232F3E opacity(0.4)',
          transition: 'width 0.3s ease-in-out',
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => setSidebarOpen(true)}
              edge='start'
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <TextField
            variant='outlined'
            size='small'
            placeholder='Search...'
            sx={{
              backgroundColor: 'white',
              borderRadius: '10px',
              flexGrow: 1,
              mx: 2,
              '& .MuiOutlinedInput-root': { borderRadius: '10px' },
              '& .MuiOutlinedInput-input': { color: '#232F3E' },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <IconButton color='inherit'>
            <PeopleIcon />
          </IconButton>
          <IconButton color='inherit'>
            <SettingsIcon />
          </IconButton>
          <IconButton
            color='inherit'
            onClick={() => {
              setAuth(false);
              dispatch(logout());
              navigate('/login', { replace: true });
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor='left'
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            background:
              'linear-gradient(209deg, rgba(1,5,29,1) 50%, rgba(31,21,0,0.824) 100%)',
            width: isMobile ? '100%' : '240px',
            boxSizing: 'border-box',
            px: 2,
            py: 2,
            color: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <div>
          <Toolbar>
            <img
              src={logoImage}
              alt='Vendor Logo'
              style={{ height: '50px', margin: 'auto' }}
            />
          </Toolbar>
          <List>
            {navItems.map((item) => (
              <ListItem
                button
                key={item.id}
                onClick={() => handleNavigation(item.id)}
                selected={selectedPage === item.id}
                sx={{
                  color: '#FFFFFF',
                  backgroundColor:
                    selectedPage === item.id ? '#FF9933' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#3f546f',
                    backdropFilter: 'blur(3px)',
                    borderRadius: '10px',
                    color: '#FFFFFF',
                  },
                  '&.Mui-selected': {
                    backdropFilter: 'blur(7px)',
                    backgroundColor: '#FF9933',
                    color: '#FFFFFF',
                    borderRadius: '10px',
                  },
                }}
              >
                <ListItemIcon sx={{ color: '#FFFFFF' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} sx={{ fontSize: 9 }} />
              </ListItem>
            ))}
          </List>
        </div>
        <Box sx={{ py: 2, textAlign: 'center' }}>
          <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.5)' }} />
          <Avatar
            src={user.images}
            alt={user.username}
            sx={{ width: 80, height: 80, mx: 'auto', mt: 2 }}
          />
          <Typography variant='h6' mt={2}>
            {user.username}
          </Typography>
        </Box>
      </Drawer>
      <Toolbar />
    </Box>
  );
}

export default AdminNav;
