import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, IconButton } from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { red } from "@mui/material/colors";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import axios from "axios";
import CustomSnackbar from "../Adminpages/Components/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { useMediaQuery, useTheme } from "@mui/material";
import { addFavorite, removeFavorite } from "../../Store/favoritesSlice";
import { addItemToCart } from "../../Store/cartSlice";
import { logout } from "../../Store/auth";
import { removeVendorID } from "../../Store/VendorIDSlice";

export default function AgriCard(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { token, role, userID } = useSelector((state) => state.loging);

  const [fav, setFav] = useState(props.fav);
  const [incart, setInCart] = useState(props.inCart);
  const [open, setOpen] = useState(false);
  const [opencart, setOpenCart] = useState(false);
  const [favadd, setfavadd] = useState(false);
  const [favremove, setfavremove] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    setInCart(props.inCart ? true : false);

    setFav(props.fav ? true : false);
  }, [props.inCart, props.fav]);
  const addTocart = () => {
    if (incart) {
      setOpenCart(true);
      return;
    }
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users/carts`,
        {
          pid: props.data._id,
          _id: userID,
          set: true,
        },
        {
          headers: { Authorization: `Agriuservalidation ${token}` },
        }
      )
      .then(() => {
        dispatch(addItemToCart(props.data._id));
        setInCart(true);
        setOpen(true);
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setSeverity("error");
            setMessage("Token expired, please login again.");
            setOpenSnackbar(true);

            setTimeout(() => {
              dispatch(logout());
              dispatch(removeVendorID());
              window.location.href = "https://vendormart.lk/";
            }, 3000); // Adjust duration as needed
          } else {
            // setSeverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          if (error.message.includes("Network Error")) {
            setSeverity("error");
            setMessage("Network Error. Please check your internet connection.");
          } else {
            setSeverity("error");
            setMessage(
              "No response from server. Please check your network connection."
            );
          }
          setOpenSnackbar(true);
        } else {
          setSeverity("error");
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  const handlefavorite = (val) => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users/favorites`,
        {
          _id: userID,
          pid: props.data._id,
          val: val,
        },
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      )
      .then(() => {
        setFav(!fav);
        if (val) {
          dispatch(addFavorite(props.data._id));
          setfavadd(true);
        } else {
          dispatch(removeFavorite(props.data._id));
          setfavremove(true);
        }
        props.removeFav(props.index);
      })
      .catch(() => {});
  };

  return (
    <Grid
      item
      xs={isMobile ? 6 : 12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      sx={{ mt: { xs: 1, sm: 2 } }}
    >
      <CustomSnackbar
        open={open}
        onClose={() => setOpen(false)}
        severity="success"
        message="Added to cart"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <CustomSnackbar
        open={opencart}
        onClose={() => setOpenCart(false)}
        severity="warning"
        message="Already Added to Cart"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <CustomSnackbar
        open={favadd}
        onClose={() => setfavadd(false)}
        severity="success"
        message="Added to Favorites"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <CustomSnackbar
        open={favremove}
        onClose={() => setfavremove(false)}
        severity="success"
        message="Removed from Favorites"
        position={{ vertical: "bottom", horizontal: "center" }}
      />
      <Card
        sx={{
          minWidth: { isMobile } ? "100%" : "200px",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
          "&:hover": {
            boxShadow: "0 0 5px 2px rgba(0,0,0,0.25)",
            transitionDuration: ".5s",
            "& .favorite-icon": {
              visibility: "visible",
            },
          },
        }}
      >
        <CardMedia
          component="img"
          style={{
            maxHeight: isMobile ? "120px" : "200px",

            objectFit: "contain",
            objectPosition: "center",
            paddingTop: "10px",
            cursor: "pointer",
          }}
          image={props.data.images[0]}
          alt="Product Image"
          onClick={() => {
            navigate(`/product/view/${props.data._id}`);
          }}
        />
        {/* {role === "client" && (
          <IconButton
            className="favorite-icon"
            sx={{
              color: red[800],
              mt: -50,
              visibility: "hidden",
              ":hover": {
                visibility: "visible",
              },
            }}
            onClick={() => {
              handlefavorite(!fav);
            }}
          >
            {fav ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
          </IconButton>
        )} */}

        {role === "client" && (
          <IconButton
            className="favorite-icon"
            sx={{
              color: red[800],
              mt: isMobile ? -30 : -50,
              visibility: isMobile ? "visible" : "hidden",
              ":hover": {
                visibility: isMobile ? "visible" : "visible",
              },
            }}
            onClick={() => {
              handlefavorite(!fav);
            }}
          >
            {fav ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
          </IconButton>
        )}

        <CardContent>
          <Typography
            variant={isMobile ? "body2" : "subtitle1"}
            align="center"
            color={props.data.stock === 0 ? "red" : "green"}
            sx={{
              fontSize: "0.9rem",
              marginTop: "-20px",
            }}
          >
            {props.data.stock === 0 ? "Out of Stock" : "In Stock"}
          </Typography>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Grid item>
              <Typography
                gutterBottom
                component="div"
                textAlign="left"
                color="#232F3E"
                sx={{
                  fontSize: "14px",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  maxWidth: "100%",
                }}
              >
                {props.data.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Typography
              gutterBottom
              variant="h6"
              textAlign={"left"}
              color="#1E7071 "
            >
              {`Rs ${props.data.price}`}
            </Typography>
            <Rating
              value={props.data.rating}
              precision={0.5}
              readOnly
              size="small"
            />
          </Grid>
        </CardContent>
        <CardActions>
          {role === "client" ? (
            <Button
              onClick={addTocart}
              fullWidth
              size={isMobile ? "small" : "medium"}
              startIcon={<ShoppingCartOutlinedIcon />}
              sx={{
                background: theme.palette.gradian.main,
                color: "#fff",
                padding: isMobile ? "8px 4px" : "10px 20px",
                width: "100%",
                textTransform: "none",
                "&:hover": {
                  color: theme.palette.customColors.white,
                  background: theme.palette.gradian.hover,
                },
              }}
              disabled={props.data.stock === 0}
            >
              {incart ? "Already in Cart" : "Add to my Cart"}
            </Button>
          ) : (
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile ? "center" : "space-between",
              }}
            >
              <Button
                disabled={userID !== props.data.user_id}
                href={`/product/edit/${props.data._id}`}
                variant="contained"
                size="small"
                startIcon={<ModeEditOutlineOutlinedIcon />}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#444",
                    color: "#62BB46",
                  },
                  mr: isMobile ? 0 : 1,
                  mb: isMobile ? 1 : 0,
                }}
              >
                Edit
              </Button>
              <Button
                disabled={userID !== props.data.user_id}
                onClick={() => {
                  props.clickDelete(props.data._id, props.index);
                }}
                color="error"
                variant="contained"
                size="small"
                startIcon={<DeleteOutlineOutlinedIcon />}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#444",
                    color: "#62BB46",
                  },
                  mb: isMobile ? 1 : 0,
                }}
              >
                Delete
              </Button>
            </Grid>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}
