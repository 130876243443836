import { Box, Container, Divider, Paper } from '@mui/material';
import Header from '../../Components/Header';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import Account from './Components/Account';
import { useSelector } from 'react-redux';
import CustomerOrders from './Components/CustomerOrders';
import { useMediaQuery, useTheme } from '@mui/material';

function Profile(props) {
  //initial value for tab bar
  const [value, setValue] = useState('1');
  const { userID, token } = useSelector((state) => state.loging);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //tab bar handler
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //user data
  const { role } = useSelector((state) => state.loging);

  return (
    <>
      {role === 'client' && <Header token={token} />}
      <Box
        component={Paper}
        sx={{
          typography: 'body1',
        }}
        elevation={0}
        square
        minHeight={'83vh'}
      >
        <Container maxWidth='lg' sx={{ mt: 2 }}>
          {role === 'client' && (
            <TabContext value={value}>
              <Container maxWidth='xl'>
                <Box component={Paper} elevation={2}>
                  <TabList onChange={handleChange}>
                    <Tab label='Account' value='1' />
                    <Tab label='Orders' value='2' sx={{ color: '#232F3E' }} />
                  </TabList>
                </Box>
                <TabPanel value='1' sx={{ m: 0, p: 0, mt: 2 }}>
                  <Account />
                </TabPanel>

                <TabPanel
                  value='2'
                  sx={{ m: 0, p: 0, mt: 2, color: '#232F3E' }}
                >
                  <CustomerOrders />
                </TabPanel>
              </Container>
            </TabContext>
          )}
          {role === 'Vendor' && <Account />}
        </Container>
      </Box>
    </>
  );
}

export default Profile;
