import { Box, Grid, Skeleton } from "@mui/material";

function AgriSkelton() {
  return (
    <>
      <Grid
        item
        xs={6}
        sm={4}
        md={3}
        lg={2}
        xl={2}
        sx={{ mt: { xs: 1, sm: 2 } }}
      >
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{ borderRadius: 1 }}
          width={"70%"}
          height={200}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{ borderRadius: 1 }}
          width={"70%"}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{ borderRadius: 1 }}
          width={"70%"}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          sx={{ borderRadius: 1 }}
          width={"70%"}
        />
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{ borderRadius: 1 }}
          width={"70%"}
          height={30}
        />
      </Grid>
    </>
  );
}

export default AgriSkelton;
