import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const CustomSnackbar = ({
  open,
  onClose,
  severity = 'info',
  message,
  autoHideDuration = 6000,
  position = { vertical: 'bottom', horizontal: 'center' }, // Default position
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={position} // Use position prop for Snackbar positioning
    >
      <Alert onClose={onClose} severity={severity} aria-label='notification'>
        {message}
      </Alert>
    </Snackbar>
  );
};

// Using PropTypes to validate props, including the new position prop
CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  position: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }), // Validate the position object structure
};

export default CustomSnackbar;
