// import { createSlice } from "@reduxjs/toolkit";

// const initialStockState = {
//   stock: 0,
//   productID: null,
// };

// const stockSlice = createSlice({
//   name: "stock",
//   initialState: initialStockState,
//   reducers: {
//     setStock(state, action) {
//       state.productID = action.payload.productID;
//       state.stock = action.payload; // Set the stock to the payload value
//     },
//     increaseStock(state, action) {
//       state.productID = action.payload.productID;
//       state.stock += 1; // Increase stock by the payload value
//     },
//     decreaseStock(state, action) {
//       state.productID = action.payload.productID;
//       state.stock -= 1; // Decrease stock by the payload value
//       if (state.stock < 0) {
//         state.stock = 0; // Ensure stock does not go negative
//       }
//     },
//   },
// });

// export const { setStock, increaseStock, decreaseStock } = stockSlice.actions;
// export default stockSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";

// const initialStockState = {
//   stock: 0,
//   productID: null,
// };

// const stockSlice = createSlice({
//   name: "stock",
//   initialState: initialStockState,
//   reducers: {
//     setStock(state, action) {
//       state.stock = action.payload.stock; // Correctly update stock from payload
//       state.productID = action.payload.productID; // Update productID from payload
//     },
//     increaseStock(state, action) {
//       if (state.productID === action.payload.productID) {
//         state.stock += 1; // Increase stock by 1 if productID matches
//         }

//     },
//     decreaseStock(state, action) {
//       if (state.productID === action.payload.productID && state.stock > 0) {
//         state.stock -= 1; // Decrease stock by 1 if productID matches
//       }
//     },
//   },
// });

// export const { setStock, increaseStock, decreaseStock } = stockSlice.actions;
// export default stockSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialStockState = {
  stocks: {}, // This will store stock counts keyed by productID
};

const stockSlice = createSlice({
  name: "stock",
  initialState: initialStockState,
  reducers: {
    setStock(state, action) {
      const { productID, stock } = action.payload;
      state.stocks[productID] = stock; // Set stock for a specific productID
    },
    increaseStock(state, action) {
      const { productID } = action.payload;
      if (state.stocks[productID]) {
        state.stocks[productID] += 1; // Increase stock by 1 for a specific productID
      } else {
        state.stocks[productID] = 1; // Initialize stock if not present
      }
    },
    decreaseStock(state, action) {
      const { productID } = action.payload;
      if (state.stocks[productID] && state.stocks[productID] > 0) {
        state.stocks[productID] -= 1; // Decrease stock by 1 if productID matches and stock is positive
      }
    },
  },
});

export const { setStock, increaseStock, decreaseStock } = stockSlice.actions;
export default stockSlice.reducer;
