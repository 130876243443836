// import { createSlice } from '@reduxjs/toolkit';

// const checkoutSlice = createSlice({
//   name: 'checkout',
//   initialState: {
//     user_id: '',
//     total: 0,
//     products: {},
//     trackingStatus: 'Pending',
//   },
//   reducers: {
//     setOrderDetails: (state, action) => {
//       state.user_id = action.payload.user_id;
//       state.total = action.payload.total;
//       state.products = action.payload.products;
//       state.trackingStatus = action.payload.tracking_status;
//     },
//     clearOrderDetails: (state) => {
//       state.user_id = null;
//       state.total = 0;
//       state.products = {};
//       state.trackingStatus = 'Pending';
//     },
//   },
// });

// export const { setOrderDetails, clearOrderDetails } = checkoutSlice.actions;
// export default checkoutSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

// Helper function to load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('checkout');
    if (serializedState === null) {
      return {
        user_id: '',
        total: 0,
        products: {},
        trackingStatus: 'Pending',
      };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {
      user_id: '',
      total: 0,
      products: {},
      trackingStatus: 'Pending',
    };
  }
};

// Helper function to save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('checkout', serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

const initialState = loadState();

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setOrderDetails: (state, action) => {
      state.user_id = action.payload.user_id;
      state.total = action.payload.total;
      state.products = action.payload.products;
      state.trackingStatus = action.payload.tracking_status;
      saveState(state); // Save state to localStorage after updating
    },
    clearOrderDetails: (state) => {
      state.user_id = '';
      state.total = 0;
      state.products = {};
      state.trackingStatus = 'Pending';
      saveState(state); // Save state to localStorage after clearing
    },
  },
});

export const { setOrderDetails, clearOrderDetails } = checkoutSlice.actions;
export default checkoutSlice.reducer;
