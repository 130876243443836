import { Box, Paper, Typography, Button } from "@mui/material";
import Header from "../../Components/Header";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
function PageNotFound(props) {
  const { userID, token } = useSelector((state) => state.loging);
  return (
    <>
      <Header token={token} />
      <Box
        component={Paper}
        elevation={1}
        square
        minHeight={"82vh"}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h3"
          textAlign={"center"}
          sx={{ mt: 6, fontFamily: "open sans", color: "#62BB46" }}
        >
          Page Not Found
        </Typography>
        <Box textAlign="center" mt={4}>
          <Button
            component={Link}
            to="/dashboard" // Replace "/dashboard" with the actual route for your dashboard
            variant="contained"
            color="primary"
          >
            Go to Dashboard
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default PageNotFound;
