import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Typography,
  TextField,
  Button,
  Container,
  Divider,
} from "@mui/material";
import logo from "../Assets/OfficalLog.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// const categories = [
//   { title: "Grocery", link: "/grocery" },
//   { title: "Beverages", link: "/beverages" },
//   { title: "Household", link: "/household" },
//   { title: "Vegetables", link: "/vegetables" },
//   { title: "Fruits", link: "/fruits" },
// ];

const usefulLinks = [
  // { title: "About us", link: "/about" },
  // { title: "Contact us", link: "/contact" },
  { title: "Privacy Policy", link: "/privacy-policy" },
  { title: "Terms and Conditions", link: "/terms" },
  // { title: "Locations", link: "/stores" },
];

const Footer = ({ token }) => {
  const [categories, setCategories] = useState([]);
  const [severity, setseverity] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_APIURL}api/Category/Forcustomer/`, {
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((response) => {
        setCategories(response.data.data);
        console.log("categories retrieved", response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity("error");
            setMessage("Token expired, please login again.");
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log("Authentication failed. Logging out.");
              // dispatch(logout());
              // navigate("/login");
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            // setseverity("error");
            // setMessage("An error occurred. Please try again later.");
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity("error");
          setMessage(
            "No response from server. Please check your network connection."
          );
          setOpenSnackbar(true);
        } else {
          setseverity("error");
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  }, [token]);

  return (
    <Box
      sx={{
        background:
          "linear-gradient(-179deg, rgb(0, 0, 0) -75%, rgb(0 153 153) 103%, rgb(26 79 79) 100%)",
      }}
      color="common.white"
      pt={4}
      pb={2}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4} justifyContent="space-between">
          {/* Logo and Contact Info */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems="center"
              mb={2}
              p={2}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ width: 190, height: 50, marginRight: 80 }}
              />
              <p className="mt-9">
                Discover a unique multivendor marketplace where wholesalers and
                shop owners bring you quality products at unbeatable prices.
                Enjoy shopping with the best deals, exclusively on Vendormart.
              </p>
              {/* <Typography variant="h6">Vendor Mart</Typography> */}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <IconButton href="" sx={{ color: "#fff", marginRight: 2 }}>
                <InstagramIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <IconButton href="" sx={{ color: "#fff", marginRight: 2 }}>
                <FacebookIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <IconButton href="" sx={{ color: "#fff", marginRight: 2 }}>
                <YouTubeIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <IconButton href="" sx={{ color: "#fff" }}>
                <TwitterIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </Box>
            {/* <Typography gutterBottom>Phone: 123-456-7890</Typography>
            <Typography>Email: VendorMart@gmail.com</Typography> */}
          </Grid>

          {/* Categories */}
          <Grid item xs={12} sm={6} md={1.5}>
            <Typography variant="subtitle1" fontWeight="bold" mb={3} mt={3}>
              Categories
            </Typography>
            {categories.slice(0, 6).map((category, index) => (
              <Link
                key={index}
                href={category.link}
                color="inherit"
                underline="hover"
                variant="body2"
                display="block"
                mb={0.5}
              >
                {category.categoryname}
              </Link>
            ))}
          </Grid>

          {/* Useful Links */}
          <Grid item xs={12} sm={6} md={1.5}>
            <Typography variant="subtitle1" fontWeight="bold" mb={3} mt={3}>
              Useful Links
            </Typography>
            {usefulLinks.map((link, index) => (
              <Link
                key={index}
                href={link.link}
                color="inherit"
                underline="hover"
                variant="body2"
                display="block"
                mb={0.5}
              >
                {link.title}
              </Link>
            ))}
          </Grid>

          {/* Social Media and Newsletter */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle1" fontWeight="bold" mb={3} mt={3}>
              Contact Us On
            </Typography>
            {/* <Box sx={{ display: "flex", flexDirection: "row" }}>
              <IconButton href="" sx={{ color: "#1bb5b5", marginRight: 2 }}>
                <InstagramIcon sx={{ fontSize: 35 }} />
              </IconButton>
              <IconButton href="" sx={{ color: "#1bb5b5", marginRight: 2 }}>
                <FacebookIcon sx={{ fontSize: 35 }} />
              </IconButton>
              <IconButton href="" sx={{ color: "#1bb5b5", marginRight: 2 }}>
                <YouTubeIcon sx={{ fontSize: 35 }} />
              </IconButton>
              <IconButton href="" sx={{ color: "#1bb5b5" }}>
                <TwitterIcon sx={{ fontSize: 35 }} />
              </IconButton>
            </Box> */}
            <Typography gutterBottom>Phone: 123-456-7890</Typography>
            <Typography>Email: VendorMart@gmail.com</Typography>

            <Box component="form" noValidate autoComplete="off">
              <TextField
                id="email-subscription"
                label="Email Address"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ bgcolor: "#fff", width: "100%" }}
              />
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                  background:
                    "linear-gradient(502deg, rgb(0, 0, 0) -189%, rgb(35 156 156) 80%, rgb(2 106 106) 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(273deg, rgb(0, 0, 0) -189%, rgb(35 156 156) 80%, rgb(2 106 106) 100%)",
                  },
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Grid>

          {/* Divider */}
          <Grid item xs={12}>
            <Divider sx={{ bgcolor: "white", width: "100%", opacity: 0.2 }} />
          </Grid>

          {/* Payment Methods and Copyright */}
          <Grid item xs={12} textAlign="center">
            <Typography variant="body2">
              &copy; All rights reserved 2024 Vendor Mart{" "}
              <Typography variant="body2" sx={{ color: "#FF9933" }}>
                Web Design & Development by Matrix Mantra (Pvt) Ltd.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
