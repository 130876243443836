import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  Skeleton,
  TextField,
  IconButton,
  Grid,
  TableContainer,
  Paper,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import CustomSnackbar from './Components/CustomSnackbar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Store/auth';
import { removeVendorID } from '../../Store/VendorIDSlice';

function VendorManagement() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [vendors, setVendors] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [isLoaded, setLoaded] = useState(false);
  const [search, setSearch] = useState('');
  const { token, userID, role } = useSelector((state) => state.loging);
  const [isEmpty, setEmpty] = useState(false);
  // New state for edit functionality
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [openSuccessEditSnackbar, setOpenSuccessEditSnackbar] = useState(false);
  const [openErrorEditSnackbar, setOpenErrorEditSnackbar] = useState(false);
  const [openSuccessstatusSnackbar, setOpenSuccessstatusSnackbar] =
    useState(false);
  const [openErrorstatusSnackbar, setOpenErrorstatusSnackbar] = useState(false);
  const [errormessage, setErrormessage] = useState('');
  const [sucessmessage, setSucessmessage] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [severity, setseverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbarerror, setOpenSnackbarerror] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APIURL}users/Admin/Vendors?pagination=${page}`,
          {
            headers: { Authorization: 'Agriuservalidation ' + token },
          }
        );

        if (response.data.cdata > 0) {
          console.log(response.data.data);
          setVendors(response.data.data);
          setCount(Math.ceil(response.data.cdata / 3));
          setLoaded(true);
        } else {
          setEmpty(true);
          setLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoaded(true);
      }
    };

    fetchData();
  }, [page, token]);

  const handleEdit = (vendorId) => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users?ID=${vendorId}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        setEditData(res.data);
        setEditDialogOpen(true);
      })
      .catch((error) => {
        console.error('Error fetching vendor data for edit: ', error);
      });
  };

  const handleEditSubmit = () => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users`,
        {
          _id: editData._id,
          username: editData.username,
          email: editData.email,
          password: editData.password,
          address: {
            address: editData.address.address,
            city: editData.address.city,
            province: editData.address.province,
            country: editData.address.country,
            postalcode: editData.address.postalcode,
          },
          mobile_number: editData.mobile_number,
        },
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        console.log(res.data.updated);
        if (res.data && res.data.updated) {
          const updatedVendors = vendors.map((vendor) =>
            vendor._id === editData._id ? editData : vendor
          );
          setVendors(updatedVendors);
          setEditDialogOpen(false);
          setOpenSuccessEditSnackbar(true);
          setSucessmessage('Vendor is updated successfully');
        } else {
          setOpenErrorEditSnackbar(true);
          setErrormessage('Error updating vendor. Please try again.');
        }
      })
      .catch((error) => {
        // console.error("Error updating vendor: ", error);
        // setErrormessage("check you internet connection or try again.");
        // setOpenErrorEditSnackbar(true);

        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(removeVendorID());
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
    // setOpenErrorEditSnackbar(true);
    // setErroreditmessage("Error updating vendor.");
  };

  const handleBlockORApprove = (vendorId, action) => {
    setCurrentVendor(vendorId);
    setCurrentAction(action);
    setDialogOpen(true);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleAction = (action) => {
    axios
      .put(
        `${process.env.REACT_APP_APIURL}users/Admin/ApproveOrBlock`,
        {
          _id: currentVendor,
          Approveled: action,
        },
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        if (res.data) {
          console.log(res.data);

          // Update the vendo$ array in the state
          const updatedVendors = vendors.map((vendor) =>
            vendor._id === currentVendor
              ? { ...vendor, Approveled: action }
              : vendor
          );
          setVendors(updatedVendors);

          setOpenSnackbar(true);
          setDialogOpen(false);
          // console.log(res.data);
          // setOpenSnackbar(true);
          // setDialogOpen(false);
        }
      })
      .catch((er) => {
        console.log('error ' + er);
        console.log(er.message);
      });
    setOpenSnackbar(true);
    setDialogOpen(false);
  };

  const searchHandler = async () => {
    try {
      const urlsearch = `${process.env.REACT_APP_APIURL}users/Admin/Vendors?pagination=${page}&title=${search}`;

      setLoaded(false);
      setEmpty(false);

      const response = await axios.get(urlsearch, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      });

      if (response.data.data.length !== 0) {
        console.log('search...');
        console.log(response.data.data);
        setVendors(response.data.data);
        setCount(Math.ceil(response.data.cdata / 3));
        setLoaded(true);
      } else {
        setEmpty(true);
        setLoaded(true);
      }
    } catch (error) {
      console.error(error);
      setLoaded(true);
      setEmpty(true);
    }
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);
  const handleDeleteAction = (vendorId) => {
    setCurrentVendor(vendorId);

    setConfirmDialogOpen(true);
  };
  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_APIURL}users/Admin/${currentVendor}`, {
        headers: { Authorization: 'Agriuservalidation ' + token },
      })
      .then((res) => {
        if (res.data && res.data.deleted) {
          // Filter out the deleted vendor from the vendo$ array in the state
          const updatedVendors = vendors.filter(
            (vendor) => vendor._id !== currentVendor
          );
          setVendors(updatedVendors);
          setOpenSuccessEditSnackbar(true);
          setSucessmessage('Vendor is deleted successfully');
          setConfirmDialogOpen(false); // Close the confirmation dialog
        } else {
          setErrormessage('Error deleting vendor. Please try again.');
          setOpenErrorEditSnackbar(true);
          setConfirmDialogOpen(false); // Close the confirmation dialog on error
        }
      })
      .catch((error) => {
        // console.error("Error deleting vendor: ", error);
        // setErrormessage("Error deleting vendor. Please try again.");
        // setOpenErrorEditSnackbar(true);
        // setConfirmDialogOpen(false); // Close the confirmation dialog on error
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          console.log(statusCode, error.response.data);
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              console.log('Authentication failed. Logging out.');
              dispatch(removeVendorID());
              dispatch(logout());
              navigate('/login');
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other erro$
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };

  return (
    <Box sx={{ py: 1, px: 2 }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={`Vendor is ${currentAction} successfully`}
      />
      <CustomSnackbar
        open={openErrorstatusSnackbar}
        onClose={() => setOpenErrorstatusSnackbar(false)}
        severity='error'
        message={`Vendor is ${currentAction} Not Updated`}
      />
      <CustomSnackbar
        open={openSuccessEditSnackbar}
        onClose={() => setOpenSuccessEditSnackbar(false)}
        severity='success'
        message={sucessmessage}
      />

      <CustomSnackbar
        open={openErrorEditSnackbar}
        onClose={() => setOpenErrorEditSnackbar(false)}
        severity='error'
        message={errormessage}
      />

      <CustomSnackbar
        open={openSnackbarerror}
        onClose={() => setOpenSnackbarerror(false)}
        severity={severity}
        message={message}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{'Confirm Action'}</DialogTitle>{' '}
        <DialogContent>
          {' '}
          <DialogContentText>
            Are you sure you want to perform this {currentAction} action on the
            vendor?{' '}
          </DialogContentText>{' '}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => handleAction(currentAction)}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Typography
          variant='h5'
          sx={{ fontWeight: 'bold', textAlign: 'center', mb: 3 }}
        >
          Vendor Management
        </Typography>
      </Box>

      <Grid container spacing={1}>
        {/* Search Section */}
        <Grid container spacing={1}>
          <Grid item xs={10} sm={6} mb={2}>
            <TextField
              label='Search'
              variant='outlined'
              onKeyUp={searchHandler}
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} sm={2} mb={2}>
            <IconButton
              onClick={searchHandler}
              sx={{
                color: '#fff',
                bgcolor: '#232F3E',
                borderRadius: 1,
                p: 1,
                '&:hover': {
                  backgroundColor: '#232F3E',
                  color: '#FF9933',
                },
              }}
              size='large'
            >
              <SearchIcon fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          {/* Table Section */}
          <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Approval Status</TableCell>
                  <TableCell>Approve / Reject</TableCell>
                  <TableCell>Edit / Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isEmpty && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box
                        mt={7}
                        component={Typography}
                        sx={{ textAlign: 'center' }}
                      >
                        No Vendor found
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {!isEmpty &&
                  isLoaded &&
                  vendors.map((vendor) => (
                    <TableRow key={vendor._id}>
                      <TableCell>{vendor.username}</TableCell>
                      <TableCell>{vendor.email}</TableCell>
                      <TableCell>{vendor.mobile_number}</TableCell>
                      <TableCell>{vendor.role}</TableCell>
                      <TableCell>
                        {`${vendor.address.address}, ${vendor.address.city}, ${vendor.address.province}, ${vendor.address.country}, ${vendor.address.postalcode}`}
                      </TableCell>
                      <TableCell>{vendor.Approveled}</TableCell>
                      <TableCell>
                        {vendor.Approveled === 'Approved' ? (
                          <Button
                            color='secondary'
                            variant='contained'
                            onClick={() =>
                              handleBlockORApprove(vendor._id, 'Blocked')
                            }
                            // disabled={vendor.Approveled === "Blocked"}
                          >
                            Blocked
                          </Button>
                        ) : (
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              handleBlockORApprove(vendor._id, 'Approved')
                            }
                            // disabled={vendor.Approveled === "Approved"}
                            sx={{ my: 1 }}
                          >
                            Approved
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => handleEdit(vendor._id)}
                          sx={{ my: 1 }}
                        >
                          Edit
                        </Button>
                        <Button
                          color='secondary'
                          variant='contained'
                          onClick={() => handleDeleteAction(vendor._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                {!isLoaded && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Skeleton animation='wave' height={60} />
                      <Skeleton animation='wave' height={60} />
                      <Skeleton animation='wave' height={60} />
                      <Skeleton animation='wave' height={60} />
                      <Skeleton animation='wave' height={60} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </TableContainer>

        {/* Pagination Section */}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination count={count} color='primary' onChange={handleChange} />
        </Grid>
      </Grid>

      <Box sx={{ p: 3 }}>
        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>{'Edit Vendor'}</DialogTitle>
          <DialogContent>
            <TextField
              label='Username'
              variant='outlined'
              defaultValue={editData ? editData.username : ''}
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  username: e.target.value,
                }))
              }
            />
            <TextField
              label='Email'
              variant='outlined'
              defaultValue={editData ? editData.email : ''}
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  email: e.target.value,
                }))
              }
            />
            <TextField
              type='password'
              label='Password'
              variant='outlined'
              fullWidth
              // Assuming editData contains the password field; if not, handle accordingly
              defaultValue={editData ? editData.password : ''}
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  password: e.target.value,
                }))
              }
            />
            <TextField
              label='Mobile Number'
              variant='outlined'
              defaultValue={editData ? editData.mobile_number : ''}
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  mobile_number: e.target.value,
                }))
              }
            />
            <TextField
              label='Address'
              variant='outlined'
              defaultValue={
                editData && editData.address ? editData.address.address : ''
              }
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  address: { ...prevData.address, address: e.target.value },
                }))
              }
            />
            <TextField
              label='City'
              variant='outlined'
              defaultValue={
                editData && editData.address ? editData.address.city : ''
              }
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  address: { ...prevData.address, city: e.target.value },
                }))
              }
            />
            {/* <TextField
              label="Province"
              variant="outlined"
              defaultValue={
                editData && editData?.address ? editData?.address.province : ""
              }
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  address: { ...prevData.address, province: e.target.value },
                }))
              }
            /> */}
            <TextField
              label='Country'
              variant='outlined'
              defaultValue={
                editData && editData.address ? editData.address.country : ''
              }
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  address: { ...prevData.address, country: e.target.value },
                }))
              }
            />
            <TextField
              label='Postal Code'
              variant='outlined'
              defaultValue={
                editData && editData.address ? editData.address.postalcode : ''
              }
              fullWidth
              onChange={(e) =>
                setEditData((prevData) => ({
                  ...prevData,
                  address: { ...prevData.address, postalcode: e.target.value },
                }))
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleEditSubmit}>Save</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Confirmation'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you want to delete this vendor?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleDelete} color='secondary' autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default VendorManagement;
