// import { Avatar, Box, Button, Grid, Modal } from '@mui/material';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import { grey, red } from '@mui/material/colors';
// import DeleteIcon from '@mui/icons-material/Delete';
// import UploadIcon from '@mui/icons-material/Upload';
// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
// import { useNavigate } from 'react-router-dom';
// //css
// import { useDispatch } from 'react-redux';
// import { logout } from '../../Store/auth';
// import { removeVendorID } from '../../Store/VendorIDSlice';
// const style = {
//   textAlign: 'center',
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: '#232F3E',
//   boxShadow: 24,
//   p: 3,
//   borderRadius: 2,
// };

// function ImageModal(props) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   //data
//   const [image, setImage] = useState(null);
//   const [previewUrl, setPreviewUrl] = useState('');

//   //sucess messge
//   const [opensuccess, setopensuccess] = useState(false);
//   const [sucessmessage, setsucessmessage] = useState('');
//   const [successseverity, setsuccessseverity] = useState('');

//   const [severity, setseverity] = useState('');
//   const [message, setMessage] = useState('');
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   //handler
//   const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

//   //handler
//   const handleFile = (file) => {
//     if (file.size > 1048576) {
//       setseverity('error');
//       setMessage('File size exceeds 1 MB. Please select a smaller file.');
//       setOpenSnackbar(true);
//       return;
//     }

//     if (!validImageTypes.includes(file.type)) {
//       setseverity('error');
//       setMessage(
//         'Invalid file type. Please select a valid image file. jpeg,png and gif are allowed.'
//       );
//       setOpenSnackbar(true);
//       return;
//     }

//     setImage(file);
//     setPreviewUrl(URL.createObjectURL(file));
//   };
//   //drag and drop handle$
//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleOnDrop = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     let imageFile = event.dataTransfer.files[0];
//     handleFile(imageFile);
//   };

//   const handleOnChange = (event) => {
//     let imageFile = event.target.files[0];
//     handleFile(imageFile);
//   };
//   //upload dp
//   const uploadImage = () => {
//     const data = new FormData();
//     data.append('image', image);
//     data.append('id', props.userID);

//     axios
//       .post(`${process.env.REACT_APP_APIURL}users/dp/${props.userID}`, data, {
//         headers: { Authorization: 'Agriuservalidation ' + props.token },
//       })
//       .then((res) => {
//         setopensuccess(true);
//         setsuccessseverity('success');
//         setsucessmessage('Profile picture uploaded Successfully');

//         // Delay the reload to briefly show the message
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000); // Adjust the delay as needed
//       })
//       .catch((error) => {
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           const statusCode = error.response.status;
//           if (statusCode === 400) {
//             setseverity('error');
//             setMessage('Token expired, please login again.');
//             setOpenSnackbar(true);

//             // Delay logout and redirection to ensure the user sees the toast message
//             setTimeout(() => {
//               dispatch(removeVendorID());
//               dispatch(logout());
//               window.location.href = 'https://vendormart.lk/';
//             }, 7000);
//           } else {
//             // Handle other erro$
//             setseverity('error');
//             setMessage('An error occurred. Please try again later.');
//             setOpenSnackbar(true);
//           }
//         } else if (error.request) {
//           // The request was made but no response was received
//           setseverity('error');
//           setMessage(
//             'No response from server. Please check your network connection.'
//           );
//           setOpenSnackbar(true);
//         } else {
//           setseverity('error');
//           // Something happened in setting up the request that triggered an Error
//           setMessage(`Error: ${error.message}`);
//           setOpenSnackbar(true);
//         }
//       });
//   };
//   //delete dp
//   const removeImage = () => {
//     axios
//       .delete(`${process.env.REACT_APP_APIURL}users/dp/${props.userID}`, {
//         headers: { Authorization: 'Agriuservalidation ' + props.token },
//       })
//       .then((res) => {
//         setPreviewUrl('');
//         // window.location.reload();
//         setopensuccess(true);
//         setsuccessseverity('success');
//         setsucessmessage('Profile picture removed Successfully');

//         // Delay the reload to briefly show the message
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000); // Adjust the delay as needed
//       })
//       .catch((error) => {
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           const statusCode = error.response.status;
//           if (statusCode === 400) {
//             setseverity('error');
//             setMessage('Token expired, please login again.');
//             setOpenSnackbar(true);

//             // Delay logout and redirection to ensure the user sees the toast message
//             setTimeout(() => {
//               dispatch(removeVendorID());
//               dispatch(logout());
//               window.location.href = 'https://vendormart.lk/';
//             }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
//           } else {
//             // Handle other erro$
//             setseverity('error');
//             setMessage('An error occurred. Please try again later.');
//             setOpenSnackbar(true);
//           }
//         } else if (error.request) {
//           // The request was made but no response was received
//           setseverity('error');
//           setMessage(
//             'No response from server. Please check your network connection.'
//           );
//           setOpenSnackbar(true);
//         } else {
//           setseverity('error');
//           // Something happened in setting up the request that triggered an Error
//           setMessage(`Error: ${error.message}`);
//           setOpenSnackbar(true);
//         }
//       });
//   };
//   //useEffect hook
//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_APIURL}users/dp/${props?.userID}`, {
//         headers: { Authorization: 'Agriuservalidation ' + props?.token },
//       })
//       .then((res) => {
//         setPreviewUrl(res?.data?.images);
//         // window.location.reload();
//       })
//       .catch((error) => {
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           const statusCode = error.response.status;
//           if (statusCode === 400) {
//             setseverity('error');
//             setMessage('Token expired, please login again.');
//             setOpenSnackbar(true);

//             // Delay logout and redirection to ensure the user sees the toast message
//             setTimeout(() => {
//               dispatch(removeVendorID());
//               dispatch(logout());
//               window.location.href = 'https://vendormart.lk/';
//             }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
//           } else {
//             // Handle other erro$
//             // setseverity('error');
//             // setMessage('An error occurred. Please try again later.');
//             // setOpenSnackbar(true);
//           }
//         } else if (error.request) {
//           // The request was made but no response was received
//           setseverity('error');
//           setMessage(
//             'No response from server. Please check your network connection.'
//           );
//           setOpenSnackbar(true);
//         } else {
//           setseverity('error');
//           // Something happened in setting up the request that triggered an Error
//           setMessage(`Error: ${error.message}`);
//           setOpenSnackbar(true);
//         }
//       });
//   }, []);

//   return (
//     <>
//       <CustomSnackbar
//         open={opensuccess}
//         onClose={() => {
//           setopensuccess(false);
//         }}
//         message={sucessmessage}
//         severity={successseverity}
//       />
//       <CustomSnackbar
//         open={openSnackbar}
//         onClose={() => {
//           setOpenSnackbar(false);
//         }}
//         message={message}
//         severity={severity}
//       />
//       <Modal open={props.open} onClose={props.handleClose}>
//         <Box sx={style}>
//           <Box sx={{ textAlign: 'center' }} width='100%'>
//             <label
//               style={{ width: '100%', bgcolor: 'red' }}
//               htmlFor='image-dp'
//               onDragOver={handleDragOver}
//               onDrop={handleOnDrop}
//               onChange={handleOnDrop}
//             >
//               <Avatar
//                 src={previewUrl && previewUrl}
//                 variant='square'
//                 sx={{
//                   borderRadius: '4px',
//                   bgcolor: grey[500],
//                   color: '#333',
//                   width: 330,
//                   minHeight: 330,
//                   maxHeight: 450,
//                   height: 'auto',
//                   cursor: 'pointer',
//                 }}
//               >
//                 {!previewUrl && (
//                   <CloudUploadIcon
//                     sx={{
//                       color: '#333',
//                       width: 300,
//                       height: 300,
//                     }}
//                   />
//                 )}
//               </Avatar>
//             </label>
//             <br />
//             <input
//               hidden
//               id='image-dp'
//               type={'file'}
//               onChange={handleOnChange}
//             />
//             <Grid
//               container
//               direction='row'
//               alignItems={'center'}
//               justifyContent='space-between'
//             >
//               <Grid item>
//                 <Button
//                   onClick={uploadImage}
//                   disableElevation
//                   sx={{
//                     bgcolor: '#FF9933',
//                     color: '#fff',
//                     fontFamily: 'open sans',
//                     '&:hover': {
//                       backgroundColor: '#FF9933',
//                       color: '#000',
//                     },
//                   }}
//                   variant='contained'
//                   endIcon={<UploadIcon fontSize='small' />}
//                 >
//                   Upload Image
//                 </Button>
//               </Grid>
//               <Grid item>
//                 <Button
//                   onClick={removeImage}
//                   disableElevation
//                   sx={{
//                     color: '#fff',
//                     bgcolor: red[900],
//                     fontFamily: 'open sans',
//                     '&:hover': {
//                       backgroundColor: 'red[900]',
//                       color: '#000',
//                     },
//                   }}
//                   color='error'
//                   endIcon={<DeleteIcon fontSize='small' />}
//                   variant='contained'
//                 >
//                   Remove Image
//                 </Button>
//               </Grid>
//               <Grid item>
//                 <Button
//                   sx={{
//                     color: '#fff',
//                     fontFamily: 'open sans',
//                     '&:hover': {
//                       color: '#FF9933',
//                     },
//                   }}
//                   onClick={props.handleClose}
//                 >
//                   Cancel
//                 </Button>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//       </Modal>
//     </>
//   );
// }

// export default ImageModal;

import { Avatar, Box, Grid, Modal, Tooltip, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { grey, red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
//css
import { useDispatch } from 'react-redux';
import { logout } from '../../Store/auth';
import { removeVendorID } from '../../Store/VendorIDSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
const style = {
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#232F3E',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};

function ImageModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //data
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  //sucess messge
  const [opensuccess, setopensuccess] = useState(false);
  const [sucessmessage, setsucessmessage] = useState('');
  const [successseverity, setsuccessseverity] = useState('');

  const [severity, setseverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Loading state for buttons
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  //handler
  const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

  //handler
  const handleFile = (file) => {
    if (file.size > 1048576) {
      setseverity('error');
      setMessage('File size exceeds 1 MB. Please select a smaller file.');
      setOpenSnackbar(true);
      return;
    }

    if (!validImageTypes.includes(file.type)) {
      setseverity('error');
      setMessage(
        'Invalid file type. Please select a valid image file. jpeg,png and gif are allowed.'
      );
      setOpenSnackbar(true);
      return;
    }

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  };
  //drag and drop handle$
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleOnDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  };

  const handleOnChange = (event) => {
    let imageFile = event.target.files[0];
    handleFile(imageFile);
  };
  //upload dp
  const uploadImage = () => {
    setLoadingUpload(true);
    const data = new FormData();
    data.append('image', image);
    data.append('id', props.userID);

    axios
      .post(`${process.env.REACT_APP_APIURL}users/dp/${props.userID}`, data, {
        headers: { Authorization: 'Agriuservalidation ' + props.token },
      })
      .then((res) => {
        setopensuccess(true);
        setsuccessseverity('success');
        setsucessmessage('Profile picture uploaded Successfully');
        setLoadingUpload(false);

        // Delay the reload to briefly show the message
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Adjust the delay as needed
      })
      .catch((error) => {
        setLoadingUpload(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              dispatch(removeVendorID());
              dispatch(logout());
              window.location.href = 'https://vendormart.lk/';
            }, 7000);
          } else {
            // Handle other errors
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };
  //delete dp
  const removeImage = () => {
    setLoadingRemove(true);
    axios
      .delete(`${process.env.REACT_APP_APIURL}users/dp/${props.userID}`, {
        headers: { Authorization: 'Agriuservalidation ' + props.token },
      })
      .then((res) => {
        setPreviewUrl('');
        setopensuccess(true);
        setsuccessseverity('success');
        setsucessmessage('Profile picture removed Successfully');
        setLoadingRemove(false);

        // Delay the reload to briefly show the message
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Adjust the delay as needed
      })
      .catch((error) => {
        setLoadingRemove(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              dispatch(removeVendorID());
              dispatch(logout());
              window.location.href = 'https://vendormart.lk/';
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            setseverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  };
  //useEffect hook
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users/dp/${props?.userID}`, {
        headers: { Authorization: 'Agriuservalidation ' + props?.token },
      })
      .then((res) => {
        setPreviewUrl(res?.data?.images);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setseverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);

            // Delay logout and redirection to ensure the user sees the toast message
            setTimeout(() => {
              dispatch(removeVendorID());
              dispatch(logout());
              window.location.href = 'https://vendormart.lk/';
            }, 7000); // Adjust this duration as needed, 6000ms matches the autoHideDuration of the Snackbar
          } else {
            // Handle other errors
            // setseverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setseverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setseverity('error');
          // Something happened in setting up the request that triggered an Error
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  }, []);

  return (
    <>
      <CustomSnackbar
        open={opensuccess}
        onClose={() => {
          setopensuccess(false);
        }}
        message={sucessmessage}
        severity={successseverity}
      />
      <CustomSnackbar
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
        message={message}
        severity={severity}
      />

      <Modal open={props.open} onClose={props.handleClose}>
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }} width='100%'>
            <Typography
              variant='h5'
              sx={{ fontWeight: 'bold', color: '#FFFFFF', mb: 2 }}
            >
              Upload Profile Picture
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ fontWeight: 'bold', color: '#FFFFFF', mb: 2 }}
            >
              Click to upload image or Drag & drop it here
            </Typography>
            <Tooltip title='Click to upload another image or Drag & drop it here'>
              <label
                style={{ width: '100%', bgcolor: 'red' }}
                htmlFor='image-dp'
                onDragOver={handleDragOver}
                onDrop={handleOnDrop}
                onChange={handleOnDrop}
              >
                <Avatar
                  src={previewUrl && previewUrl}
                  variant='square'
                  sx={{
                    borderRadius: '4px',
                    bgcolor: grey[500],
                    color: '#333',
                    width: 330,
                    minHeight: 330,
                    maxHeight: 450,
                    height: 'auto',
                    cursor: 'pointer',
                  }}
                >
                  {!previewUrl && (
                    <CloudUploadIcon
                      sx={{
                        color: '#333',
                        width: 300,
                        height: 300,
                      }}
                    />
                  )}
                </Avatar>
              </label>
            </Tooltip>
            <br />
            <input
              hidden
              id='image-dp'
              type={'file'}
              onChange={handleOnChange}
            />
            <Grid
              container
              direction='row'
              alignItems={'center'}
              justifyContent='space-between'
            >
              <Grid item>
                <Button
                  onClick={uploadImage}
                  sx={{
                    bgcolor: '#FF9933',
                    color: '#fff',
                    fontFamily: 'open sans',
                    '&:hover': {
                      backgroundColor: '#FF9933',
                      color: '#000',
                    },
                  }}
                  variant='contained'
                  endIcon={<UploadIcon fontSize='small' />}
                >
                  {loadingUpload ? 'Uploading...' : 'Upload'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={removeImage}
                  loading={loadingRemove}
                  sx={{
                    color: '#fff',
                    bgcolor: red[900],
                    fontFamily: 'open sans',
                    '&:hover': {
                      backgroundColor: red[900],
                      color: '#000',
                    },
                  }}
                  color='error'
                  endIcon={<DeleteIcon fontSize='small' />}
                  variant='contained'
                >
                  {loadingRemove ? 'Removing...' : 'Remove'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    color: '#fff',
                    fontFamily: 'open sans',
                    '&:hover': {
                      color: '#FF9933',
                    },
                  }}
                  onClick={props.handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ImageModal;
