import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemAvatar,
  Avatar,
  Modal,
} from '@mui/material';
import Header from '../../Components/Header';
import AgriCard from '../Utils/AgriCard';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AgriSkelton from '../../Pages/AllSkeleton/AgriSkelton';
import Ack from '../../Components/Ack';
import AdminNav from '../AdminNavigation/AdminNav';
import AdminDashboard from '../Adminpages/AdminDashboard';
import VendorManagement from '../Adminpages/VendorManagement';
import CustomerManagement from '../Adminpages/CustomerManagement';
import ProductManagement from '../Adminpages/ProductManagement';
import OrderManagement from '../Adminpages/OrderManagement';
import SearchIcon from '@mui/icons-material/Search';
import CategoryView from '../Category/CategoryView';
import Recommendations from '../Category/Recommendations';
import ImageSlider from '../Category/ImageSlider';
import CategoriesDropdown from '../Category/CategoriesDropdown';
import CategoryManagement from '../Adminpages/CategoryManagement';
import CommunicationManagement from '../Adminpages/CommunicationManagement';
import Settings from '@mui/icons-material/Settings';
import VendorsDropdown from '../VendorCategory/VendorsDropdown';
import CategoryIcon from '@mui/icons-material/Category';
import CategoryDialog from '../Category/CategoryDialog';
import Footer from '../../Components/Footer';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../Store/auth';
import CustomSnackbar from '../Adminpages/Components/CustomSnackbar';
import { useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu'; // Import the menu icon
import SearchResultList from '../SearchResultList/SearchResultList';
// import VendorSideNavigationBar from '../VendorSideNavigationBar/VendorSideNavigationBar';
import Products from '../Profile/Components/Products';
import VendorOrders from '../Profile/Components/VendorOrders';
import Profile from '../Profile/Profile';
import VendorDashboard from '../VendorSideNavigationBar/VendorDashboradPages/VendorDashborad';
import { useLocation } from 'react-router-dom';
import CategorySidebar from '../Category/CategorySidebar';
import WithOutTokenAgricard from '../Utils/WithOutTokenAgricard';
import { addVendorID, removeVendorID } from '../../Store/VendorIDSlice'; // Import Redux actions
import MobileImageSilder from '../Category/MobileImageSilder';
import EmptyUI from '../EmptyUI/EmptyUI';
function WithoutTokenDashboard(props) {
  const location = useLocation();

  const targetSectionRef = useRef(null);
  const [selectionCompleted, setSelectionCompleted] = useState(false);
  const formatAddress = (address) => {
    return `${address.address}, ${address.city}, ${address.province}, ${address.country},  ${address.postalcode}`;
  };
  // This function will be passed to VendorsDropdown and called upon selection completion
  const handleSelectionComplete = (isComplete) => {
    setSelectionCompleted(isComplete);
  };

  // Function to scroll to the target section
  const scrollToSection = () => {
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //product data
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [favorites, setFavorites] = useState([]);
  const [carts, setcarts] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [search, setSearch] = useState();
  const [isEmpty, setEmpty] = useState(false);
  const [selectedPage, setSelectedPage] = useState('dashboard');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [severity, setseverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentvendor, setCurrentvendor] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPageforvendor, setSelectedPageforvendor] =
    useState('dashboard');

  const [getAllproducts, setGetAllproducts] = useState([]);

  const fetchAllProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/productsforcustomer/getforcustomer`
      )
      .then((response) => {
        setGetAllproducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching :', error);
      });
  };

  const fetchProducts = (subcategory) => {
    if (!subcategory || !selectedCategory) return;

    // Assuming getAllProducts is the state where all products are stored
    const filteredProducts = getAllproducts.filter((product) => {
      return (
        product.sub_category.sub_categoryID === hoveredSubcategory._id &&
        product.category.categoryID === selectedCategory._id
      );
    });

    setProductscategory(filteredProducts);
    setProducts(filteredProducts);
    setPage(Math.ceil(filteredProducts.length / 12));
    setEmpty(filteredProducts.length === 0);
    setTotalPagescategory(Math.ceil(filteredProducts.length / 12));
    setEmptycategory(filteredProducts.length === 0);
  };

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
  };

  const hadlecurrentvendor = (vendor) => {
    setCurrentvendor(vendor);
  };
  const [showAd, setShowAd] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAd(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Function to close the ad modal
  const handleCloseAd = () => {
    setShowAd(false);
  };

  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchAllProducts();
    const vendorID = location.state?.vendorId || null;
    if (vendorID) {
      dispatch(addVendorID({ vendorID })); // Set the vendorID in Redux
    }

    // return () => {
    //   dispatch(removeVendorID()); // Clean up by removing the vendorID when the component unmounts
    // };
  }, [dispatch, location.state?.vendorId]);
  useEffect(() => {
    const vendorID = location.state?.vendorId;
    setSelectedVendor(vendorID);

    setLoading(true);

    if (vendorID) {
      axios
        .get(
          `${process.env.REACT_APP_APIURL}api/vendorone/categorys/${vendorID}`
        )
        .then((response) => {
          setCategories(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching categories:', error);
          setLoading(false);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_APIURL}api/Category/Forcustomer/`)
        .then((response) => {
          setCategories(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('An error occurred while fetching categories:', error);
        });
    }
  }, [location.state?.vendorId]);

  //popup
  const [open, setOpen] = useState(false);

  //product delete
  const [id, setID] = useState();
  const [index, setIndex] = useState();
  const [SeachResult, setSeachResult] = useState([]);

  //search product
  const searchHandler = (searchText) => {
    setShowSearch(searchText !== '' ? true : false);
    let urlsearch;

    if (selectedVendor) {
      urlsearch = `${process.env.REACT_APP_APIURL}api/withouttoken/Products?pagination=${page}&title=${searchText}&owner=${selectedVendor}`;
    } else {
      urlsearch = `${process.env.REACT_APP_APIURL}api/withouttoken/Products?pagination=${page}&title=${searchText}`;
    }
    setPage(1);
    setLoaded(false);
    setEmpty(false);
    axios
      .get(`${urlsearch}`, {})
      .then((res) => {
        if (res.data.data.length > 0) {
          setProducts(res.data.data);
          setCount(Math.ceil(res.data.cdata / 12));
          setSeachResult(res.data.data);
          setLoaded(true);
          setEmpty(false);
        } else {
          // setSeachResult(res.data.data.length === 0 ? 0 : res.data.data.length);
          setEmpty(true);
          setLoaded(true);
        }
      })
      .catch((error) => {});
  };

  //page change[pagination]
  const handleChange = (event, value) => {
    setPage(value);
  };

  //useEffect call
  useEffect(() => {
    let urlget;

    if (selectedVendor) {
      urlget = `${process.env.REACT_APP_APIURL}api/withouttoken/Products?pagination=${page}&owner=${selectedVendor}`;
    }

    axios
      .get(`${urlget}`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setProducts(res.data.data);
          setLoaded(true);
          setEmpty(false);

          const p_count = Math.ceil(+res.data.cdata / 12);
          setCount(p_count);
        } else {
          setEmpty(true);
          setLoaded(true);
        }
      })
      .catch((error) => {});
  }, [page, selectedVendor, page, count]);

  //popup handler
  const handleClose = () => {
    setOpen(false);
  };

  const [currentPage, setCurrentPage] = useState('dashboard');

  const handleSelectPage = (page) => {
    setCurrentPage(page);
  };
  const handlecolor = (page) => {
    setCurrentPage(page);
  };

  // const handlePageChange = (page) => {
  //   setSelectedPageforvendor(page);
  // };
  // #################################################################################################################

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredSubcategory, setHoveredSubcategory] = useState(null);
  const [Productscategory, setProductscategory] = useState([]);
  const [emptycategory, setEmptycategory] = useState(false);
  const [categoriess, setCategoriess] = useState([]);
  const [totalPagescategory, setTotalPagescategory] = useState(0);
  const [currentPagecategory, setCurrentPagecategory] = useState(1);

  const [loadingcategory, setLoadingcategoty] = useState(true);

  useEffect(() => {
    if (selectedVendor) {
      axios
        .get(
          `${process.env.REACT_APP_APIURL}api/vendorone/categorys/${selectedVendor}`
        )
        .then((response) => {
          setCategoriess(response.data);
          setLoadingcategoty(false);
        })
        .catch((error) => {
          console.error('Error fetching categories:', error);
          setLoadingcategoty(false);
        });
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (!hoveredSubcategory || !selectedCategory) return;
    fetchProducts(hoveredSubcategory);
  }, [hoveredSubcategory, currentPagecategory]);

  // const fetchProducts = (subcategory) => {
  //   if (!subcategory || !selectedCategory) return;
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_APIURL}api/subcategorytoFindProduct/${hoveredSubcategory._id}?categoryId=${selectedCategory._id}&page=${currentPage}&limit=4`
  //     )
  //     .then((response) => {
  //       setProductscategory(response.data.products);
  //       setProducts(response.data.products);
  //       setPage(response.data.totalPages);
  //       setEmpty(response.data.products.length === 0);
  //       setTotalPagescategory(response.data.totalPages);
  //       setEmptycategory(response.data.products.length === 0);
  //     })
  //     .catch((error) => {
  //       console.error('An error occurred while fetching products:', error);
  //     });
  // };

  const handleCategoryHover = (category) => {
    setSelectedCategory(category);
    setCurrentPagecategory(1); // Reset to the fi$t page when a new category is selected
  };

  const handleSubcategoryHover = (subcategory) => {
    setHoveredSubcategory(subcategory);
    setCurrentPagecategory(1); // Reset to the fi$t page when a new subcategory is hovered
    fetchProducts(subcategory);
  };

  const handlePageChange = (event, value) => {
    setCurrentPagecategory(value);
  };
  return (
    <>
      <Header
        handler={handleSelectPage}
        searchHandler={searchHandler}
        vendorDetails={currentvendor}
      />

      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseAlert}
        severity={severity}
        message={message}
      />
      {/* admindashboard   ################################################### */}
      <Box>
        <Box
          component={Paper}
          elevation={1}
          square
          minHeight={'83vh'}
          // sx={{ backgroundColor: "#fff" }}
          sx={{
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {/* showSearch options   ################################################### */}
          <div
            sx={{
              display: showSearch ? 'block' : 'none',
            }}
          >
            {showSearch && (
              <SearchResultList
                searchResult={SeachResult}
                scrollToSection={scrollToSection}
              />
            )}
          </div>

          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              // flexDirection: "row",
              alignItems: 'center',
              justifyContent: 'space-evenly',
              textAlign: 'center',
            }}
          >
            <Grid
              item
              sx={{
                display: isMobile ? 'none' : 'block',
                zIndex: 1000,
                position: 'absolute',
                top: '60px',
                left: '10px',
                // right: "20px",

                // bgcolor: "#fff",
              }}
            >
              {/* <CategorySidebar vendorID={selectedVendor} /> */}
              <CategorySidebar
                categoriess={categoriess}
                hoveredSubcategory={hoveredSubcategory}
                selectedCategory={selectedCategory}
                emptycategory={emptycategory}
                Productscategory={Productscategory}
                currentPagecategory={currentPagecategory}
                totalPagescategory={totalPagescategory}
                handlePageChange={handlePageChange}
                handleSubcategoryHover={handleSubcategoryHover}
                handleCategoryHover={handleCategoryHover}
              />
            </Grid>
            {/* <Grid
              item
              xs={12} // Takes full width on extra-small screens
              sm={8} // Takes 2/3 of the width on small screens and larger
              md={9} // Takes 3/4 of the width on medium screens and larger
              lg={10} // Takes 5/6 of the width on large screens and larger
            > */}
            {/* <Box
                sx={{
                  // marginRight: "280px",
                  position: 'relative',
                  marginTop: '35px',

                  marginLeft: isMobile ? '10px' : '140px',

                  width: isMobile ? '100%' : '97%',
                  height: '400px',
                }}
              >
                <ImageSlider />
              </Box> */}

            {isMobile ? (
              <Grid
                item
                xs={12} // Takes full width on extra-small screens
                sm={8} // Takes 2/3 of the width on small screens and larger
                md={9} // Takes 3/4 of the width on medium screens and larger
                lg={10} // Takes 5/6 of the width on large screens and larger
              >
                <Box
                  sx={{
                    position: 'relative',
                    marginTop: '35px',

                    width: isMobile ? '100%' : '97%',
                    minHeight: '450px',
                  }}
                >
                  {<MobileImageSilder />}
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12} // Takes full width on extra-small screens
                sm={8} // Takes 2/3 of the width on small screens and larger
                md={9} // Takes 3/4 of the width on medium screens and larger
                lg={10} // Takes 5/6 of the width on large screens and larger
              >
                <Box
                  sx={{
                    // marginRight: "280px",
                    position: 'relative',
                    marginTop: '35px',

                    marginLeft: isMobile ? '10px' : '140px',

                    width: isMobile ? '100%' : '97%',
                    height: '400px',
                  }}
                >
                  {<ImageSlider />}
                </Box>
              </Grid>
            )}
          </Grid>
          {/* </Grid> */}

          <br />

          <Container maxWidth='xll'>
            <Grid
              ref={targetSectionRef}
              container
              direction='row'
              justifyContent='center'
              alignItems={'space-between'}
              spacing={2}
              minHeight='50vh'
            >
              {isEmpty && (
                <>
                  <Box
                    mt={7}
                    component={Typography}
                    sx={{ textAlign: 'center' }}
                  >
                    <EmptyUI />
                  </Box>
                </>
              )}
              {!isEmpty &&
                isLoaded &&
                products.map((row, index) => {
                  return (
                    <WithOutTokenAgricard
                      key={row._id}
                      data={row}
                      index={index}
                    />
                  );
                })}
              {!isLoaded && (
                <>
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                  <AgriSkelton />
                </>
              )}
            </Grid>
            <Box mt={3} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }} />
              <Pagination
                page={page}
                count={count}
                onChange={handleChange}
                sx={{ color: '#232F3E ' }}
              />
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default WithoutTokenDashboard;
