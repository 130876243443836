import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Avatar,
  Grid,
  Box,
  TextField,
  Container,
  CssBaseline,
} from "@mui/material";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { removeVendorID } from "../../Store/VendorIDSlice";
import { useDispatch } from "react-redux";
const VendorList = () => {
  const dispatch = useDispatch();
  dispatch(removeVendorID());
  const navigate = useNavigate();
  const location = useLocation();
  const vendors = location.state?.vendors || [];
  const [searchTerm, setSearchTerm] = useState("");

  const filteredVendors = vendors.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const goToVendorDashboard = (vendorId) => {
    navigate(`/dashboard`, { state: { vendorId } });
  };

  return (
    <>
      <CssBaseline />
      <Header />
      <Container maxWidth="xl">
        <Box sx={{ my: 3, textAlign: "center", minHeight: "70vh" }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Vendor List
          </Typography>
          <Typography variant='subtitle1' component='h1' gutterBottom>
            Select a vendor to view their products
          </Typography>
          <br />
          <TextField
            variant="outlined"
            placeholder="Search vendors..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: { xs: "100%", md: "50%" }, mb: 4 }}
          />

          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            {filteredVendors.length === 0 ? (
              <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                No vendors found for this category.
              </Typography>
            ) : (
              filteredVendors.map((vendor) => (
                <Grid item key={vendor.id} xs={12} sm={6} md={4} lg={3}>
                  <CardActionArea
                    onClick={() => goToVendorDashboard(vendor.id)}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      <Avatar
                        sx={{
                          mb: 0,
                          width: 100,
                          height: 100,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                        alt={vendor.username}
                        src={vendor.images}
                      />
                      <CardContent sx={{ textAlign: "center" }}>
                        <Typography variant="h6">{vendor.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {vendor.mobile_number}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {vendor.email}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {vendor.address}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Container>

      <Footer />
    </>
  );
};

export default VendorList;
