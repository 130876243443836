import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
  useTheme,
  Table,
  TableHead,
  TableCell,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const OrdersAccordion = ({ orders, onStatusChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getStatusChip = (status) => {
    let color = 'default'; // Default color
    if (status === 'Shipping') color = '#1976D2'; // Blue
    else if (status === 'Pending') color = '#FFA500'; // Orange
    else if (status === 'Delivered') color = '#4CAF50'; // Green
    else if (status === 'Processing') color = '#9C27B0'; // Purple
    return color; // Return the color value
  };

  return (
    <Box sx={{ borderRadius: '30px' }}>
      <Table>
        <TableHead>
          <TableCell>
            <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
              Customer
            </Typography>
          </TableCell>
          {!isMobile && (
            <>
              <TableCell sx={{ textAlign: 'Left' }}>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
                  Create Time
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'Left' }}>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
                  Total
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'Left' }}>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
                  Update Time
                </Typography>
              </TableCell>
            </>
          )}
          <TableCell sx={{ textAlign: 'Left' }}>
            <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
              Status
            </Typography>
          </TableCell>
        </TableHead>
      </Table>
      {orders.map((order, index) => (
        <Accordion key={order._id} sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Grid container alignItems='center' spacing={2}>
              <Grid
                item
                xs={6}
                sm={2.5}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Avatar
                  alt='customer image'
                  src={order.customer.images[0]}
                  sx={{ width: 38, height: 38 }}
                />
                <Typography variant='body2' sx={{ ml: 1 }}>
                  {order.customer.username}
                </Typography>
              </Grid>
              {!isMobile && (
                <>
                  <Grid
                    item
                    sm={3.3}
                    sx={{ display: 'flex', alignItems: 'Left' }}
                  >
                    <Typography variant='body2'>
                      {new Date(
                        parseInt(order.orderDetails.date_time)
                      ).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={1.5}
                    sx={{ display: 'flex', alignItems: 'right' }}
                  >
                    <Typography variant='body2'>
                      ${order.orderDetails.total}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={2.4}
                    sx={{ display: 'flex', alignItems: 'right' }}
                  >
                    <Typography variant='body2'>
                      {new Date(
                        order.orderDetails.update_time
                      ).toLocaleString()}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6} sm={2} sx={{ textAlign: 'right' }}>
                <FormControl fullWidth>
                  <Select
                    value={order.orderDetails.tracking_status}
                    onChange={(e) =>
                      onStatusChange(order.orderDetails._id, e.target.value)
                    }
                    inputProps={{ 'aria-label': 'Without label' }}
                    size='small'
                    sx={{
                      bgcolor: getStatusChip(
                        order.orderDetails.tracking_status
                      ),
                      color: 'white',
                      borderRadius: 1,
                      textAlign: 'center',
                      // height: "100%",
                    }}
                  >
                    <MenuItem value='Pending'>{'Pending'}</MenuItem>
                    <MenuItem value='Processing'>{'Processing'}</MenuItem>
                    <MenuItem value='Shipping'>{'Shipping'}</MenuItem>
                    <MenuItem value='Delivered'>{'Delivered'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={isMobile ? 1 : 2}>
              <Grid item xs={12} md={4}>
                <Typography variant='subtitle2' gutterBottom>
                  Order Details:
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary={`Ordrer Id: ${order.orderDetails._id}`}
                      secondary={`Created Time: ${new Date(
                        parseInt(order.orderDetails.date_time)
                      ).toLocaleString()}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={`Total: ${order.orderDetails.total}`}
                      secondary={`Updated Time: ${new Date(
                        order.orderDetails.update_time
                      ).toLocaleString()}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Shipping Address'
                      secondary={`${order.orderDetails.address.address}, ${order.orderDetails.address.city}`}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant='subtitle2' gutterBottom>
                  Customer Details:
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary='Customer Email'
                      secondary={` ${order.customer.email}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Customer Address'
                      secondary={` ${order.customer.address.address},
                         ${order.customer.address.city},
                        ${order.customer.address.province},
                         ${order.customer.address.postalcode},
                         ${order.customer.address.country}`}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant='subtitle2' gutterBottom>
                  Products:
                </Typography>
                <List dense>
                  {order.products.map((product, prodIndex) => (
                    <ListItem key={prodIndex}>
                      <ListItemAvatar>
                        <Avatar src={product.images} alt={product.title} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={product.title}
                        secondary={`Quantity: ${product.quantity}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default OrdersAccordion;
