import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Paper, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Image imports
import image from '../../Assets/3D Design 010.png';
import image4 from '../../Assets/1st.jpg';
import image2 from '../../Assets/3st.jpg';
import image3 from '../../Assets/4th Slide Design.png';
import image1 from '../../Assets/4st.jpg';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
}));

const SliderWrapper = styled('div')(({ theme }) => ({
  width: '100%',

  borderRadius: theme.shape.borderRadius,

  // boxShadow: theme.shadows[2],
  position: 'relative',
  overflow: 'hidden',
}));

const Arrow = styled(IconButton)(({ theme, left }) => ({
  position: 'absolute',
  top: '50%',
  [left ? 'left' : 'right']: '-1rem', // Adjust this value as needed
  transform: 'translateY(-50%)',
  zIndex: 2,
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down('sm')]: {
    [left ? 'left' : 'right']: '-0.5rem', // Adjust for small screens
  },
}));

const PrevArrow = ({ onClick }) => (
  <Arrow onClick={onClick} left='true'>
    <ArrowBackIosNewIcon />
  </Arrow>
);

const NextArrow = ({ onClick }) => (
  <Arrow onClick={onClick}>
    <ArrowForwardIosIcon />
  </Arrow>
);

export default function ImageSlider() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          paddingBottom: theme.spacing(1),
        }}
      >
        <ul style={{ margin: '15px' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          backgroundColor: 'black',
          color: 'white',
          opacity: 0.75,
          transition: 'opacity .3s',
        }}
      />
    ),
  };

  const images = [image, image1, image2, image3, image4];
  const imageStyle = {
    width: '100%',
    height: isMobile ? '250px' : '380px',
    objectFit: 'cover',
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {images.map((imgSrc, index) => (
          <StyledPaper key={index}>
            <img src={imgSrc} alt={`Slide ${index + 1}`} style={imageStyle} />
          </StyledPaper>
        ))}
      </Slider>
    </SliderWrapper>
  );
}
