import React from "react";
import TextField from "@mui/material/TextField";

const CustomTextField = ({ value, onChange, label, ...props }) => {
  return (
    <TextField
      required
      value={value}
      inputProps={{ sx: { color: "#232F3E" } }}
      onChange={onChange}
      fullWidth
      id={props.id || "custom-text-field"} // Set a default ID or use the provided one
      label={label}
      name={props.name || "custom-text-field"} // Set a default name or use the provided one
      autoComplete={props.autoComplete || "off"} // Set a default value or use the provided one
      {...props}
    />
  );
};

export default CustomTextField;
