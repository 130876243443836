import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Skeleton,
  Typography,
  Grid,
  Chip,
  Select,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
  Input,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Store/auth';
import CustomSnackbar from '../../Adminpages/Components/CustomSnackbar';
import CustomerOrdersAccordion from './CustomerOrdersAccordion';
import { removeVendorID } from '../../../Store/VendorIDSlice';

function CustomerOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { token, userID } = useSelector((state) => state.loging);
  const [orders, setOrders] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [Empty, setEmpty] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  function formattedDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    };
    return date.toLocaleString('en-GB', options);
  }



  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/orders?userID=${userID}&search=${searchQuery}&status=${statusFilter}&page=${currentPage}&pageSize=${pageSize}`,
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      )
      .then((res) => {
        if (res.data) {
   
          setOrders(res.data.orders);
          setLoaded(true);
          setTotalPages(res.data.totalPages);
          setEmpty(res.data.orders.length === 0);
        }
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setSeverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);
            setTimeout(() => {
              dispatch(removeVendorID());
              dispatch(logout());
              window.location.href = 'https://vendormart.lk/';
            }, 3000);
          } else {
            setSeverity('error');
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          setSeverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setSeverity('error');
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      });
  }, [
    userID,
    token,
    searchQuery,
    statusFilter,
    currentPage,
    pageSize,
    dispatch,
    navigate,
  ]);

  const SingleOrder = ({ data }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
      const productsArray = Object.entries(data.products).map(
        ([productid, quantity]) => ({
          productid,
          quantity,
        })
      );

      const fetchData = async () => {
        setLoading(true); // Set loading to true before fetching
        const updatedProducts = [];

        for (const { productid, quantity } of productsArray) {
          try {
            const res = await axios.get(
              `${process.env.REACT_APP_APIURL}api/products/${productid}`,
              {
                headers: { Authorization: 'Agriuservalidation ' + token },
              }
            );
            updatedProducts.push({ ...res.data, quantity });
          } catch (error) {
            if (error.response) {
              const statusCode = error.response.status;
              if (statusCode === 400) {
                setSeverity('error');
                setMessage('Token expired, please login again.');
                setOpenSnackbar(true);
                setTimeout(() => {
                  dispatch(removeVendorID());
                  dispatch(logout());
                  window.location.href = 'https://vendormart.lk/';
                }, 3000);
              } else {
                setSeverity('error');
                setMessage('An error occurred. Please try again later.');
                setOpenSnackbar(true);
              }
            } else if (error.request) {
              setSeverity('error');
              setMessage(
                'No response from server. Please check your network connection.'
              );
              setOpenSnackbar(true);
            } else {
              setSeverity('error');
              setMessage(`Error: ${error.message}`);
              setOpenSnackbar(true);
            }
          }
        }

        setProducts(updatedProducts);
        setLoading(false); // Set loading to false after fetching
      };
      fetchData();
    }, [data.products, token, dispatch, navigate]);

    const getStatusChip = (status) => {
      let color = 'default';
      if (status === 'Shipping') color = 'primary';
      else if (status === 'Pending') color = 'warning';
      else if (status === 'Delivered') color = 'success';
      else if (status === 'Processing') color = 'info';
      return <Chip label={status} color={color} size='small' sx={{ p: 2 }} />;
    };

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{
            color: '#232F3E',
            m: 1,
            p: 1,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems='center'
            sx={{ display: 'columns', justifyContent: 'space-between' }}
          >
            <Grid item xs={12} sm={2}>
              <Typography variant='body2' align={isMobile ? 'center' : 'left'}>
                {data._id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant='body2' align={isMobile ? 'center' : 'left'}>
                {new Date(parseInt(data.date_time)).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant='body2' align={isMobile ? 'center' : 'left'}>
                Rs {data.total}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant='body2' align={isMobile ? 'center' : 'left'}>
                {/* {new Date(parseInt(data.updatedAt)).toLocaleString()} */}
                {formattedDate(data.updatedAt)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{ textAlign: isMobile ? 'center' : 'right' }}
            >
              {getStatusChip(data.tracking_status)}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              width='100%'
            >
              <CircularProgress />
            </Box>
          ) : (
            <List dense>
              {products.map((product) => (
                <CustomerOrdersAccordion product={product} key={product._id} />
              ))}
              {products.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary={`Number of items: ${products.length}`}
                  />
                </ListItem>
              )}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const N = 6;
  const count = Array.from({ length: N }, (_, index) => index + 1);

  return (
    <>
      <Box component={Paper} p={4} elevation={2} square minHeight={'70vh'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant={isMobile ? 'h5' : 'h6'}
            sx={{ color: '#232F3E', marginBottom: isMobile ? 2 : 0 }}
          >
            Your Orders
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: isMobile ? '100%' : 'auto',
            }}
          >
            <Input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              inputProps={{
                'aria-label': 'Without label',
              }}
              placeholder='Search by Product Name or ID'
              sx={{
                m: 1,
                width: isMobile ? '100%' : '30ch',
                border: '#232F3E solid 1px',
                padding: 1,
              }}
              size='small'
              endAdornment={<SearchIcon />}
            />
            <Select
              labelId='demo-simple-select-standard-label'
              id='demo-simple-select-standard'
              name='statusFilter'
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              defaultValue='Search by Status'
              size='small'
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              sx={{ m: 1, width: isMobile ? '100%' : 'auto' }}
            >
              <MenuItem value=''>Search by Tracking Status</MenuItem>
              <MenuItem value='Pending'>Pending</MenuItem>
              <MenuItem value='Processing'>Processing</MenuItem>
              <MenuItem value='Shipping'>Shipping</MenuItem>
              <MenuItem value='Delivered'>Delivered</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box mt={2}>
          {Empty ? (
            <Typography
              sx={{
                textAlign: 'center',
                m: 10,
                color: 'primary',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                letterSpacing: '0.1rem',
              }}
            >
              No Orders Found
            </Typography>
          ) : (
            <Box>
              {!isMobile ? (
                <Grid container spacing={2} sx={{ fontWeight: 'bold' }}>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body2' align='center'>
                      OrderID
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body2' align='center'>
                      Create Time
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body2' align='center'>
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body2' align='center'>
                      Update Time
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body2' align='center'>
                      Status
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {isLoaded
                ? orders.map((row) => <SingleOrder data={row} key={row._id} />)
                : count.map((index) => (
                    <Skeleton
                      key={index}
                      animation='pulse'
                      variant='rectangular'
                      sx={{ borderRadius: 1, mb: 2 }}
                      width={'100%'}
                      height={50}
                    />
                  ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginTop: 2,
        }}
      >
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </Button>
        <Typography>Page {currentPage}</Typography>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Box>
      <CustomSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity={severity}
        message={message}
      />
    </>
  );
}

export default CustomerOrders;
