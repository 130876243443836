import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
  AppBar,
  IconButton,
  TextField,
  InputAdornment,
  Divider,
  Box,
  Stack,
  Grid,
  Avatar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logoImage from "../../Assets/OfficalLog.png";
import { logout } from "../../Store/auth";
import axios from "axios";

function VendorsideNavigationBar({ selectedPage, handlePageChange, token1 }) {
  const { userID } = useSelector((state) => state.loging);
  const [user, setUser] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}users?ID=${userID}`, {
        headers: { Authorization: "Agriuservalidation " + token },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.error("Error fetching user", error);
      });
  });
  let history = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTaplet = useMediaQuery(theme.breakpoints.down("md"));
  const { token } = useSelector((state) => state.loging);
  const [auth, setAuth] = useState(token1 ? true : false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const menuItems = [
    { id: 1, text: "dashboard", icon: <DashboardIcon /> },
    { id: 2, text: "orders", icon: <ListAltIcon /> },
    { id: 3, text: "products", icon: <ShoppingCartIcon /> },
    { id: 4, text: "profile", icon: <PersonIcon /> },
  ];
  // const searchTerm = useSelector((state) => state.search.searchTerm);

  const handleNavigation = (path) => {
    handlePageChange(path);
    if (isMobile || isTaplet) {
      setSidebarOpen(false);
    }
  };

  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          // elevation={3}

          sx={{
            ml: { xs: 1, sm: 1, md: 190 },
            mt: { xs: 1, sm: 1, md: 1 },
            mr: { xs: 1, sm: 1, md: 1 },
            // backgroundColor: "#fff !important",

            background:
              "linear-gradient(502deg, rgb(0, 0, 0) 50%, rgb(35 156 156) 80%, rgb(2 106 106) 100%)",
            // color: "#232F3E",
            color: "#FFFFFF",
            // color: "#232F3E",
            boxShadow: "none",
            borderBottom: "1px solid #E5E5E5",
            // width: "calc(100% - 35px)",
            width: isMobile || isTaplet ? "95%" : "80%",
            borderRadius: isMobile ? "3px 3px 5px 5px" : "10px 10px 35px 35px",
            border: "none",
            boxShadow: "0 1px 3px #232F3E opacity(0.4)",
            transition: "width 0.3s ease-in-out",
          }}
        >
          <Toolbar>
            {(isMobile || isTaplet) && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setSidebarOpen(true)}
                edge="start"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              // value={searchTerm}
              // onChange={handleSearchInputChange}
              sx={{
                backgroundColor: "white",
                borderRadius: " 10px 10px 10px 10px",
                flexGrow: 1,
                mx: 2,
                // ml: isMobile ? 0 : 35,
                mr: { xs: 0, sm: 0, md: 50 },

                "& .MuiOutlinedInput-root": {
                  borderRadius: " 10px 10px 10px 10px",
                  // border: "3px solid #FF9933",
                },

                "& .MuiOutlinedInput-input": {
                  color: "#232F3E",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton color="inherit">
              <PersonIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                setAuth(false);
                dispatch(logout());
                history("/login", { replace: true });
              }}
            >
              <LogoutIcon />
            </IconButton>
            <IconButton color="inherit">
              <SettingsIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* <Drawer
          onChange={(event, newValue) => handleNavigation(newValue)}
          variant={isMobile || isTaplet ? "temporary" : "permanent"}
          anchor="top-left"
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundImage: "linear-gradient(to right, black, red)", // Gradient color
              width: isMobile ? "100%" : "240px",
              boxSizing: "border-box",
              // m: isMobile ? 0 : 2,

              // ml: isMobile ? 0 : 0.5,
              px: 2,
              py: 2,
              // borderRadius: "10px 10px 10px 10px",
              border: "none",
              boxShadow: "0 1px 3px rgba(0,0,0,0.4)",
              // backgroundColor: "#232F3E",
              // backgroundColor: "#232F3E",
            },
          }}
        >
          <Toolbar>
            <img
              src={logoImage}
              alt="Vendor Logo"
              style={{ height: "50px", margin: "auto" }}
            />
          </Toolbar>
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleNavigation(item.text)}
                selected={selectedPage === item.text}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor:
                    selectedPage === item.text ? "#FF9933" : "transparent",
                  "&:hover": {
                    backgroundColor: "#3f546f",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#FF9933",
                    color: "#FFFFFF",
                    borderRadius: "10px",
                  },
                }}
              >
                <ListItemIcon sx={{ color: "#FFFFFF" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer> */}
        <Drawer
          variant={isMobile || isTaplet ? "temporary" : "permanent"}
          anchor="left"
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          sx={{
            "& .MuiDrawer-paper": {
              background: "rgb(1,12,66)",
              background:
                "linear-gradient(502deg, rgb(0, 0, 0) 50%, rgb(35 156 156) 80%, rgb(2 106 106) 100%)",
              width: isMobile ? "100%" : "240px",
              boxSizing: "border-box",
              px: 2,
              py: 2,
              border: "none",
              boxShadow: "0 1px 3px rgba(0,0,0,0.4)",
              color: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRadius: isMobile ? "3px" : "0 60px 60px 0",
            },
          }}
        >
          <div>
            <Toolbar>
              <img
                src={logoImage}
                alt="Vendor Logo"
                style={{
                  height: "50px",
                  margin: "auto",
                  marginBottom: "40px",
                  marginTop: "20px",
                }}
              />
            </Toolbar>
            <List>
              {menuItems.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleNavigation(item.text)}
                  selected={selectedPage === item.text}
                  sx={{
                    color: "#FFFFFF",
                    backgroundColor:
                      selectedPage === item.text ? "#FF9933" : "transparent",
                    "&:hover": {
                      backgroundColor: "#3f546f",
                      backdropFilter: "blur(3px)",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                    },
                    "&.Mui-selected": {
                      backdropFilter: "blur(7px)",
                      backgroundColor: "#FF9933",
                      color: "#FFFFFF",
                      borderRadius: "10px",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "#FFFFFF" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          </div>
          <Box sx={{ py: 2, textAlign: "center" }}>
            <Divider sx={{ backgroundColor: "rgba(255,255,255,0.5)" }} />
            <Avatar
              src={user.images}
              alt={user.username}
              sx={{ width: 80, height: 80, mx: "auto", mt: 2 }}
            />
            <Typography variant="h6" mt={2}>
              {/* {truncateString(user.username, 15)} */}
              {user.username}
            </Typography>
          </Box>
        </Drawer>

        <Toolbar />
      </Box>
    </>
  );
}

export default VendorsideNavigationBar;
