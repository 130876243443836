// import React from "react";
// import { Box, Typography, Button, Container } from "@mui/material";
// import shoppingCartIcon from "../../Assets/empty-box.png";
// import "./EmptyUI.css";

// function EmptyUI() {
//   return (
//     <Container className="empty-ui-container" maxWidth="xl">
//       <Box textAlign="center">
//         <Box className="empty-ui-animation">
//           <img src={shoppingCartIcon} alt="Shopping Cart"  />
//         </Box>
//         {/* <Typography variant="h4" className="empty-ui-title">
//           Your cart is empty
//         </Typography> */}
//         <Typography variant="h6" className="empty-ui-description">
//           items empty...
//         </Typography>
//         {/* <Button variant="contained" color="primary" className="empty-ui-button">
//           Shop Now
//         </Button> */}
//       </Box>
//     </Container>
//   );
// }

// export default EmptyUI;

// import React from 'react';
// import { Box, Typography, Button, Container } from '@mui/material';
// import shoppingCartIcon from '../../Assets/empty-box.png';
// import './EmptyUI.css';

// function EmptyUI() {
//   return (
//     <Container className='empty-ui-container' maxWidth='xl'>
//       <Box textAlign='center'>
//         <Box className='empty-ui-animation'>
//           <img src={shoppingCartIcon} alt='Shopping Cart' />
//         </Box>

//         <Typography variant='h6' className='empty-ui-description'>
//           No items found...
//         </Typography>
//         <Button
//           variant='contained'
//           color='primary'
//           className='empty-ui-button'
//           onClick={() => window.location.reload()}
//         >
//           Shop Now
//         </Button>
//       </Box>
//     </Container>
//   );
// }

// export default EmptyUI;

import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import shoppingCartIcon from '../../Assets/empty-box.png';
import './EmptyUI.css';

function EmptyUI() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container className='empty-ui-container' maxWidth='xl'>
      <Box
        textAlign='center'
        display={'flex'}
        flexDirection='column'
        alignItems={'center'}
      >
        <Box className='empty-ui-animation'>
          <motion.img
            src={shoppingCartIcon}
            alt='Shopping Cart'
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
          />
        </Box>

        <Typography
          variant={isMobile ? 'body1' : 'h6'}
          className='empty-ui-description'
        >
          No items found...
        </Typography>
      </Box>
    </Container>
  );
}

export default EmptyUI;
