import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import FlashSale from "./FlashSale";
import image from "../../Assets/Rancrsip-Cassava-Chips-Hot-Spicy-100g-300x300.jpg";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
// Define your products array here
const products = [
  // Add your product details here
  {
    id: 1,
    name: "FEBRINA Black Pink Pen Gel Pen.",
    price: "Rs.175",
    originalPrice: "250",
    discount: "-30%",
    rating: 4,
    imageUrl: image,
  },
  {
    id: 2,
    name: "Radium Stars Radium Stickers Night Glow",
    price: "Rs.199",
    originalPrice: "600",
    discount: "-67%",
    rating: 5,
    imageUrl: image,
  },
  {
    id: 1,
    name: "FEBRINA Black Pink Pen Gel Pen.",
    price: "Rs.175",
    originalPrice: "250",
    discount: "-30%",
    rating: 4,
    imageUrl: image,
  },
  {
    id: 2,
    name: "Radium Stars Radium Stickers Night Glow",
    price: "Rs.199",
    originalPrice: "600",
    discount: "-67%",
    rating: 5,
    imageUrl: image,
  },
  {
    id: 1,
    name: "FEBRINA Black Pink Pen Gel Pen.",
    price: "Rs.175",
    originalPrice: "250",
    discount: "-30%",
    rating: 4,
    imageUrl: image,
  },
  {
    id: 2,
    name: "Radium Stars Radium Stickers Night Glow",
    price: "Rs.199",
    originalPrice: "600",
    discount: "-67%",
    rating: 5,
    imageUrl: image,
  },
  {
    id: 1,
    name: "FEBRINA Black Pink Pen Gel Pen.",
    price: "Rs.175",
    originalPrice: "250",
    discount: "-30%",
    rating: 4,
    imageUrl: image,
  },
  {
    id: 2,
    name: "Radium Stars Radium Stickers Night Glow",
    price: "Rs.199",
    originalPrice: "600",
    discount: "-67%",
    rating: 5,
    imageUrl: image,
  },
  {
    id: 1,
    name: "FEBRINA Black Pink Pen Gel Pen.",
    price: "Rs.175",
    originalPrice: "250",
    discount: "-30%",
    rating: 4,
    imageUrl: image,
  },
  {
    id: 2,
    name: "Radium Stars Radium Stickers Night Glow",
    price: "Rs.199",
    originalPrice: "600",
    discount: "-67%",
    rating: 5,
    imageUrl: image,
  },
  {
    id: 1,
    name: "FEBRINA Black Pink Pen Gel Pen.",
    price: "Rs.175",
    originalPrice: "250",
    discount: "-30%",
    rating: 4,
    imageUrl: image,
  },
  {
    id: 2,
    name: "Radium Stars Radium Stickers Night Glow",
    price: "Rs.199",
    originalPrice: "600",
    discount: "-67%",
    rating: 5,
    imageUrl: image,
  },
  // Add more products as needed
];

export default function Recommendations({ productID }) {
  const handleMoreDetailsClick = (id) => {
    navigate(`/product/view/${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showAll, setShowAll] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const { token } = useSelector((state) => state.loging);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APIURL}api/recommendations/${productID}`,
          {
            headers: { Authorization: "Agriuservalidation " + token },
          }
        ); // Adjust the endpoint as needed
        setRecommendedProducts(response.data.recommendations);
        console.log(response.data.recommendations);
      } catch (err) {
        // setError(err.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchRecommendations();
  }, [productID]);

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  // Adjust the slice parameters based on your specific layout and requirements
  const displayedProducts = showAll
    ? recommendedProducts
    : recommendedProducts.slice(0, 8); // Show first 6 products initially
  const navigate = useNavigate();
  return (
    <>
      {/* <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 0, m: 3 }}>
        <FlashSale />
      </Grid> */}
      <Box p={0} mb={8}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: 24,
            fontWeight: "xxl",
            mb: 0,
            pl: 5,
            mt: 5,
            textAlign: "center",
          }}
        >
          Just For Your Recommendations
        </Typography>
        <Box textAlign="right" sx={{ p: 0, mr: 5 }}>
          <Button onClick={handleToggleShowAll} endIcon={<ExpandMoreIcon />}>
            {showAll ? "Show Less" : "Show More"}
          </Button>
        </Box>
        <Grid
          container
          spacing={1}
          sx={{ pl: 2, pr: 2 }}
          justifyContent="center"
        >
          {displayedProducts.map((product) => (
            <Grid item xs={6} sm={6} md={6} lg={2.5} key={product.id}>
              <Card
                sx={{
                  maxWidth: 345,
                  ":hover": {
                    boxShadow: 8,
                    transform: "scale(1.05)",
                    transition: "transform 0.2s ease-in-out",
                  },
                }}
              >
                {/* <CardMedia
                  component="img"
                  height="100px"
                  width="100px"
                  image={product.images[0]}
                  alt={product.title}
                  sx={{
                    objectFit: "contain",
                  }}
                /> */}
                <CardMedia
                  component="img"
                  style={{
                    maxHeight: isMobile ? "120px" : "200px",

                    objectFit: "contain",
                    objectPosition: "center",
                    paddingTop: "10px",
                    cursor: "pointer",
                  }}
                  image={product.images[0]}
                  alt="Product Image"
                  // onClick={() => {
                  //   navigate(`/product/view/${product._id}`);
                  // }}
                />
                <CardContent>
                  {/* <Typography gutterBottom variant="h6" component="div">
                  {product.name}
                </Typography> */}
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{
                      fontSize: "14px",
                      height: "3em", // Fixed height, for example, 3 lines high
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Add ellipsis to text that overflows
                      display: "-webkit-box", // Necessary for line clamping
                      WebkitLineClamp: 3, // Limit text to 3 lines
                      WebkitBoxOrient: "vertical", // Set box orientation
                      boxSizing: "border-box", // Include padding and borders in the height
                    }}
                  >
                    {product.title}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    {" "}
                    {"Rs" + product.price}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    component={Typography}
                    variant="contained"
                    size="small"
                    onClick={() => handleMoreDetailsClick(product._id)}
                    sx={{
                      m: 1,
                      background: theme.palette.gradian.main,
                      color: theme.palette.customColors.white,

                      width: "100%",
                      textTransform: "none",
                      "&:hover": {
                        background: theme.palette.gradian.hover,
                        color: theme.palette.customColors.white,
                      },
                    }}
                  >
                    More details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
