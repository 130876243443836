import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  useTheme,
  TableContainer,
} from "@mui/material";

const ProductsTable = ({ recentProducts, isLoadingProducts }) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        mt: 3,
        p: 2,
        borderRadius: "10px",
        boxShadow: 3,
        overflowX: "auto",
        boxShadow:
          "3px 2px 0px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgb(227 5 5 / 14%), 0px 1px 3px 0px rgb(50 190 205)",
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        Recent Products
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Product ID
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Product Name
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Price
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Created Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingProducts ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Skeleton height={30} />
                </TableCell>
              </TableRow>
            ) : recentProducts.length > 0 ? (
              recentProducts.map((product, index) => (
                <TableRow
                  key={product?._id}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? theme.palette.action.hover : "inherit",
                    "&:hover": {
                      backgroundColor: theme.palette.action.selected,
                    },
                  }}
                >
                  <TableCell align="center">{product?._id}</TableCell>
                  <TableCell align="center">{product?.title}</TableCell>
                  <TableCell align="center">
                    Rs {product?.price.toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(product?.createdAt).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No recent products found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProductsTable;
