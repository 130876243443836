// src/Adminpages/CommunicationManagement.js
import React, { useState, useEffect } from "react";
import { Paper, Typography, Button, TextField } from "@mui/material";

const CommunicationManagement = () => {
  const [communicationLogs, setCommunicationLogs] = useState([]);
  const [notificationText, setNotificationText] = useState("");

  useEffect(() => {
    // Simulate fetching communication logs from your backend
    // Replace this with the actual logic to fetch data from your backend
    const fetchCommunicationLogs = async () => {
      try {
        // Simulate API response delay
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Sample Communication Logs Data
        const sampleData = [
          {
            message: "Notification 1: Important update for vendors.",
            timestamp: "2023-01-01T12:00:00Z",
          },
          {
            message: "Notification 2: New feature announcement for customers.",
            timestamp: "2023-01-02T14:30:00Z",
          },
          // Add more log entries as needed
        ];

        setCommunicationLogs(sampleData);
      } catch (error) {
        console.error("Error fetching communication logs", error);
      }
    };

    fetchCommunicationLogs();
  }, []);

  const handleSendNotification = () => {
    // Send notification logic goes here
    // You might want to call an API endpoint to send notifications
    // Replace the URL with your actual API endpoint
    fetch("your_backend_send_notification_url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text: notificationText }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle success (if needed)
        console.log("Notification sent successfully", data);
      })
      .catch((error) => {
        console.error("Error sending notification", error);
      });
  };

  return (
    <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
      <Typography variant="h5">Communication Management</Typography>
      <Typography>Communication Logs:</Typography>
      <ul>
        {communicationLogs.map((log, index) => (
          <li key={index}>
            {log.message} (Timestamp: {log.timestamp})
          </li>
        ))}
      </ul>
      <Typography>Send Notification:</Typography>
      <TextField
        label="Notification Text"
        variant="outlined"
        fullWidth
        value={notificationText}
        onChange={(e) => setNotificationText(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSendNotification}
        sx={{ mt: 2, mb: 2 }}
      >
        Send Notification
      </Button>
    </Paper>
  );
};

export default CommunicationManagement;
