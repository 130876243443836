import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the initial state
const initialState = {
  cartItems: [],
  itemCount: 0,
  loading: false,
  error: null,
};

export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async (args, { rejectWithValue }) => {
    try {
      const userID = localStorage.getItem("userID");
      const token = localStorage.getItem("token");


      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}users/carts?_id=${userID}`,
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching cart items:", error);
      if (error.response) {
        console.error("Error details:", error.response.data);
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        console.error("No response was received.");
        return rejectWithValue("No response was received");
      } else {
        console.error("Error setting up the request.");
        return rejectWithValue("Error setting up the request");
      }
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      state.cartItems.push(action.payload);
      state.itemCount = state.cartItems.length;
    },
    removeItemFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item !== action.payload
      );
      state.itemCount = state.cartItems.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.cartItems = action.payload;
        state.itemCount = action.payload.length;
        state.loading = false;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.error = action.payload || "Failed to fetch cart items";
        state.loading = false;
      });
  },
});

export const { addItemToCart, removeItemFromCart } = cartSlice.actions;
export default cartSlice.reducer;
