import { createSlice } from "@reduxjs/toolkit";

const initialVendorState = {
  vendorID: localStorage.getItem("vendorID"),
};

const VendorIDSlice = createSlice({
  name: "vendorID",
  initialState: initialVendorState,
  reducers: {
    addVendorID(state, action) {
      state.vendorID = action.payload.vendorID;
      localStorage.setItem("vendorID", state.vendorID);
    },
    removeVendorID(state) {
      localStorage.removeItem("vendorID");
      state.vendorID = null; // Reset vendorID to null upon removal
    },
  },
});

export const { addVendorID, removeVendorID } = VendorIDSlice.actions;
export default VendorIDSlice.reducer;
