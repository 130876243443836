import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  ListItem,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
const SearchResultItem = ({ result, scrollToSection }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/product/view/${result._id}`); // Assuming result.id corresponds to the product ID
  };
  return (
    <>
      {' '}
      <ListItem
        key={result.id}
        alignItems='flex-start'
        sx={{
          cursor: 'pointer',
          backgroundColor: '#f5f5f5',
          borderRadius: '5px',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
        }}
        onClick={handleClick}
      >
        <img
          src={result.images[0]}
          alt={result.title}
          style={{
            width: '50px',
            height: '50px',
            marginRight: '10px',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>{result.title}</span>
          <span style={{ color: result.stock ? 'green' : 'red' }}>
            {result.stock ? 'In stock' : 'Out of stock'}
          </span>
          <span>{`Starting Price $${result.price}`}</span>
        </div>
      </ListItem>
      <Divider variant='middle' sx={{ borderColor: '#232F3E', my: 0.2 }} />
    </>
  );
};

const SearchResultList = ({ searchResult, scrollToSection }) => (
  <Box
    sx={{
      border: '1px solid black',
      bgcolor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Container maxWidth='md'>
      <Box component='form' noValidate>
        <Grid
          container
          justifyContent='space-evenly'
          // alignItems="center"
          spacing={2}
          sx={{
            minHeight: '20vh',
            bgcolor: 'white',
            border: '1px solid black',
            p: 1,
            m: 2,
            borderRadius: 3,
            position: 'absolute',
            left: '10',
            right: '0',
            top: '-10',

            zIndex: '1000',
            width: '80%',
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          {searchResult.length > 0 ? (
            searchResult.map((result) => (
              <SearchResultItem
                key={result._id}
                result={result}
                scrollToSection={scrollToSection}
              />
            ))
          ) : (
            <Typography
              variant='subtitle1'
              style={{ marginTop: '16px', color: 'red', fontWeight: 'bold' }}
            >
              No more results.
            </Typography>
          )}
        </Grid>
      </Box>
    </Container>
  </Box>
);

export default SearchResultList;
