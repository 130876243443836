import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  useTheme,
  TableContainer,
} from "@mui/material";

const UserTable = ({ recentUsers, isLoadingUsers }) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        mt: 3,
        p: 2,
        borderRadius: "10px",
        boxShadow: 3,
        overflowX: "auto",
        boxShadow:
          "3px 2px 0px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgb(227 5 5 / 14%), 0px 1px 3px 0px rgb(50 190 205)",
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        Recent Users
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                User ID
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Name
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Email
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Mobile Number
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingUsers ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Skeleton height={30} />
                </TableCell>
              </TableRow>
            ) : recentUsers.length > 0 ? (
              recentUsers.map((user, index) => (
                <TableRow
                  key={user?._id}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? theme.palette.action.hover : "inherit",
                    "&:hover": {
                      backgroundColor: theme.palette.action.selected,
                    },
                  }}
                >
                  <TableCell align="center">{user?._id}</TableCell>
                  <TableCell align="center">{user?.username}</TableCell>
                  <TableCell align="center">{user?.email}</TableCell>
                  <TableCell align="center">{user?.mobile_number}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No recent users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default UserTable;
