import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({ title, ...props }) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{
        my: 1,
        // background: "#FF9933 ",
        background:
          "linear-gradient(502deg, rgb(0, 0, 0) -189%, rgb(35 156 156) 80%, rgb(2 106 106) 100%)",
        mt: 2,
        color: "white",
        letterSpacing: 2.5,
        fontWeight: "1000",
        fontSize: 15,
        fontFamily: "open sans",
        textTransform: "capitalize",
        "&:hover": {
          background:
            "linear-gradient(273deg, rgb(0, 0, 0) -189%, rgb(35 156 156) 80%, rgb(2 106 106) 100%) ",
          color: "#FFF",
        },
      }}
      {...props}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
