// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   favorites: [],
//   count: 0,
// };

// const favoritesSlice = createSlice({
//   name: "favorites",
//   initialState,
//   reducers: {
//     addFavorite: (state, action) => {
//       state.favorites.push(action.payload);
//       state.count = state.favorites.length;
//     },
//     removeFavorite: (state, action) => {
//       state.favorites = state.favorites.filter(
//         (item) => item !== action.payload
//       );
//       state.count = state.favorites.length;
//     },
//     setFavorites: (state, action) => {
//       state.favorites = action.payload;
//       state.count = action.payload.length;
//     },
//   },
// });

// export const { addFavorite, removeFavorite, setFavorites } =
//   favoritesSlice.actions;
// export default favoritesSlice.reducer;



import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the initial state
const initialState = {
  favorites: [],
  count: 0,
  loading: false,
  error: null,
};

export const fetchFavorites = createAsyncThunk(
  "favorites/fetchFavorites",
  async (args, { rejectWithValue }) => {
    // Ensure the function parameters are correctly defined
    try {
      const userID = localStorage.getItem("userID");
      const token = localStorage.getItem("token"); // Ensure you have token available in localStorage or context

      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}users/favorites?_id=${userID}`,
        {
          headers: { Authorization: "Agriuservalidation " + token },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching favorites:", error); // Log more detailed error information
      if (error.response) {
        console.error("Error details:", error.response.data); // Log error response data
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        console.error("No response was received.");
        return rejectWithValue("No response was received");
      } else {
        console.error("Error setting up the request.");
        return rejectWithValue("Error setting up the request");
      }
    }
  }
);

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    addFavorite: (state, action) => {
      state.favorites.push(action.payload);
      state.count = state.favorites.length;
    },
    removeFavorite: (state, action) => {
      state.favorites = state.favorites.filter(
        (item) => item !== action.payload
      );
      state.count = state.favorites.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.favorites = action.payload;
        state.count = action.payload.length;
        state.loading = false;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.error = action.payload || "Failed to fetch favorites";
        state.loading = false;
      });
  },
});

export const { addFavorite, removeFavorite } = favoritesSlice.actions;
export default favoritesSlice.reducer;
