// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   CircularProgress,
//   List,
//   ListItem,
//   ListItemText,
//   Grid,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Header from '../../Components/Header';
// import Footer from '../../Components/Footer';
// import { useSelector } from 'react-redux';
// import SimpleAgriCardforMobileview from '../Utils/SimpleAgriCardforMobileview';
// import { ContactMail } from '@mui/icons-material';

// function MobileviewCategory() {
//   const { token } = useSelector((state) => state.loging);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [expandedSubcategory, setExpandedSubcategory] = useState(null);
//   const [loadingCategories, setLoadingCategories] = useState(false);
//   const [loadingProducts, setLoadingProducts] = useState(false);
//   const [products, setProducts] = useState([]);
//   const vendorID = useSelector((state) => state.vendorID.vendorID);

//   useEffect(() => {
//     setLoadingCategories(true);
//     axios
//       .get(`${process.env.REACT_APP_APIURL}api/vendorone/categorys/${vendorID}`)
//       .then((response) => {
//         console.log(response.data);
//         setCategories(response.data);
//         if (response.data.length > 0) {
//           setSelectedCategory(response.data[0]);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching categories:', error);
//       })
//       .finally(() => {
//         setLoadingCategories(false);
//       });
//   }, [vendorID]);

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//     setExpandedSubcategory(null);
//     setProducts([]);
//   };

//   const handleSubcategoryClick = (subcategory) => {
//     const isExpanding = expandedSubcategory !== subcategory._id;
//     setExpandedSubcategory(isExpanding ? subcategory._id : null);
//     if (isExpanding) {
//       fetchProducts(subcategory);
//     }
//   };

//   const fetchProducts = (subcategory) => {
//     setLoadingProducts(true);
//     axios
//       .get(
//         `${process.env.REACT_APP_APIURL}api/subcategorytoFindProduct/${subcategory._id}?categoryId=${selectedCategory._id}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       )
//       .then((response) => {
//         setProducts(response.data.products);
//       })
//       .catch((error) => {
//         console.error('An error occurred while fetching products:', error);
//       })
//       .finally(() => {
//         setLoadingProducts(false);
//       });
//   };

//   return (
//     <>
//       <Header page='category' token={token} />
//       <div className='flex bg-white'>
//         {/* Category List */}
//         <div className='w-1/4 overflow-y-hidden overflow-x-hidden sticky top-0'>
//           {loadingCategories ? (
//             <div className='flex justify-center items-center h-full'>
//               <CircularProgress />
//             </div>
//           ) : (
//             <List>
//               {categories.map((category) => (
//                 <>
//                   <Grid
//                     key={category._id}
//                     sx={{
//                       cursor: 'pointer',
//                       borderBottom: '1px solid #ccc',
//                       bgColor:
//                         selectedCategory._id === category._id
//                           ? '#FF9933'
//                           : 'inherit',
//                     }}
//                   >
//                     <ListItem
//                       key={category._id}
//                       onClick={() => handleCategoryClick(category)}
//                     >
//                       <ListItemText
//                         primary={
//                           <img
//                             className='w-8 h-8 object-contain'
//                             src={category.categoryimages}
//                             alt={category.categoryname}
//                             loading='lazy'
//                           />
//                         }
//                         secondary={category.categoryname}
//                       />
//                     </ListItem>
//                   </Grid>
//                 </>
//               ))}
//             </List>
//           )}
//         </div>

//         {/* Subcategory Accordion */}
//         <div className='w-3/4 overflow-y-auto bg-white'>
//           {selectedCategory ? (
//             <div className='p-4'>
//               {selectedCategory.subcategories.length > 0 ? (
//                 selectedCategory.subcategories.map((subcategory) => (
//                   <Accordion
//                     key={subcategory._id}
//                     expanded={expandedSubcategory === subcategory._id}
//                     onChange={() => handleSubcategoryClick(subcategory)}
//                     className='my-2'
//                   >
//                     <AccordionSummary
//                       expandIcon={
//                         <ExpandMoreIcon
//                           sx={{
//                             color:
//                               expandedSubcategory === subcategory._id
//                                 ? '#FF9933'
//                                 : 'inherit',
//                           }}
//                         />
//                       }
//                     >
//                       <Typography
//                         className='text-lg font-semibold'
//                         sx={{
//                           color:
//                             expandedSubcategory === subcategory._id
//                               ? '#FF9933'
//                               : 'inherit',
//                         }}
//                       >
//                         {subcategory.subcategoryname}
//                       </Typography>
//                     </AccordionSummary>
//                     <AccordionDetails className='flex flex-wrap'>
//                       {loadingProducts ? (
//                         <div className='flex justify-center items-center w-full'>
//                           <CircularProgress />
//                         </div>
//                       ) : (
//                         <>
//                           {products.length > 0 ? (
//                             products.map((product) => (
//                               <SimpleAgriCardforMobileview
//                                 key={product._id}
//                                 product={product}
//                               />
//                             ))
//                           ) : (
//                             <Typography className='p-10 w-full text-center'>
//                               No products found.
//                             </Typography>
//                           )}
//                         </>
//                       )}
//                     </AccordionDetails>
//                   </Accordion>
//                 ))
//               ) : (
//                 <Typography className='w-full text-center py-20'>
//                   No subcategories found.
//                 </Typography>
//               )}
//             </div>
//           ) : (
//             <Typography className='w-full text-center py-20'>
//               Select a category to view subcategories.
//             </Typography>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default MobileviewCategory;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useSelector } from "react-redux";
import SimpleAgriCardforMobileview from "../Utils/SimpleAgriCardforMobileview";
import { ContactMail } from "@mui/icons-material";

function MobileviewCategory() {
  const { token } = useSelector((state) => state.loging);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const vendorID = useSelector((state) => state.vendorID.vendorID);

  useEffect(() => {
    setLoadingCategories(true);
    axios
      .get(`${process.env.REACT_APP_APIURL}api/vendorone/categorys/${vendorID}`)
      .then((response) => {
        console.log(response.data);
        setCategories(response.data);
        if (response.data.length > 0) {
          setSelectedCategory(response.data[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      })
      .finally(() => {
        setLoadingCategories(false);
      });
  }, [vendorID]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setExpandedSubcategory(null);
    setProducts([]);
  };

  const handleSubcategoryClick = (subcategory) => {
    const isExpanding = expandedSubcategory !== subcategory._id;
    setExpandedSubcategory(isExpanding ? subcategory._id : null);
    if (isExpanding) {
      fetchProducts(subcategory);
    }
  };

  const fetchProducts = (subcategory) => {
    setLoadingProducts(true);
    axios
      .get(
        `${process.env.REACT_APP_APIURL}api/subcategorytoFindProduct/${subcategory._id}?categoryId=${selectedCategory._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setProducts(response.data.products);
      })
      .catch((error) => {
        console.error("An error occurred while fetching products:", error);
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  };

  return (
    <>
      <Header page="category" token={token} />
      <div className="flex bg-gray-100" style={{ minHeight: "80vh" }}>
        {/* Category List */}
        <div className="w-1/4 overflow-y-hidden overflow-x-hidden sticky top-0">
          {loadingCategories ? (
            <div className="flex justify-center items-center h-full">
              <CircularProgress />
            </div>
          ) : (
            <List>
              {categories.map((category) => (
                <>
                  <Grid
                    key={category._id}
                    sx={{
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",

                      background:
                        selectedCategory._id === category._id
                          ? "white"
                          : "inherit",
                    }}
                  >
                    <ListItem
                      key={category._id}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <ListItemText
                        primary={
                          <div className="flex flex-col justify-center content-center text-center items-center">
                            <img
                              className="w-10 h-10 object-contain rounded-full bg-center"
                              src={category.categoryimages}
                              alt={category.categoryname}
                              loading="lazy"
                            />

                            <Typography
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "11px",
                                textAlign: "center",

                                color:
                                  selectedCategory &&
                                  selectedCategory._id === category._id
                                    ? "#FF9933"
                                    : "inherit",
                              }}
                            >
                              {category.categoryname}
                            </Typography>
                          </div>
                        }
                      />
                    </ListItem>
                  </Grid>
                </>
              ))}
            </List>
          )}
        </div>

        {/* Subcategory Accordion */}
        <div className="w-3/4 overflow-y-auto bg-white">
          {selectedCategory ? (
            <div className="p-4">
              {selectedCategory.subcategories.length > 0 ? (
                selectedCategory.subcategories.map((subcategory) => (
                  <Accordion
                    key={subcategory._id}
                    expanded={expandedSubcategory === subcategory._id}
                    onChange={() => handleSubcategoryClick(subcategory)}
                    className="my-2"
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color:
                              expandedSubcategory === subcategory._id
                                ? "#FF9933"
                                : "inherit",
                          }}
                        />
                      }
                    >
                      <Typography
                        className="text-lg font-semibold"
                        sx={{
                          color:
                            expandedSubcategory === subcategory._id
                              ? "#FF9933"
                              : "inherit",
                        }}
                      >
                        {subcategory.subcategoryname}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="flex flex-wrap">
                      {loadingProducts ? (
                        <div className="flex justify-center items-center w-full">
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          {products.length > 0 ? (
                            products.map((product) => (
                              <SimpleAgriCardforMobileview
                                key={product._id}
                                product={product}
                              />
                            ))
                          ) : (
                            <Typography className="p-10 w-full text-center">
                              No products found.
                            </Typography>
                          )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography className="w-full text-center py-20">
                  No subcategories found.
                </Typography>
              )}
            </div>
          ) : (
            <Typography className="w-full text-center py-20">
              Select a category to view subcategories.
            </Typography>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MobileviewCategory;
