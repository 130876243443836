import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  Avatar,
  Skeleton,
  useMediaQuery,
  useTheme,
  Container,
} from '@mui/material';
import {
  ThumbUp as ThumbUpIcon,
  QueryBuilder as QueryBuilderIcon,
  Transform as TransformIcon,
  LocalShipping as LocalShippingIcon,
  DeliveryDiningRounded,
  AccountCircle as AccountCircleIcon,
  Storefront as StorefrontIcon,
  MonetizationOn as MonetizationOnIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Autorenew as AutorenewIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import OrderTable from '../VendorSideNavigationBar/VendorDashboradPages/Components/OrderTable';
import ProductsTable from '../VendorSideNavigationBar/VendorDashboradPages/Components/ProductsTable';
import UserTable from '../VendorSideNavigationBar/VendorDashboradPages/Components/UserTable';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import useErrorHandler from '../Utils/useErrorHandler';
import CustomSnackbar from './Components/CustomSnackbar';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MetricCard = ({
  title,
  value,
  icon,
  subtitle,
  increase,
  backgroundColor,
  textColor,
  isLoading,
  isMobile,
}) => (
  <Grid item xs={12} sm={6} md={4} lg={2.4}>
    <Card
      sx={{
        p: 2,
        textAlign: 'center',
        borderRadius: 2,
        backgroundColor: backgroundColor,
        width: isMobile ? '100%' : 'auto',
      }}
    >
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Avatar
          sizes='large'
          variant='rounded'
          sx={{
            mb: 1,
            color: backgroundColor,
            bgcolor: 'white',
            borderRadius: 5,
          }}
        >
          {icon}
        </Avatar>
        {isLoading ? (
          <>
            <Skeleton variant='text' width={60} />
            <Skeleton variant='text' width={100} />
          </>
        ) : (
          <>
            <Typography
              variant='h5'
              component='div'
              fontWeight='bold'
              color={'white'}
            >
              {value}
            </Typography>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              gutterBottom
              sx={{ color: 'white' }}
            >
              <Typography
                variant='body2'
                sx={{
                  fontWeight: 100,
                  position: 'relative',
                  color: 'white',
                }}
              >
                {increase}
              </Typography>
              {subtitle}
            </Typography>
          </>
        )}
      </Box>
    </Card>
  </Grid>
);

const GraphCard = ({ title, children, isLoading, isMobile }) => (
  <Grid item xs={12} md={6} lg={isMobile ? 12 : 4}>
    <Card
      sx={{
        p: 2,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '100%' : 'auto',
      }}
    >
      <Typography variant='h6' fontWeight='bold' gutterBottom>
        {title}
      </Typography>
      {isLoading ? <Skeleton variant='rectangular' height={200} /> : children}
    </Card>
  </Grid>
);

function AdminDashboard() {
  const { severity, message, openSnackbar, handleError, handleCloseSnackbar } =
    useErrorHandler();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { token, userID } = useSelector((state) => state.loging);
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentUsers, setRecentUsers] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);
  const [adminMetrics, setAdminMetrics] = useState({
    registeredCustomers: 0,
    approvedVendors: 0,
    totalSales: 0,
    pendingOrders: 0,
    processingOrders: 0,
    shippingOrders: 0,
    deliveredOrders: 0,
  });
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingGraphs, setIsLoadingGraphs] = useState(true);

  const fetchRecentOrders = async () => {
    setIsLoadingOrders(true);
    try {
      const axiosConfig = {
        headers: { Authorization: 'Agriuservalidation ' + token },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/Admin/getRecentOrders`,
        axiosConfig
      );
      setRecentOrders(response.data);
    } catch (error) {
      handleError(error);
      console.error('Error fetching recent orders:', error);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const fetchRecentUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const axiosConfig = {
        headers: { Authorization: 'Agriuservalidation ' + token },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/Admin/getRecentClients`,
        axiosConfig
      );
      setRecentUsers(response.data);
      console.log(response.data);
    } catch (error) {
      handleError(error);
      console.error('Error fetching recent users:', error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const fetchRecentProducts = async () => {
    setIsLoadingProducts(true);
    try {
      const axiosConfig = {
        headers: { Authorization: 'Agriuservalidation ' + token },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/Admin/getRecentProducts`,
        axiosConfig
      );

      setRecentProducts(response.data);
      console.log(response.data);
    } catch (error) {
      handleError(error);
      console.error('Error fetching recent products:', error);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const fetchMetrics = async () => {
    setIsLoadingMetrics(true);
    try {
      const axiosConfig = {
        headers: { Authorization: 'Agriuservalidation ' + token },
      };
      const endpoints = [
        `${process.env.REACT_APP_APIURL}users/admin/registered-customers`,
        `${process.env.REACT_APP_APIURL}users/admin/approved-vendors`,
        `${process.env.REACT_APP_APIURL}users/admin/total-sales`,
        `${process.env.REACT_APP_APIURL}users/admin/pending-orders`,
        `${process.env.REACT_APP_APIURL}users/admin/processing-orders`,
        `${process.env.REACT_APP_APIURL}users/admin/shipping-orders`,
        `${process.env.REACT_APP_APIURL}users/admin/delivered-orders`,
      ];

      const responses = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint, axiosConfig))
      );

      const data = responses.map((response) => response.data);
      setAdminMetrics({
        registeredCustomers: data[0].registeredCustomers,
        approvedVendors: data[1].approvedVendors,
        totalSales: data[2].totalSales,
        pendingOrders: data[3].pendingOrders,
        processingOrders: data[4].processingOrders,
        shippingOrders: data[5].shippingOrders,
        deliveredOrders: data[6].deliveredOrders,
      });
    } catch (error) {
      handleError(error);
      console.error('Error fetching metrics:', error);
    } finally {
      setIsLoadingMetrics(false);
    }
  };

  const fetchGraphs = async () => {
    setIsLoadingGraphs(true);
    await fetchRecentOrders();
    await fetchRecentUsers();
    await fetchRecentProducts();
    await fetchMetrics();
    setIsLoadingGraphs(false);
  };

  useEffect(() => {
    fetchGraphs();
  }, [token]);

  const orderStatusData = {
    labels: ['Pending', 'Processing', 'Shipping', 'Delivered'],
    datasets: [
      {
        data: [
          adminMetrics.pendingOrders,
          adminMetrics.processingOrders,
          adminMetrics.shippingOrders,
          adminMetrics.deliveredOrders,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50'],
      },
    ],
  };

  const orderDatesData = {
    labels: recentOrders.map((order) => {
      const timestamp = parseInt(order.date_time, 10);
      const parsedDate = new Date(timestamp);

      if (isNaN(parsedDate.getTime())) {
        console.error(`Invalid timestamp found: ${order.date_time}`);
        return 'Invalid Date';
      }

      return parsedDate.toLocaleDateString();
    }),
    datasets: [
      {
        label: 'Orders',
        data: recentOrders.map((order) => order.total),
        fill: false,
        borderColor: '#36A2EB',
        tension: 0.1,
      },
    ],
  };

  const initializeMonthlyData = () => Array(12).fill(0);

  const getMonthIndex = (date) => new Date(date).getMonth();

  const getMonthlySalesData = (orders) => {
    const monthlySales = initializeMonthlyData();

    orders.forEach((order) => {
      const monthIndex = getMonthIndex(parseInt(order.date_time, 10));
      monthlySales[monthIndex] += order.total;
    });

    return monthlySales;
  };

  const monthlySalesData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Sales',
        data: getMonthlySalesData(recentOrders),
        backgroundColor: '#4CAF50',
      },
    ],
  };

  const ordersByUserData = {
    labels: [...new Set(recentOrders.map((order) => order.user_id))],
    datasets: [
      {
        label: 'Total Orders',
        data: [
          ...new Set(
            recentOrders.map((order) =>
              recentOrders
                .filter((o) => o.user_id === order.user_id)
                .reduce((acc, o) => acc + o.total, 0)
            )
          ),
        ],
        backgroundColor: '#1976D2',
      },
    ],
  };

  const productPricesData = {
    labels: recentProducts.map((product) =>
      new Date(product.createdAt).toLocaleDateString()
    ),
    datasets: [
      {
        label: 'Product Prices',
        data: recentProducts.map((product) => product.price),
        fill: false,
        borderColor: '#4CAF50',
        tension: 0.1,
      },
    ],
  };

  const domainCount = recentUsers.reduce((acc, user) => {
    const domain = user?.email;
    if (domain) acc[domain] = (acc[domain] || 0) + 1;
    return acc;
  }, {});

  const colors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4CAF50',
    '#FFC107',
    '#AB47BC',
    '#FF7043',
    '#42A5F5',
  ];

  const backgroundColoro = Array.from(
    { length: Object.keys(domainCount).length },
    (_, i) => colors[i % colors.length]
  );

  const emailDomainsData = {
    labels: Object.keys(domainCount),
    datasets: [
      {
        label: 'Email Domain Distribution',
        data: Object.values(domainCount),
        backgroundColor: backgroundColoro,
      },
    ],
  };

  const categoryCount = recentProducts.reduce((acc, product) => {
    const categoryName = product.category?.categoryName || 'Uncategorized';
    acc[categoryName] = (acc[categoryName] || 0) + 1;
    return acc;
  }, {});

  const customColors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4CAF50',
    '#FFC107',
    '#AB47BC',
    '#FF7043',
    '#42A5F5',
  ];

  const backgroundColor = Array.from(
    { length: Object.keys(categoryCount).length },
    (_, i) => customColors[i % customColors.length]
  );

  const productCategoriesData = {
    labels: Object.keys(categoryCount),
    datasets: [
      {
        label: 'Product Distribution',
        data: Object.values(categoryCount),
        backgroundColor,
      },
    ],
  };

  const metrics = [
    {
      title: 'Registered Customers',
      value: adminMetrics.registeredCustomers.toLocaleString() ?? 0,
      icon: <AccountCircleIcon />,
      subtitle: 'Total registered customers',
      increase: '+5%',
      backgroundColor: '#1976D2',
      textColor: 'black',
      isLoading: isLoadingMetrics,
    },
    {
      title: 'Approved Vendors',
      value: adminMetrics.approvedVendors.toLocaleString() ?? 0,
      icon: <StorefrontIcon />,
      subtitle: 'Total approved vendors',
      increase: '+3%',
      backgroundColor: '#FF9933',
      textColor: 'black',
      isLoading: isLoadingMetrics,
    },
    {
      title: 'Total Sales',
      value: '$' + adminMetrics.totalSales.toFixed(2) ?? 0,
      icon: <MonetizationOnIcon />,
      subtitle: 'Total sales made',
      increase: '+10%',
      backgroundColor: '#4CAF50',
      textColor: 'black',
      isLoading: isLoadingMetrics,
    },
    {
      title: 'Pending Orders',
      value: adminMetrics.pendingOrders.toLocaleString() ?? 0,
      icon: <HourglassEmptyIcon />,
      subtitle: 'Orders pending',
      increase: '+8%',
      backgroundColor: '#FFC107',
      textColor: 'black',
      isLoading: isLoadingMetrics,
    },
    {
      title: 'Processing Orders',
      value: adminMetrics.processingOrders.toLocaleString() ?? 0,
      icon: <AutorenewIcon />,
      subtitle: 'Orders in process',
      increase: '+19%',
      backgroundColor: '#1976D2',
      textColor: 'black',
      isLoading: isLoadingMetrics,
    },
    {
      title: 'Shipping Orders',
      value: adminMetrics.shippingOrders.toLocaleString() ?? 0,
      icon: <LocalShippingIcon />,
      subtitle: 'Orders being shipped',
      increase: '+12%',
      backgroundColor: '#FF9933',
      textColor: 'black',
      isLoading: isLoadingMetrics,
    },
    {
      title: 'Delivered Orders',
      value: adminMetrics.deliveredOrders.toLocaleString() ?? 0,
      icon: <CheckCircleOutlineIcon />,
      subtitle: 'Orders successfully delivered',
      increase: '+16%',
      backgroundColor: '#4CAF50',
      textColor: 'black',
      isLoading: isLoadingMetrics,
    },
  ];

  return (
    <>
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        severity={severity}
        message={message}
      />
      <Container maxWidth={isMobile ? 'xs' : 'xl'}>
        <Box sx={{ p: 0 }} width={isMobile ? '100%' : '100%'}>
          <Grid container spacing={2}>
            {metrics.map((metric, index) => (
              <MetricCard key={index} {...metric} isMobile={isMobile} />
            ))}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <GraphCard
              title='Order Status'
              isLoading={isLoadingGraphs}
              isMobile={isMobile}
            >
              <Doughnut data={orderStatusData} />
            </GraphCard>
            <GraphCard
              title='Customer Email Domains'
              isLoading={isLoadingGraphs}
              isMobile={isMobile}
            >
              <Doughnut data={emailDomainsData} />
            </GraphCard>
            <GraphCard
              title='Product Categories'
              isLoading={isLoadingGraphs}
              isMobile={isMobile}
            >
              <Doughnut data={productCategoriesData} />
            </GraphCard>
            <GraphCard
              title='Order Dates'
              isLoading={isLoadingGraphs}
              isMobile={isMobile}
            >
              <Line data={orderDatesData} />
            </GraphCard>
            <GraphCard
              title='Monthly Sales'
              isLoading={isLoadingGraphs}
              isMobile={isMobile}
            >
              <Bar data={monthlySalesData} />
            </GraphCard>
            <GraphCard
              title='Product Prices Over Time'
              isLoading={isLoadingGraphs}
              isMobile={isMobile}
            >
              <Line data={productPricesData} />
            </GraphCard>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={isMobile ? 12 : 12} sx={{ mt: 2 }}>
              <OrderTable
                recentOrders={recentOrders}
                isLoadingOrders={isLoadingOrders}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={isMobile ? 12 : 6}>
              <UserTable
                recentUsers={recentUsers}
                isLoadingUsers={isLoadingUsers}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={isMobile ? 12 : 6}>
              <ProductsTable
                recentProducts={recentProducts}
                isLoadingProducts={isLoadingProducts}
                isMobile={isMobile}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default AdminDashboard;
