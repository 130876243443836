import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  Avatar,
  Skeleton,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import {
  ThumbUp as ThumbUpIcon,
  QueryBuilder as QueryBuilderIcon,
  Transform as TransformIcon,
  LocalShipping as LocalShippingIcon,
  DeliveryDiningRounded,
} from "@mui/icons-material";
import axios from "axios";
import { useSelector } from "react-redux";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import OrderTable from "./Components/OrderTable";
import ProductsTable from "./Components/ProductsTable";
import UserTable from "./Components/UserTable";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MetricCard = ({
  title,
  value,
  icon,
  subtitle,
  increase,
  background,
  textColor,
  isLoading,
  isMobile,
}) => (
  <Grid item xs={6} sm={6} md={4} lg={2.4}>
    <Card
      sx={{
        p: 2,
        textAlign: "center",
        borderRadius: 2,
        background: background,
        width: "100%",
        minHeight: isMobile ? "200px" : "100%",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Avatar
          sizes="large"
          variant="rounded"
          sx={{
            mb: 1,
            color: "primary.main",
            bgcolor: "white",
            borderRadius: 5,
          }}
        >
          {icon}
        </Avatar>
        {isLoading ? (
          <>
            <Skeleton variant="text" width={60} />
            <Skeleton variant="text" width={100} />
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              component="div"
              fontWeight="bold"
              color={"white"}
            >
              {value}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              gutterBottom
              sx={{ color: "white" }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 100,
                  position: "relative",
                  color: "white",
                }}
              >
                {increase}
              </Typography>
              {subtitle}
            </Typography>
          </>
        )}
      </Box>
    </Card>
  </Grid>
);

const GraphCard = ({ title, children, isLoading, isMobile }) => (
  <Grid item xs={12} md={6} lg={isMobile ? 12 : 4}>
    <Card
      sx={{
        p: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxShadow:
          "3px 2px 0px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgb(227 5 5 / 14%), 0px 1px 3px 0px rgb(50 190 205)",
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {title}
      </Typography>
      {isLoading ? <Skeleton variant="rectangular" height={200} /> : children}
    </Card>
  </Grid>
);

function VendorDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { token, userID } = useSelector((state) => state.loging);
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentUsers, setRecentUsers] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);
  const [vendorMetrics, setVendorMetrics] = useState({
    totalSales: 0,
    pendingOrders: 0,
    processingOrders: 0,
    shippingOrders: 0,
    deliveredOrders: 0,
  });
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingGraphs, setIsLoadingGraphs] = useState(true);

  const fetchRecentOrders = async () => {
    setIsLoadingOrders(true);
    try {
      const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/Vendor/getRecentOrders?userID=${userID}`,
        axiosConfig
      );
      setRecentOrders(response.data);
    } catch (error) {
      console.error("Error fetching recent orders:", error);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const fetchRecentUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/Vendor/getRecentClient?userID=${userID}`,
        axiosConfig
      );
      setRecentUsers(response.data);
    } catch (error) {
      console.error("Error fetching recent users:", error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const fetchRecentProducts = async () => {
    setIsLoadingProducts(true);
    try {
      const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}api/Vendor/getRecentProducts?userID=${userID}`,
        axiosConfig
      );
      setRecentProducts(response.data);
    } catch (error) {
      console.error("Error fetching recent products:", error);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const fetchMetrics = async () => {
    setIsLoadingMetrics(true);
    try {
      const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const endpoints = [
        `${process.env.REACT_APP_APIURL}users/vendor/total-sales?vendorId=${userID}`,
        `${process.env.REACT_APP_APIURL}users/vendor/pending-orders?vendorId=${userID}`,
        `${process.env.REACT_APP_APIURL}users/vendor/processing-orders?vendorId=${userID}`,
        `${process.env.REACT_APP_APIURL}users/vendor/shipping-orders?vendorId=${userID}`,
        `${process.env.REACT_APP_APIURL}users/vendor/delivered-orders?vendorId=${userID}`,
      ];

      const responses = await Promise.all(
        endpoints.map((endpoint) => axios.get(endpoint, axiosConfig))
      );

      const data = responses.map((response) => response.data);
      setVendorMetrics({
        totalSales: data[0].totalSales,
        pendingOrders: data[1].pendingOrders,
        processingOrders: data[2].processingOrders,
        shippingOrders: data[3].shippingOrders,
        deliveredOrders: data[4].deliveredOrders,
      });
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setIsLoadingMetrics(false);
    }
  };

  const fetchGraphs = async () => {
    setIsLoadingGraphs(true);
    await fetchRecentOrders();
    await fetchRecentUsers();
    await fetchRecentProducts();
    await fetchMetrics();
    setIsLoadingGraphs(false);
  };

  useEffect(() => {
    fetchGraphs();
  }, [token]);

  const orderStatusData = {
    labels: ["Pending", "Processing", "Shipping", "Delivered"],
    datasets: [
      {
        data: [
          vendorMetrics.pendingOrders,
          vendorMetrics.processingOrders,
          vendorMetrics.shippingOrders,
          vendorMetrics.deliveredOrders,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50"],
      },
    ],
  };

  const orderDatesData = {
    labels: recentOrders.map((order) => {
      const timestamp = parseInt(order.date_time, 10);
      const parsedDate = new Date(timestamp);

      if (isNaN(parsedDate.getTime())) {
        console.error(`Invalid timestamp found: ${order.date_time}`);
        return "Invalid Date";
      }

      return parsedDate.toLocaleDateString();
    }),
    datasets: [
      {
        label: "Orders",
        data: recentOrders.map((order) => order.total),
        fill: false,
        borderColor: "#36A2EB",
        tension: 0.1,
      },
    ],
  };

  const initializeMonthlyData = () => Array(12).fill(0);

  const getMonthIndex = (date) => new Date(date).getMonth();

  const getMonthlySalesData = (orders) => {
    const monthlySales = initializeMonthlyData();

    orders.forEach((order) => {
      const monthIndex = getMonthIndex(parseInt(order.date_time, 10));
      monthlySales[monthIndex] += order.total;
    });

    return monthlySales;
  };

  const monthlySalesData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Sales",
        data: getMonthlySalesData(recentOrders),
        backgroundColor: "#4CAF50",
      },
    ],
  };

  const ordersByUserData = {
    labels: [...new Set(recentOrders.map((order) => order.user_id))],
    datasets: [
      {
        label: "Total Orders",
        data: [
          ...new Set(
            recentOrders.map((order) =>
              recentOrders
                .filter((o) => o.user_id === order.user_id)
                .reduce((acc, o) => acc + o.total, 0)
            )
          ),
        ],
        backgroundColor: "#1976D2",
      },
    ],
  };

  const productPricesData = {
    labels: recentProducts.map((product) =>
      new Date(product.createdAt).toLocaleDateString()
    ),
    datasets: [
      {
        label: "Product Prices",
        data: recentProducts.map((product) => product.price),
        fill: false,
        borderColor: "#4CAF50",
        tension: 0.1,
      },
    ],
  };

  const domainCount = recentUsers.reduce((acc, user) => {
    const domain = user?.email;
    if (domain) acc[domain] = (acc[domain] || 0) + 1;
    return acc;
  }, {});

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4CAF50",
    "#FFC107",
    "#AB47BC",
    "#FF7043",
    "#42A5F5",
  ];

  const backgroundColoro = Array.from(
    { length: Object.keys(domainCount)?.length },
    (_, i) => colors[i % colors?.length]
  );

  const emailDomainsData = {
    labels: Object.keys(domainCount),
    datasets: [
      {
        label: "Email Domain Distribution",
        data: Object.values(domainCount),
        backgroundColor: backgroundColoro,
      },
    ],
  };

  const categoryCount = recentProducts.reduce((acc, product) => {
    const categoryName = product.category?.categoryName || "Uncategorized";
    acc[categoryName] = (acc[categoryName] || 0) + 1;
    return acc;
  }, {});

  const customColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4CAF50",
    "#FFC107",
    "#AB47BC",
    "#FF7043",
    "#42A5F5",
  ];

  const backgroundColor = Array.from(
    { length: Object.keys(categoryCount)?.length },
    (_, i) => customColors[i % customColors?.length]
  );

  const productCategoriesData = {
    labels: Object.keys(categoryCount),
    datasets: [
      {
        label: "Product Distribution",
        data: Object.values(categoryCount),
        backgroundColor,
      },
    ],
  };

  const metrics = [
    {
      title: "Total Sales",
      value: "$" + vendorMetrics.totalSales.toFixed(2),
      icon: <ThumbUpIcon />,
      subtitle: "Since last week",
      increase: "+3%",
      background: theme.palette.gradian.main,
      textColor: "black",
      isLoading: isLoadingMetrics,
    },
    {
      title: "Pending Orders",
      value: vendorMetrics.pendingOrders,
      icon: <QueryBuilderIcon />,
      subtitle: "Orders pending",
      increase: "+8%",
      background: theme.palette.gradian.main,
      textColor: "black",
      isLoading: isLoadingMetrics,
    },
    {
      title: "Orders in Process",
      value: vendorMetrics.processingOrders,
      icon: <TransformIcon />,
      subtitle: "Currently processing",
      increase: "+19%",
      background: theme.palette.gradian.main,
      textColor: "black",
      isLoading: isLoadingMetrics,
    },
    {
      title: "Shipping Orders",
      value: vendorMetrics.shippingOrders,
      icon: <LocalShippingIcon />,
      subtitle: "Orders being shipped",
      increase: "+12%",
      background: theme.palette.gradian.main,
      textColor: "black",
      isLoading: isLoadingMetrics,
    },
    {
      title: "Delivered Orders",
      value: vendorMetrics.deliveredOrders,
      icon: <DeliveryDiningRounded />,
      subtitle: "Successfully delivered",
      increase: "+16%",
      background: theme.palette.gradian.main,
      textColor: "black",
      isLoading: isLoadingMetrics,
    },
  ];

  return (
    <Container
      maxWidth={isMobile ? "xs" : "xl"}
      sx={{
        paddingLeft: isMobile ? "0px !important" : "1px",
        paddingRight: isMobile ? "0px !important" : "1px",
      }}
    >
      <Box
        sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}
        width={isMobile ? "100%" : "100%"}
      >
        <Grid
          container
          spacing={2}
          sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}
        >
          {metrics.map((metric, index) => (
            <MetricCard key={index} {...metric} isMobile={isMobile} />
          ))}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <GraphCard
            title="Order Status"
            isLoading={isLoadingGraphs}
            isMobile={isMobile}
          >
            <Doughnut data={orderStatusData} />
          </GraphCard>
          <GraphCard
            title="Customer Email Domains"
            isLoading={isLoadingGraphs}
            isMobile={isMobile}
          >
            <Doughnut data={emailDomainsData} />
          </GraphCard>
          <GraphCard
            title="Product Categories"
            isLoading={isLoadingGraphs}
            isMobile={isMobile}
          >
            <Doughnut data={productCategoriesData} />
          </GraphCard>
          <GraphCard
            title="Order Dates"
            isLoading={isLoadingGraphs}
            isMobile={isMobile}
          >
            <Line data={orderDatesData} />
          </GraphCard>
          <GraphCard
            title="Monthly Sales"
            isLoading={isLoadingGraphs}
            isMobile={isMobile}
          >
            <Bar data={monthlySalesData} />
          </GraphCard>
          <GraphCard
            title="Product Prices Over Time"
            isLoading={isLoadingGraphs}
            isMobile={isMobile}
          >
            <Line data={productPricesData} />
          </GraphCard>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
            <OrderTable
              recentOrders={recentOrders}
              isLoadingOrders={isLoadingOrders}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <UserTable
              recentUsers={recentUsers}
              isLoadingUsers={isLoadingUsers}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ProductsTable
              recentProducts={recentProducts}
              isLoadingProducts={isLoadingProducts}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default VendorDashboard;
