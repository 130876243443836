import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const OrdersAccordionSkeleton = ({ numberOfItems = 5 }) => {
  return (
    <div>
      {[...Array(numberOfItems)].map((_, index) => (
        <Accordion key={index} sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-skeleton-${index}-content`}
            id={`panel-skeleton-${index}-header`}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
            <Box sx={{ flexGrow: 1, marginLeft: 2 }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </Box>
            <Skeleton animation="wave" height={40} width={100} />
            <Skeleton animation="wave" height={30} width={100} />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Skeleton animation="wave" height={30} width="60%" />
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton animation="wave" height={30} width="60%" />
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton animation="wave" height={30} width="60%" />
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} multiple={3} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default OrdersAccordionSkeleton;
