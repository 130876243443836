import React from 'react';
import { useNavigate } from 'react-router-dom';

const SimpleAgriCardforMobileview = ({ product }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/product/view/${product._id}`);
  };

  return (
    <div
      className='w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8  p-1'
      onClick={handleCardClick}
    >
      <div className='bg-white shadow-lg hover:shadow-lg transition-transform transform hover:scale-105 cursor-pointer'>
        <img
          className='object-contain w-full h-20 p-2'
          src={product.images[0]}
          alt={product.title}
        />
        <div className='p-4'>
          <p className='text-gray-800 text-xs font-medium truncate'>
            {product.title}
          </p>
          <p className='text-gray-600 text-sm'>Rs {product.price}</p>
        </div>
      </div>
    </div>
  );
};

export default SimpleAgriCardforMobileview;
