import React, { useState, useEffect } from 'react';
import OrdersAccordion from './OrdersAccordion';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  Input,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import CustomSnackbar from '../../Adminpages/Components/CustomSnackbar';
import OrdersAccordionSkeleton from './OrdersAccordionSkeleton';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Store/auth';
import { removeVendorID } from '../../../Store/VendorIDSlice';

const VendorOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [orders, setOrders] = useState([]);
  const { token, userID } = useSelector((state) => state.loging);
  const [isLoading, setIsLoading] = useState(false);
  const [Empty, setEmpty] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      setEmpty(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APIURL}api/ordersWithVendor/${userID}?search=${searchQuery}&status=${statusFilter}&page=${currentPage}&pageSize=${pageSize}`,
          {
            headers: { Authorization: 'Agriuservalidation ' + token },
          }
        );

        if (response.data.message === 'No products found for this vendor.') {
          setOrders([]);
          setEmpty(true);
        } else {
          setOrders(response.data);
          setEmpty(response.data.length === 0);
        }
      } catch (error) {
        setEmpty(true);
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            setSeverity('error');
            setMessage('Token expired, please login again.');
            setOpenSnackbar(true);
            setTimeout(() => {
              dispatch(logout());
              dispatch(removeVendorID());
              window.location.href = 'https://vendormart.lk/';
            }, 3000);
          } else {
            // setSeverity('error');
            // setMessage('An error occurred. Please try again later.');
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          setSeverity('error');
          setMessage(
            'No response from server. Please check your network connection.'
          );
          setOpenSnackbar(true);
        } else {
          setSeverity('error');
          setMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [
    searchQuery,
    statusFilter,
    currentPage,
    token,
    userID,
    dispatch,
    navigate,
  ]);

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APIURL}api/ordersWithVendor/`,
        { newStatus, orderId },
        {
          headers: { Authorization: 'Agriuservalidation ' + token },
        }
      );

      if (response.status === 200) {
        setOrders((prevOrders) =>
          prevOrders.map((order) => {
            if (order.orderDetails._id === orderId) {
              return {
                ...order,
                orderDetails: {
                  ...order.orderDetails,
                  tracking_status: response.data.data.tracking_status,
                },
              };
            }
            return order;
          })
        );
        setSnackbarMessage(
          'Order status updated successfully. Email sent to customer.'
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else if (response.status === 400) {
        setSnackbarSeverity('error');
        setSnackbarMessage('Token expired, please login again.');
        setSnackbarOpen(true);
        setTimeout(() => {
          dispatch(logout());
          window.location.href = 'https://vendormart.lk/';
        }, 7000);
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to update order status.');
      setSnackbarOpen(true);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  return (
    <>
      <Box
        component={Paper}
        p={isMobile ? 1 : 2}
        square
        sx={{ borderRadius: '10px', mt: isMobile ? 0 : 3 }}
      >
        <Grid container spacing={2} minHeight={'100vh'} pt={isMobile ? 0 : 3}>
          <Grid item xs={12}>
            <CustomSnackbar
              open={snackbarOpen}
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              message={snackbarMessage}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'stretch' : 'center',
              }}
            >
              <Input
                type='text'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder='Search by customer name'
                sx={{ m: 1, width: isMobile ? '95%' : '20ch' }}
                size='small'
                endAdornment={<SearchIcon />}
                inputProps={{ 'aria-label': 'Without label' }}
              />
              <Select
                labelId='demo-simple-select-standard-label'
                id='demo-simple-select-standard'
                name='statusFilter'
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                defaultValue='Search by Statuses'
                size='largest'
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                sx={{ m: 1, width: isMobile ? '95%' : 'auto' }}
              >
                <MenuItem value=''>Search by Tracking Statuses</MenuItem>
                <MenuItem value='Pending'>Pending</MenuItem>
                <MenuItem value='Processing'>Processing</MenuItem>
                <MenuItem value='Shipping'>Shipping</MenuItem>
                <MenuItem value='Delivered'>Delivered</MenuItem>
              </Select>
            </Box>
            {!isLoading && orders.length > 0 && (
              <OrdersAccordion
                orders={orders}
                onStatusChange={handleStatusChange}
                isLoading={isLoading}
                Empty={Empty}
              />
            )}
            {isLoading && <OrdersAccordionSkeleton numberOfItems={7} />}
            {!isLoading && Empty && (
              <Typography
                sx={{
                  textAlign: 'center',
                  m: 10,
                  color: 'primary',
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  letterSpacing: '0.1rem',
                }}
              >
                No Orders Found
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </Button>
              <Typography>Page {currentPage}</Typography>
              <Button onClick={() => setCurrentPage(currentPage + 1)}>
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default VendorOrders;
