import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Header from "../../../Components/Header";
import { useDispatch } from "react-redux";
import { login } from "../../../Store/auth";
import Alert from "../../../Components/Alert";
import { Container } from "@mui/material";
import axios from "axios";
import AgriSnackbar from "../../Utils/AgriSnackbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonA from "../../../Components/ButtonA";
import logo from "../../../Assets/logo1.png";
import officallogo from "../../../Assets/OfficalLog.png";
import CustomButton from "../../../Components/CustomButton";
import CustomSnackbar from "../../Adminpages/Components/CustomSnackbar";
import { CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";
import { addVendorID } from "../../../Store/VendorIDSlice";

import backgroundImage from "../../../Assets/signin.webp";
import { useMediaQuery, useTheme } from "@mui/material";

export default function SignInSide(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  //data
  const [snack, setSnack] = useState(false);
  // const [snackmessage, setSnackmessage] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [opensanackbar, setOpensnackbar] = useState(false);
  const [snackmessage, setSnackmessage] = useState("");
  const [snackseverity, setSnackseverity] = useState("success");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //error display
  const handleClose = () => {
    setSnack(false);
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.email = email ? "" : "Email is required.";
    tempErrors.password = password ? "" : "Password is required.";

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      tempErrors.email = "Email is not valid.";
    }

    if (password && password.length < 6) {
      tempErrors.password = "Password must be at least 6 characters long.";
    }

    setErrors({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === "");
  };

  //login submit
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      if (!email.trim() || !email.includes("@") || !email.endsWith(".com")) {
        setError("Invalid Email");
        return;
      }
      if (!password.trim() || password.length < 6) {
        setError("Password Required");
        return;
      }
      setSnackmessage("Please correct the errors before submitting.");
      setSnackseverity("error");
      setOpensnackbar(true);
      return;
    }
    setTimeout(() => {
      const url = `${process.env.REACT_APP_APIURL}users/login`;
      axios
        .post(url, {
          email: email,
          password: password,
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);

          // Handle successful login with token
          if (res.data.token) {
            dispatch(
              login({
                role: res.data.type,
                userID: res.data._id,
                token: res.data.token,
              })
            );
            setSnackmessage("Login Successfully !!");
            setSnackseverity("success");
            setOpensnackbar(true);
            // navigate("/dashboard", { replace: true });
            setTimeout(() => {
              navigate("/dashboard", { replace: true });
            }, 1000);
          }
          // Handle waiting for admin approval
          else if (
            res.data.msg &&
            res.data.msg.includes("Please wait for admin approval")
          ) {
            setSnackmessage(res.data.msg); // Using the message from the response directly
            setSnackseverity("warning");
            setOpensnackbar(true);
          } else if (res.data.msg && res.data.msg.includes("User not found")) {
            setSnackmessage("user email not found !! Please signup first"); // Using the message from the response directly
            setSnackseverity("error");
            setOpensnackbar(true);
          } else if (
            res.data.msg &&
            res.data.msg.includes("Password does not match")
          ) {
            setSnackmessage(res.data.msg); // Using the message from the response directly
            setSnackseverity("error");
            setOpensnackbar(true);
          } else {
            setSnackmessage(
              "Login Failure: " +
                (res.data.msg ? res.data.msg : "Unknown Error")
            );
            setSnackseverity("error");
            setOpensnackbar(true);
          }
        })
        .catch((er) => {
          setLoading(false);
          setSnackmessage("Network Error: Please check your connection");
          setSnackseverity("error");
          setOpensnackbar(true);
        });
    }, 1000);
  };

  //close alert msg
  const handleCloseAlert = () => {
    setError("");
  };

  return (
    <>
      <Alert
        open={!!error}
        handleClose={handleCloseAlert}
        title="Alert"
        msg={error}
      />
      <CustomSnackbar
        open={opensanackbar}
        onClose={() => setOpensnackbar(false)}
        severity={snackseverity}
        message={snackmessage}
      />
      <Box
        minHeight={"100vh"}
        sx={{
          backgroundImage: `url("${backgroundImage}")`,

          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          maxWidth={isMobile ? "md" : "sm"}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "left",
            ml: isMobile ? 0 : 0,
            mr: isMobile ? 0 : 0,
            mt: isMobile ? 0 : -10,
          }}
        >
          <Paper
            sx={{
              p: 3,
              bgcolor: "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(10px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 5,
            }}
          >
            <img
              alt="logo"
              src={officallogo}
              style={{ width: 190, height: 80, mb: 2 }}
            />

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <CustomButton
                title={loading ? "Signing In..." : "Sign In"}
                onClick={handleSubmit}
                disabled={loading} // Disable the button while loading
                startIcon={
                  loading && <CircularProgress size={20} color="inherit" />
                }
              />
              <Grid container>
                <Grid item xs>
                  <Link href="/ForgotPassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
