import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  useTheme,
} from "@mui/material";

const OrderTable = ({ recentOrders, isLoadingOrders }) => {
  const theme = useTheme();

  const getTrackingStatusStyle = (status) => {
    const commonStyles = {
      color: "white",
      fontWeight: "bold",
      padding: "5px 10px",
      borderRadius: "8px",
      display: "inline-block",
      fontSize: "14px",
      margin: "10px 15px",
      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    };

    switch (status.toLowerCase()) {
      case "shipped":
        return {
          ...commonStyles,
          backgroundColor: theme.palette.success.main,
          border: `1px solid ${theme.palette.success.dark}`,
        };
      case "delivered":
        return {
          ...commonStyles,
          backgroundColor: theme.palette.info.main,
          border: `1px solid ${theme.palette.info.dark}`,
        };
      case "pending":
        return {
          ...commonStyles,
          backgroundColor: theme.palette.warning.main,
          border: `1px solid ${theme.palette.warning.dark}`,
        };
      case "processing":
        return {
          ...commonStyles,
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.dark}`,
        };
      default:
        return {
          ...commonStyles,
          backgroundColor: theme.palette.grey[500],
          border: `1px solid ${theme.palette.grey[700]}`,
        };
    }
  };

  return (
    <Paper
      sx={{
        mt: 3,
        p: 2,
        borderRadius: "10px",
        boxShadow: 3,
        boxShadow:
          "3px 2px 0px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgb(227 5 5 / 14%), 0px 1px 3px 0px rgb(50 190 205)",
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        Recent Orders
      </Typography>
      <TableContainer sx={{ overflowX: "auto", width: "100%" }}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Tracking Status</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingOrders ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Skeleton height={30} />
                </TableCell>
              </TableRow>
            ) : recentOrders.length ? (
              recentOrders.map((order) => (
                <TableRow key={order._id} hover>
                  <TableCell>{order._id}</TableCell>
                  <TableCell>{order.user_id}</TableCell>
                  <TableCell>{order.total}</TableCell>
                  <TableCell
                    sx={{ ...getTrackingStatusStyle(order.tracking_status) }}
                  >
                    {order.tracking_status}
                  </TableCell>
                  <TableCell>
                    {new Date(parseInt(order.date_time)).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No recent orders found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OrderTable;
