import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import Signin from './Auth/Signin/Signin';
import SignUp from './Auth/Signup/Signup';
import Cart from './Cart/Cart';
import Checkout from './Checkout/Checkout';
import Dashboard from './Dashboard/Dashboard';
import Favorites from './Favorites/Favorites';
import NewProduct from './Products/NewProduct';
import Profile from './Profile/Profile';
import { useSelector } from 'react-redux';
import PageNotFound from './404/PageNotFound';
import ProductView from './ProductView/ProductView';

import CategoriesProductsView from './Category/CategoriesProductsView';
import Forgotpassword from './Forgotpassword/Forgotpassword';
import ResetPassword from './Resetpassword/ResetPassword';
import CategorySelection from '../Pages/location_view/CategorySelection';

import VendorList from '../Pages/location_view/VendorList';
import MobileviewCategory from './Category/MobileviewCategory';

import WithoutTokenDashboard from './Dashboard/WithoutTokenDashboard';

function Pages(props) {
  const { token, role } = useSelector((state) => state.loging);
  return (
    <>
      <Routes>
        {token && (
          <>
            {role === 'Vendor' && (
              <>
                <Route eaxct path='/product/add' element={<NewProduct />} />
                <Route
                  eaxct
                  path='/product/edit/:id'
                  element={<NewProduct />}
                />
              </>
            )}
            <Route eaxct path='product/view/:id' element={<ProductView />} />
            <Route
              eaxct
              path='/Category/ProductsView/:categoryid/:subcategoryid'
              element={<CategoriesProductsView />}
            />

            <Route eaxct path='/dashboard' element={<Dashboard />} />

            {role === 'client' && (
              <>
                <Route eaxct path='/checkout/:ID' element={<Checkout />} />
                <Route eaxct path='/favorites' element={<Favorites />} />
                <Route eaxct path='/cart' element={<Cart />} />
              </>
            )}
            <Route eaxct path='/profile' element={<Profile />} />

            <Route eaxct path='/login' element={<Signin />} />
            <Route eaxct path='/404' element={<PageNotFound />} />
            <Route eaxct path='/categorys' element={<MobileviewCategory />} />

            <Route
              exact
              path='/'
              element={<Navigate replace to='/dashboard' />}
            />
            <Route
              exact
              path='/signup'
              element={<Navigate replace to='/dashboard' />}
            />
            <Route
              exact
              path='/login'
              element={<Navigate replace to='/dashboard' />}
            />
            <Route exact path='*' element={<Navigate replace to='/404' />} />
          </>
        )}
        {!token && (
          <>
            <Route eaxct path='/' element={<CategorySelection />} />
            <Route eaxct path='/signup' element={<SignUp />} />
            <Route eaxct path='/login' element={<Signin />} />

            <Route eaxct path='/ForgotPassword' element={<Forgotpassword />} />
            <Route
              eaxct
              path='/reset-password/:token'
              element={<ResetPassword />}
            />

            <Route exact path='*' element={<Navigate replace to='/login' />} />

            <Route
              eaxct
              path='/location/category/:categoryName'
              element={<VendorList />}
            />
            <Route
              eaxct
              path='/dashboard'
              element={<WithoutTokenDashboard />}
            />
            <Route eaxct path='/categorys' element={<MobileviewCategory />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default Pages;
